import { render, staticRenderFns } from "./PortalEditMessage.vue?vue&type=template&id=47974951&scoped=true&"
import script from "./PortalEditMessage.vue?vue&type=script&lang=js&"
export * from "./PortalEditMessage.vue?vue&type=script&lang=js&"
import style0 from "./PortalEditMessage.vue?vue&type=style&index=0&id=47974951&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47974951",
  null
  
)

export default component.exports