<template>
  <div class="user-role-wrap" v-click-outside="onClose">
    <div v-for="(item, index) in permissions" :key="index" class="auth">
      <div class="auth-title" :class="{ 'param-group': !item.ui }">
        {{ $t(`role_${item.param}`) }}
      </div>
      <div class="auth-level">
        {{ getPermissionLabel(item) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'
import { DefaultTopScope } from '@/config/permissions.js'

export default {
  name: 'UserRolePermission',
  props: {
    roleId: {
      type: [String, Number],
      default: null
    },
    userRole: {
      type: Object,
      default: null
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    ...mapState('userinfo', ['groupList', 'roleList']),
    ...mapGetters('userinfo', ['selectedUserInfo']),
  },
  methods: {
    onClose() {
      this.$emit('close')
    },

    getPermissionValue(permission) {
      let value = -1

      if (!permission || !permission.ui) {
        return value
      }
      if (this.userRole && this.userRole.permissionV2) {
        value = this.userRole.permissionV2[permission.param]
      } else {
        const currRoleId = Number(this.roleId)
        const currRole = this.roleList.find((role) => role.id == currRoleId)
        value = currRole.permissionV2[permission.param]
      }

      return value
    },
    getTopScopeLabel(permission) {
      let value = this.getPermissionValue(permission)

      if (value < 0) return ''
    },
    getPermissionLabel(permission) {
      let value = this.getPermissionValue(permission)

      if (value < 0) return ''

      if (permission.param === 'topScope') {
        // 若topScope=0, 表示可視範圍為自己所在的群組
        if (value === DefaultTopScope) return this.$t('role_topScop_default')
        // groupList的id是String, 但permissionV2取得的是Number，這邊要用兩個等於！
        const group = this.groupList.find((item) => item.id == value)
        return group ? group.name : ''
      } else {
        const option = permission.options.find(
          (option) => option.value === value
        )

        return option ? this.$t(option.desc) : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.user-role-wrap {
  position: absolute;
  top: px2rem(260); // 16.25rem;
  right: px2rem(8);
  bottom: px2rem(20);
  width: 98%;
  height: px2rem(400);
  background: #151b35;
  color: #ffffff;
  padding: px2rem(12); // 0.75rem;
  border: 1px solid #4a5c78;
  border-radius: px2rem(8); // 0.5rem;
  overflow: overlay;

  // &[parent='mutiple'] {
  //   top: calc(100% + 0.25rem); // px2rem(260); // 16.25rem;
  //   right: 1rem; // px2rem(8);
  //   bottom: 0; // px2rem(20);
  //   height: px2rem(300);
  // }

  .auth {
    display: flex;
    font-size: px2rem(24); // 1.5rem;
    line-height: px2rem(35); // 2.1875rem;
    padding: px2rem(8) 0; // 0.5rem 0;
    border-bottom: 1px solid #4a5c78;

    &:last-child {
      border-bottom: unset;
    }

    $AuthTitleW: 40%;
    $AuthGapW: 1%;
    .auth-title {
      padding-left: px2rem(24);
      margin-right: $AuthGapW;
      width: $AuthTitleW;

      &.param-group {
        padding-left: unset;
        color: #ffe99f;
        font-weight: 700;
      }
    }
    .auth-level {
      width: calc(100% - $AuthTitleW - $AuthGapW);
      padding-left: px2rem(16);
    }
  }
}
</style>
