<template>
  <div class="wrap-checkbox" @click="checkValue = !checkValue">
    <input type="checkbox" :checked="checkValue">
    <label></label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    checkValue: {
      get() {
        return this.value === 1 ? true : false
      },
      set(val) {
        let setVal = val === true ? 1 : 0
        this.$emit('input', setVal)
      }
    }
  }
}
</script>

<style scoped>
.wrap-checkbox {
  /* width: 20px; */
  /* position: relative; */
  /* margin: 20px auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  /* position: absolute;
  top: 0px;
  left: 0px; */
  border: 1px solid #ffffff;
  
  /* background: linear-gradient(top, #222 0%, #45484d 100%); */
  border-radius: 2px;
  /* box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4); */
  
}

label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #282942;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
}

label:hover::after {
  opacity: 0.3;
}

input[type=checkbox] {
  visibility: hidden;    
}

input[type=checkbox]:checked + label {
  border-color: #FFC600;
  background: #FFC600;
}

input[type=checkbox]:checked + label:after {
  opacity: 1;
}

</style>