<template>
  <!-- <div class="wrap-select-tree" tabindex="0" @focusout="handleFocusOut"> -->
  <div class="wrap-select-tree">
    <div v-if="title" class="title">{{ title }}</div>
    <div class="select" :class="{ active: optionShow }" :style="selectStyle">
      <div class="select-selected" @click.stop="handleClick">
        <span>{{ selectLabel }}</span>
        <img src="@/assets/icons/Drop.svg" alt="" :class="{ active: optionShow }">
        <!-- <div class="triangle" :class="{ active: optionShow }"></div> -->
      </div>
      <div
        v-if="optionShow"
        class="tree-options"
        :class="{ top: placement === 'top', bottom: placement === 'bottom' }"
      >
        <div class="search">
          <input
            type="text"
            ref="input"
            v-model="filterText"
            :placeholder="
              placeholder ? placeholder : $t('search_device_unit') /*單位*/
            "
          />
          <div class="search-control">
            <div v-if="filterText.length > 0" class="clear" @click="clearInput">
              <img src="@/assets/icons/clear.svg" alt="">
            </div>
            <div class="search-icon">
              <img src="@/assets/icons/feather-search-gray.svg" alt="">
            </div>
          </div>
        </div>
        <div class="tree">
          <el-tree
            ref="elTree"
            :data="workOptions"
            :props="defaultProps"
            node-key="id"
            :empty-text="$t('tree_no_data')" 
            default-expand-all
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
          >
            <div
              :id="data.id"
              class="custom-tree-node"
              :class="{
                selected: data.id == value,
                'role-default-scope': isForRole && data.id === '0'
              }"
              slot-scope="{ data }"
            >
              <div
                class="icon"
                :style="{ color: data.id == value ? '#ffc600' : '#ffffff' }"
              >
                <i v-if="data.id == value" class="far fa-dot-circle"></i>
                <i v-else class="far fa-circle"></i>
              </div>
              <img src="@/assets/icons/group-icon.svg" alt="" />
              <div
                class="label"
                :class="{ 'role-default-scope': isForRole && data.id === '0' }"
              >
                {{ data.name }}
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div v-if="isForRole && optionShow" class="tree-detail">
        <div class="tree-detail-wrap">
          <div class="title">
            <span>
              {{ optionDetail ? $t(`role_${optionDetail.param}`) : ''}}
            </span>
          </div>
          <ul>
            <li
              class="level"
              v-for="(level, idx) in optionDetail.tooltip.level"
              :key="`${level}${idx}`"
            >
              {{ $t(level) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTree',
  props: {
    title: {},  
    value: {}, 
    options: {},
    dataList: {},
    placement: {
      default: 'bottom'
    },
    optionsWidth: {
      default: '100%'
    },
    effect: {
      default: 'light'
    },
    placeholder: {
      type: String,
      default: ''
    },
    isForRole: {
      type: Boolean,
      default: false
    },
    optionDetail: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      optionShow: false,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      
    }
  },
  computed: {
    selectLabel() {
      const node = this.workDataList.find((item) => item.id == this.value)
      return node ? node.name : ''
      // let str = ''
      // if (this.multiSelect) 
      //   str = this.placeholder
      // else 
      //   str = this?.dataList.find(item => item.id === this.value)?.name
      // return str ? str : this.placeholder
    },
    selectStyle() {
      if (this.effect === 'dark') {
        return {
          '--background': '#4A5C7880',
          '--color': '#ffffff',
          '--triangle-bg': '#ffffff',
          '--options-width': this.optionsWidth
        }
      } else {
        return {
          '--background': '#ffffff',
          '--color': '#191919',
          '--triangle-bg': '#282942',
          '--options-width': this.optionsWidth
        }
      }
    },
    workOptions() {
      if (this.isForRole) {
        return [
          {
            id: `0`,
            name: this.$t(`role_topScop_default`), // 所屬群組
            children: []
          },
          ...this.options
        ]
      }
      return this.options
    },
    workDataList() {
      if (this.isForRole) {
        return [
          {
            id: `0`,
            name: this.$t(`role_topScop_default`), // 所屬群組
          },
          ...this.dataList
        ]
      }
      return this.dataList
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        if (this.value) this.$refs.elTree?.setCurrentKey(this.value)
      })
    },
    filterText(val) {
      this.$refs.elTree?.filter(val)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.elTree?.setCurrentKey(this.value)
    })
  },
  methods: {
    getIcon(fn) {
      return require(`@/assets/icons/${fn}`)
    },
    handleClick() {
      this.optionShow = !this.optionShow
    },
    optionSelect(value) {
      // 多選模式下不關閉下拉選單, 單選才關閉
      let tmpVal = value
      if (this.multiSelect) {
        tmpVal = [...this.value]
        const idx = this.value.findIndex((item) => item === value)
        if (idx >= 0) tmpVal.splice(idx, 1)
        else tmpVal.push(value)
      } else {
        this.optionShow = false
      }
      this.$emit('input', tmpVal)
    },
    handleFocusOut() {
      setTimeout(() => {
        this.optionShow = false
      }, 250)
    },
    inputMouseDown($event) {
      $event.preventDefault()
      this.$refs.input.focus()
      this.optionShow = true
    },
    clearInput() {
      this.filterText = ''
      this.$refs.input.focus()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    },
    handleNodeClick(data) {
      this.$emit('input', Number(data.id))
      setTimeout(() => {
        this.optionShow = false
      }, 600)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
  // font-weight: 500;
}

.wrap-select-tree {
  width: 100%;
  cursor: pointer;

  .title {
    color: #ffffff;
    font-size: px2rem(20);
    // font-weight: 300;
    line-height: px2rem(28);
    padding-bottom: px2rem(4);
  }

  .select {
    width: 100%;
    position: relative;

    // &::after {
    //   position: absolute;
    //   content: '';
    //   top: px2rem(20);
    //   right: px2rem(10);
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   border-width: px2rem(10) px2rem(7.5) 0 px2rem(7.5);
    //   border-color: var(--triangle-bg) transparent transparent transparent;
    //   transform: rotate(0deg);
    //   transform-origin: 50% 50%;
    //   transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);
    // }

    .select-selected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: px2rem(48);
      background: var(--background);
      color: var(--color);
      border-radius: px2rem(8);
      // padding-left: px2rem(12);
      // padding-right: px2rem(24);
      padding: 0 px2rem(12);
      font-size: px2rem(24);
      // font-weight: 300;
      line-height: px2rem(48);
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {}

      img {
        margin-left: 1rem;
        width: 1rem;
        height: 1rem;
        transform: rotate(0deg);
        transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);
        @include filter_black;

        &.active {
          transform: rotate(180deg);
        }
      }
      // .triangle {
      //   position: absolute;
      //   content: '';
      //   top: 40%;
      //   right: px2rem(16);
      //   width: 0;
      //   height: 0;
      //   border-style: solid;
      //   border-width: px2rem(10) px2rem(7.5) 0 px2rem(7.5);
      //   border-color: var(--triangle-bg) transparent transparent transparent;
      //   transform: rotate(0deg);
      //   transform-origin: 50% 50%;
      //   transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);

      //   &.active {
      //     // top: 0px;
      //     transform: rotate(180deg);
      //     // border-width: 0px 7.5px 10px 7.5px;
      //     // border-color: transparent transparent #282942 transparent;
      //     // top: 6%;
      //   }
      // }

    }

    .tree-options {
      position: absolute;
      width: var(--options-width);
      left: calc( ( 100% - var(--options-width)) / 2 );
      color: #ffffff;
      background: #151B35;
      border: 1px solid #4A5C78;
      border-radius: 2px;
      z-index: 1;
      transition: all 250ms ease-in-out;
      min-width: px2rem(200);
      max-height: px2rem(540);
      overflow: overlay;

      &.top {
        bottom: 50px
      }

      &.bottom {
        top: 50px;
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #4A5C78;
        padding: 0 0.5rem 0 1rem;
        height: 3rem;

        input {
          flex: 1;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          color: #ffffff;
          font-size: 20px;
          // font-weight: 300;
          line-height: px2rem(48);
          border-radius: 2px 2px 0 0;
          background: #151B35;
        }

        .search-control {
          display: flex;
          align-items: center;
          height: 100%;

          .clear {
            margin-right: 0.5rem;
            cursor: pointer;

            &:hover {
              filter: brightness(0) saturate(100%) invert(64%) sepia(82%) saturate(741%) hue-rotate(358deg) brightness(108%) contrast(107%);
            }
            img {
              width: px2rem(12);
              height: px2rem(12);
            }
          }

          .search-icon {
            width: px2rem(22);
            height: px2rem(22);
            cursor: pointer;

            &:hover {
              filter: brightness(0) saturate(100%) invert(64%) sepia(82%) saturate(741%) hue-rotate(358deg) brightness(108%) contrast(107%);
            }
          }

          // span {
          //   font-size: 24px;
          //   font-weight: 300;
          //   color: #9D9D9D;
          // }
        }
      }
    }
    .active::after {
      // top: 0px;
      transform: rotate(180deg);
      // border-width: 0px 7.5px 10px 7.5px;
      // border-color: transparent transparent #282942 transparent;
    }

    .tree-detail {
      position: absolute;
      left: calc(100% + 1rem);
      top: calc(-3rem);
      width: px2rem(420);
      padding: px2rem(20);
      border: 1px solid #4a5c78;
      border-radius: px2rem(8);
      background-color: #151b35;
      z-index: 2;

      .tree-detail-wrap {
        // background-color: #151b35;

        .title {
          margin-bottom: 1rem;
          font-size: px2rem(24);
          font-weight: 500;
          text-align: left;
          // color: #fff99f;
        }

        .level {
          margin: 1rem 0 1rem;
          text-align: left;
          list-style-type: disc; // 實心原點
          list-style-position: inside; // 點在文字<li>內
          // background-color: #f00;
        }
      }

    }
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9D9D9D;
  opacity: 1; /* Firefox */
}


.tree {
  width: 100%;
  height: px2rem(360);
  background: #151B35;
  overflow: overlay;
}

::v-deep .el-tree {
  background: #151B35;
  min-width: 100%;
  display: inline-block;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: px2rem(6) 0;
  align-items: center;
}

::v-deep .el-tree-node .el-tree-node__children {
  overflow: visible;
}

::v-deep .el-tree-node__label {
  font-size: px2rem(24);
  // font-weight: 300;
  line-height: px2rem(32);
  color: #ffffff;
}

/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
}

::v-deep .el-tree-node__content .is-leaf {
  color: transparent;
  cursor: default;
}

::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon .is-leaf {
  cursor: default;
}

::v-deep .el-tree-node__expand-icon {
  color: #f3f4f4;
  font-size: 20px;
}

::v-deep .el-tree-node__content:hover {
  background: #6E7D9333 !important;
}

/*::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon {
   //color: #2a303e; 
}*/

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background-color: #6E7D9399 !important;
}

::v-deep .el-tree > .el-tree-node.is-expanded > .el-tree-node__content {
  background: #151b35;
}

::v-deep .el-tree-node > .el-tree-node__content {
  min-height: px2rem(32);
}

::v-deep .el-tree-node__children > .el-tree-node__content {
  min-height: px2rem(32);
}

.custom-tree-node {
  // font-weight: 300;
  line-height: px2rem(32);
  color: #FFC600;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;

  img {
    vertical-align: bottom;
    margin-right: 0.5rem;
  }
  .label {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &.role-default-scope {
      font-style: italic;
    }
  }

  &.role-default-scope {
    border-bottom: 1px solid #4a5c78;
  }
}

.selected {
  color: #FFC600;
}

.icon {
  margin-left: px2rem(10);
  margin-right: px2rem(10);
  font-size: 1.2rem;
}

.icon img {
  vertical-align: middle;
  width: px2rem(20);
  height: px2rem(20);
}


::-webkit-scrollbar {
  width: px2rem(4);
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #9D9D9D;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: px2rem(6);
  background: #9D9D9D99;
}

::-webkit-scrollbar-corner {
  background: #151b35;
}

@media screen and (max-width: 1680px) {
  .title {
    font-size: px2rem(20);
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: px2rem(18);
  } 
  .select-selected {
    font-size: px2rem(18);
  }
}
</style>