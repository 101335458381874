<template>
  <portal to="portal-edit-psw" >
    <div class="edit-psw-modal" @click="closeModal"></div>
    <div class="edit-psw-box">
      <div class="title">
        {{ $t('account_change_password') }}
      </div>
      <div class="tip">{{ $t('account_edit_password_tip1', {min: pswLen.min, max: pswLen.max}) }}</div>
      <div class="tip sp">
        {{ $t('account_edit_password_tip2') }}
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
          <img class="psw-i" src="@/assets/icons/info.svg" alt="" />
        </el-tooltip>
      </div>
      <div class="item">{{ $t('account_new_pw')/*新密碼*/ }}</div>
      <form class="wrap-input">
        <input :type="showPsw ? 'text' : 'password'" v-model="password" @input="checkPasswordInput" autocomplete="false">
        <div v-if="errorPassword" class="error">
          {{ $t(errorPassword.key, errorPassword?.param) }}
          <el-tooltip v-if="errorPassword.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
            <img class="psw-err-i" src="@/assets/icons/info.svg" alt="" />
          </el-tooltip>
        </div>
        <img v-if="showPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handlePswShow">
        <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handlePswShow">
      </form>
      <div class="item">{{ $t('account_reenter_pw')/*再次輸入您的新密碼*/ }}</div>
      <form class="wrap-input">
        <input :type="showRetryPsw ? 'text' : 'password'" v-model="confirmPassword" @input="checkConfirmPasswordInput" autocomplete="false">
        <div v-if="errorConfirmPassword" class="error">{{ $t(errorConfirmPassword.key) }}</div>
        <img v-if="showRetryPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handleRetryPswShow">
        <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handleRetryPswShow">
      </form>
      <div class="btns">
        <div class="btn cancel" @click="closeModal">{{ $t('cancel') }}</div>
        <div class="btn" @click="handleConfirm">{{ $t('confirm') }}</div>
      </div>
    </div>
  </portal>
</template>

<script>
import { validatePwsLen, validatePswChar, validatePswSpecialChar } from '@/utils/lib.js'
import { pswLen, pswSpecialChars } from '@/utils/lib.js'

export default {
  name: 'PortalEditPsw',
  data() {
    return {
      pswLen,
      pswSpecialChars,

      showPsw: false,
      showRetryPsw: false,
      password: undefined,
      confirmPassword: null,

      errorPassword: null,
      errorConfirmPassword: null,
    }
  },
  methods: {
    handlePswShow() {
      this.showPsw = !this.showPsw
    },
    handleRetryPswShow() {
      this.showRetryPsw = !this.showRetryPsw
    },
    closeModal() {
      this.$emit('close')
    },
    checkPasswordInput() {
      // 移除字串起始及結尾處的空白字元
      this.password = this.password.trim()

      // 檢查格式
      if (!validatePswChar(this.password)) {
        this.errorPassword = {
          key: 'account_pw_char_error', // 密碼只允許英數，大小寫，及特殊字元 {chars}
          // param: {
          //   chars: JSON.parse(JSON.stringify(pswSpecialChars))
          // }
          info: true
        }
      } else if (!validatePwsLen(this.password)) {
        const { min, max } = pswLen
        this.errorPassword = {
          key: 'account_pw_length_error', // 密碼長度需{min}～{max}個字元
          param: {
            min,
            max
          }
        }
      } else if (!validatePswSpecialChar(this.password)) {
        this.errorPassword = {
          key: 'account_pw_special_char_error', // 密碼須包含任一特殊字元 {chars}
          // param: {
          //   chars: JSON.parse(JSON.stringify(pswSpecialChars))
          // }
          info: true
        }
      } else {
        this.errorPassword = null
      }

      // // 檢查格式
      // if (!validateAccountIDChar(this.password)) {
      //   this.errorPassword = 'account_id_char_error'
      // } else if (!this.validatePwsLen(this.password)) {
      //   this.errorPassword = 'account_pw_length_error'
      // } else if (!this.validatePswSpecialChar(this.password)) {
      //   this.errorPassword = 'account_pw_char_error'
      // } else {
      //   this.errorPassword = null
      // }
    },
    checkConfirmPasswordInput() {
      // 移除字串起始及結尾處的空白字元
      this.confirmPassword = this.confirmPassword.trim()

      // 檢查格式
      if (this.confirmPassword && this.confirmPassword !== this.password) {
        this.errorConfirmPassword = {
          key: 'account_pw_error' // 兩個密碼不一致，請確認!
        }
      } else {
        this.errorConfirmPassword = null
      }
    },
    checkAllRequired() {
      // 檢查是否有輸入
      if (!this.password) {
        this.errorPassword = {
          key: 'account_required_info'
        }
      }
      if (!this.confirmPassword) {
        this.errorConfirmPassword = {
          key: 'account_required_info'
        }
      }

      if (this.errorPassword || this.errorConfirmPassword) return false

      return true
    },
    handleConfirm() {
      if (!this.checkAllRequired()) return

      this.$emit('confirm', this.password)
    },
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.edit-psw-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.edit-psw-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #282942;
  border: 1px solid #4A5C78;
  color: #ffffff;
  border-radius: 4px;
  padding: 32px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  z-index: 3;
}

.title {
  font-size: 36px;
  font-weight: 500;  
  text-align: center;
  margin-bottom: 16px;
}

.tip {
  text-align: center;
}

.sp {
  white-space: pre-line; // 搭配翻譯字串有 '\n' 就可以換行
  margin-bottom: 32px;

  .psw-i {
    @include img_icon_16x16;
    @include filter_FFF_50;
    &:hover {
      @include filter_FFF;
    }
  }
}

.wrap-input {
  position: relative;
}

.wrap-input img {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

input {
  width: 100%;
  height: 48px;
  border: 1px solid #9D9D9D;
  border-radius: 8px;
  outline: none;
  font-size: 24px;
  font-weight: 300;
  padding-left: 16px;
  padding-right: 36px;
  margin-bottom: 70px;
}

.error {
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  color: $color_F94144;

  .psw-err-i {
    position: relative;
    top: 0rem;
    left: 0rem;
    @include img_icon_16x16;
    @include filter_F94144_50;
    &:hover {
      @include filter_F94144;
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

.cancel {
  border: 1px solid #FFC600;
  background: #282942;
  color: #FFC600;
  margin-right: 32px;
}

.cancel:hover {
  background: #FFC60033;
}

.delete {
  border: 1px solid #F94144;
  background: #F94144;
  color: #ffffff;
}

.delete:hover {
  border: 1px solid #F95052;
  background: #F95052;
}

</style>