<template>
  <div class="wrap-single-user-edit">
    <div class="header">
      <div class="group-action">
        <SelectTree
          v-if="bEdit && selectedUserInfo.kind !== 1"
          class="select-tree"
          v-model="editGroupId"
          :options="groupTreeList"
          :dataList="groupList"
          :placeholder="$t('am_search_group')"
        />
        <div v-else class="group-name">
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span>{{ getGroupName(selectedUserInfo.groupId) }}</span>
        </div>
        <div v-if="showEdit" class="action">
          <div
            v-if="editBtn.show"
            class="action-btn"
            :class="{ 'disable-edit': editBtn.disabled }"
            @click="startEdit"
          >
            {{ $t('edit') }}
          </div>
          <div class="action-btn more" @click="onOpenMoreAction">
            <i class="fa fa-ellipsis-v"></i>
          </div>
        </div>
      </div>
      <div
        v-if="showMoreAction"
        class="action-list"
        v-click-outside="onCloseMoreAction"
      >
        <div class="action-list-item" @click="openEditPwd">
          {{ $t('account_change_password') }}
        </div>
        <div
          class="action-list-item"
          :class="{ disabled: !selectedUserInfo['2faEnabled'] }"
          @click="open2fa"
        >
          {{ $t('account_disable_2fa') }}
        </div>
      </div>
      <div class="account-id">
        <img v-if="selectedUserInfo.enabled === 0" src="@/assets/icons/user_disable.svg" alt="" />
        <img v-else-if="selectedUserInfo.locked === 1" src="@/assets/icons/user_locked.svg" alt="" />
        <img v-else-if="selectedUserInfo.suspendedTime" src="@/assets/icons/user_pause.svg" alt="" />
        <img v-else src="@/assets/icons/user.svg" alt="" />
        <span
          :class="{
            'account-id-gray': selectedUserInfo.enabled === 0,
            'account-id-red': selectedUserInfo.locked === 1
          }"
        >
          {{ selectedUserInfo.id }}
        </span>
      </div>
      <div v-if="!bEdit && selectedUserInfo.locked === 1" class="status">
        <div class="locked">{{ $t('account_locked') /*帳號已上鎖*/ }}</div>
        <div class="disabled locked">
          {{ $t('account_disabled_until') /*暫時停用至*/ }}
        </div>
        <span>{{ getTimeStr(suspend) }}</span>
      </div>
      <div v-else class="status">
        <div v-if="bEdit && !isSelf" class="active">
          <img
            v-if="editEnabled === 1"
            src="@/assets/icons/checkbox-yellow-checked.svg"
            alt=""
            @click="editUserEnabled(0)"
          />
          <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="" @click="editUserEnabled(1)">
          {{ $t('account_enabled')/*啟用*/ }}
        </div>
        <div v-else-if="selectedUserInfo.enabled === 1" class="active">{{ $t('account_active')/*啟用中*/ }}</div>
        <div v-else class="active">{{ $t('account_not_activated')/*未啟用*/ }}</div>

        <template v-if="selectedUserInfo.enabled === 1">
          <div class="disabled">
            {{ $t('account_disabled_until')/*暫時停用至*/ }}
          </div>
          <div v-if="bEdit && !isSelf" class="set-suspend">
            <Select
              class="suspend"
              v-model="selectTimeOption"
              :options="timeOptions"
              effect="dark"
              :placeholder="$t('search_device_placehold')/*請選擇*/"
            />
            <Select
              v-if="selectTimeOption === 'custom'"
              class="customize-suspend"
              v-model="suspend"
              :options="lockMinutesOptions"
              effect="dark"
              :placeholder="$t('search_device_placehold') /*請選擇*/"
              :tooltip=true
            />
            <!-- <el-date-picker v-if="selectTimeOption" class="date-pick" v-model="suspendedTime" type="datetime" :placeholder="$t('search_select_time')"></el-date-picker> -->
          </div>
          <span v-else>{{ $t('none') }}</span>
        </template>
      </div>
    </div>
    <!-- Content -->
    <div class="content" ref="content">
      <div
        class="item"
        v-for="(item, idx) in userItems"
        :key="`${item.title}_${idx}`"
        :class="{
          edit: bEdit,
          disable:
            bEdit && item.ui && (!item.canEdit || (item.canEdit && isSSO()))
        }"
      >
        <div class="item-block">
          <div class="item-detail">
            <div class="title" :class="{ 'title-group': !item.ui }">
              <img v-if="item.ui" :src="item.icon" alt="" />
              <div>{{ $t(item.title) }}</div>
              <span v-if="bEdit && item.required" class="required">*</span>
            </div>
            <!-- 檢視 -->
            <div class="value" v-if="!bEdit && item.ui">
              <span v-if="item.key === 'public' /*|| item.ui === 'switch'*/">
                {{ getPublic(item.value) }}
                <!-- <img
                  v-if="item.value === euPublic.public"
                  src="@/assets/icons/success-check.svg"
                />
                <img v-else src="@/assets/icons/failed.svg" alt="" /> -->
              </span>
              <!-- 帳號類型 -->
              <span
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
              >
                {{ getAccountKind(item) }}
              </span>
              <!-- 角色 -->
              <span v-else-if="item.key === 'role'">
                {{ getUserRole(item)?.label }}
              </span>
              <!-- 強制 2FA -->
              <span v-else-if="item.key === '2faMode'">
                {{ getForce2fa(item) }}
              </span>
              <!-- 浮水印 -->
              <span v-else-if="item.key === 'watermarkEnabled'">
                {{ getWatermark(item) }}
              </span>
              <span v-else-if="item.key === 'language'">
                {{ item.value ? $t(`lang_${item.value}`) : '' }}
              </span>
              <span
                v-else-if="item.key === 'kind' && item.title === 'account_from'"
              >
                {{
                  item.value === euAccountKind.sso
                    ? $t('account_source_sso')
                    : $t('account_source_self')
                }}
              </span>
              <span
                v-else-if="
                  item.key === 'lastLogin' ||
                  item.key === 'updatedTime' ||
                  item.key === 'registerTime'
                "
              >
                {{ getTimeStr(item.value) }}
              </span>
              <span v-else>{{ item.value }}</span>
            </div>
            <!-- 編輯 -->
            <div
              class="value"
              v-if="bEdit && item.ui"
              :bEdit="bEdit"
              :ui="item.ui"
              :class="{ editable: bEdit && item.canEdit }"
            >
              <!-- 公開狀態 -->
              <!-- <ToggleSwitch
                v-if="item.key === 'public'"
                v-model="item.editValue"
                id="public"
                @switch="onSwitchPublic"
              /> -->
              <Select
                v-if="item.key === 'public'"
                class="keep-select"
                v-model="item.editValue"
                :options="publicOptions"
              />
              <!-- 帳號類型 -->
              <Select
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
                class="keep-select"
                v-model="item.editValue"
                :options="kindList"
                :isDisabled="isSSO()"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 角色 -->
              <Select
                v-else-if="isDev && item.key === 'role'"
                class="keep-select role"
                v-model="item.editValue"
                :options="roles"
                :placeholder="
                  $t('search_device_placehold') + `(${roles.length})` /*請選擇*/
                "
              />
              <Select
                v-else-if="item.key === 'role'"
                class="keep-select role"
                v-model="item.editValue"
                :options="roles"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 強制 2FA -->
              <Select
                v-else-if="item.key === '2faMode'"
                class="keep-select"
                v-model="item.editValue"
                :options="forced2faOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 語系 -->
              <Select
                v-else-if="item.key === 'language'"
                class="keep-select"
                v-model="item.editValue"
                :options="langOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 時區 -->
              <Select
                v-else-if="item.key === 'timezone'"
                class="keep-select"
                v-model="item.editValue"
                :options="timezoneOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 浮水印 -->
              <Select
                v-else-if="item.key === 'watermarkEnabled'"
                class="keep-select"
                v-model="item.editValue"
                :options="watermarkOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 帳號來源 -->
              <div
                v-else-if="item.key === 'kind' && item.title === 'account_from'"
              >
                {{
                  item.value === euAccountKind.sso
                    ? $t('account_source_sso')
                    : $t('account_source_self')
                }}
              </div>
              <div
                v-else-if="
                  item.key === 'lastLogin' ||
                  item.key === 'updatedTime' ||
                  item.key === 'registerTime'
                "
              >
                {{ getTimeStr(item.value) }}
              </div>
              <input
                v-else-if="item.ui === 'text' && item.canEdit"
                class="edit-input"
                type="text"
                v-model="item.editValue"
              />
              <span class="text" v-else>{{ item.editValue }}</span>
            </div>
          </div>
          <div class="item-error">
            <span v-if="item.key === 'role' && errorRole">
              {{ $t(errorRole) }}
            </span>
          </div>
        </div>
        <div class="item-helper">
          <div class="role-helper" v-if="item.helper && item.key === 'role'">
            <div
              class="icon"
              @click="onOpenPermission(true)"
              v-if="bEdit ? item.editValue : item.value"
            >
              <img src="@/assets/icons/info.svg" alt="" />
            </div>
            <UserRolePermission
              class="role-permission"
              v-if="isRolePermissionShow"
              :roleId="bEdit ? item.editValue : item.value"
              :userRole="getUserRole(item)"
              :permissions="permissionList"
              @close="onOpenPermission(false)"
            />
          </div>
          <div class="fource2fa_helper" v-if="item.helper && item.key === '2faMode' && selectedUserInfo['2faEnabled']">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('account_enabled_2fa')/*已啟用雙重驗證*/" placement="bottom">
              <div class="icon">
                <img src="@/assets/icons/success-check.svg" alt="">
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bEdit" class="footer">
      <div class="btn cancel" @click="handleCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onEditUser">{{ $t('confirm') }}</div>
    </div>
    <PortalEditPsw
      v-if="showEditPwdPortal"
      @close="closePortal"
      @confirm="changePassword"
    />
    <!-- <PortalMap
      v-if="showMap"
      :gps="selectedUserInfo.gps"
      :bEdit="bEdit"
      @close="closeMap"
      @confirm="confirmMap"
    /> -->
    <TwoFactor
      v-if="show2fa"
      :parent="'account'"
      :userInfo="selectedUserInfo"
      :stopConfirm="isSelf /*同登入者才需要做取消驗證碼確認*/"
      @closemodal="close2fa"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import vClickOutside from 'v-click-outside'
import { DefaultTopScope, permissionList } from '@/config/permissions.js'
import {
  euAccountKind,
  userKindOptions,
  euTF,
  lockMinutesOptions
} from '@/utils/global.js'
import {
  console,
  formatTimeStr,
  getNodeFamily,
  getAncestorList
} from '@/utils/lib'
import { apiEditUser, apiLockUser, apiUnlockUser } from '@/api/index.js'
import {
  getGpsStr,
  euPublic,
  publicOptions,
  forced2faOptions,
  watermarkOptions,
  userItems as defUserItems
} from '@/config/account.js'
import { supportLangs } from '@/i18n/i18n.js'
import Select from '../../tools/Select.vue'
import SelectTree from '../../tools/SelectTree.vue'
// import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import PortalEditPsw from '../../portal/PortalEditPsw.vue'
// import PortalMap from '../../portal/ProtalMap.vue'
import TwoFactor from '@/components/LeftBar/UserInfo/TwoFactor'
import UserRolePermission from '@/components/AccountManagement/user/UserEdit/UserRolePermission.vue'

export default {
  name: 'SingleUserEdit',
  components: {
    Select,
    SelectTree,
    PortalEditPsw,
    TwoFactor,
    // ToggleSwitch,
    UserRolePermission,
    // PortalMap
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['showEdit', 'allowRoles'],
  data() {
    return {
      isDev: console.get(),
      permissionList,
      euAccountKind,
      euPublic,
      bEdit: false,
      showEditPwdPortal: false,
      userItems: JSON.parse(JSON.stringify(defUserItems)),
      editGroupId: '',
      editEnabled: false,
      kindList: userKindOptions.map((option) => {
        return {
          ...option,
          ...{ label: this.$t(`account_kind_${option.label}`) }
        }
      }),
      isRolePermissionShow: false,
      selectTimeOption: null,
      langOptions: supportLangs.map((lang) => {
        return { label: lang.str, value: lang.key }
      }),
      timeOptions: [
        { value: null, label: this.$t('none') },
        { value: '15', label: this.$t('account_15min_later') },
        { value: '30', label: this.$t('account_30min_later') },
        { value: '60', label: this.$t('account_1hr_later') },
        { value: 'custom', label: this.$t('custom') }
      ],
      // suspendedTime: '',
      timezoneOptions: moment.tz.names().map((tz) => {
        return { label: tz, value: tz }
      }),
      keepOptions: [],
      showMap: false,
      lockMinutesOptions,
      editGps: {},
      suspend: '',

      showMoreAction: false,
      show2fa: false,

      // errorMassage
      errorRole: null,
    }
  },
  computed: {
    ...mapGetters(['timezone', 'getUserInfo']),
    ...mapState(['staff', 'permissionV2']),
    ...mapState('userinfo', [
      'groupId',
      'groupList',
      'groupTreeList',
      'roleList',
      'userId',
      'editMode',
      'userInfoList'
    ]),
    ...mapGetters('userinfo', ['selectedUserInfo', 'getUserIcon']),
    isSelf() {
      return this.getUserInfo.id === this.selectedUserInfo.id
    },
    editBtn() {
      // 編輯按鈕 控制
      // 0. 觸發編輯（bEdit = true） => 不顯示
      // 1. 登入者 = 被編輯者 => 不顯示
      // 2. 權限比較: 登入者權限 < 被編輯者權限 => 顯示, 但不可用
      // 3. 群組比較: 被編輯者群組 不在登入者可視範圍裡 => 不會發生
      //             被編輯者群組 不在登入者的群組範圍裡 => TBD

      let show = !this.isSelf && !this.bEdit
      let available = true

      // 被編輯者
      const editUser = this.userInfoList.find(
        (user) => user.id == this.userId // || this.selectedUserInfo.id
      )
      // 被編輯者角色
      const editUserRole = this.roleList.find(
        (role) => editUser && role.id == editUser.roleId
      )?.permissionV2

      // 權限比較
      const highterThenEditUser = Object.keys(this.permissionV2)
        .filter((key) => key !== 'topScope')
        .filter((key) => {
          return (
            !editUserRole || // 應應未設定角色的情況 => user.kind = 0
            (editUserRole && this.permissionV2[key] >= editUserRole[key])
          )
        })

      if (
        highterThenEditUser.length !==
        Object.keys(this.permissionV2).length - 1 /*-1: topScope*/
      ) {
        available = false

        return { show, disabled: !available }
      }

      // // 群組比較
      // const editorGroupId = editUser?.groupId
      // const loggedTopScopeFamily = getNodeFamily(
      //   this.groupTreeList,
      //   this.permissionV2.topScope
      // )
      // const hasEditor = loggedTopScopeFamily.find(
      //   (group) => group.id == editorGroupId
      // )

      // if (!hasEditor) {
      //   available = false
      // }

      return { show, disabled: !available }
    },
    // 落在 登入者角色(如果沒角色就用群組) ~ 被編輯者群組 之間的角色
    roles() {
      if (!this.allowRoles) return []
      let options = []

      // 被編輯者資訊
      const userId = this.userId
      const userInfo = this.userInfoList.find((user) => user.id === userId)
      const parents = getAncestorList(this.groupTreeList, userInfo.groupId)

      options = this.allowRoles
        .filter((role) => {
          const isDef = role.permissionV2.topScope === DefaultTopScope
          const exist = parents.filter(
            (group) => Number(group.id) === role.permissionV2.topScope
          )
          return isDef || exist.length > 0
        })
        .map((role) => {
          return {
            label: !this.isDev
              ? role.name
              : `${role.name} (${role.permissionV2.topScope})`,
            value: role.id
          }
        })

      console.log(`[SingleUserEdit] roles:`, options.map((o) => o.label).length)
      return options
    },
    publicOptions() {
      return this.formatOptions(publicOptions)
    },
    forced2faOptions() {
      return this.formatOptions(forced2faOptions)
    },
    watermarkOptions() {
      return this.formatOptions(watermarkOptions)
    },
  },
  methods: {
    ...mapMutations('userinfo', ['updateAccountPortal', 'updateEditMode']),
    ...mapActions('userinfo', ['getUserInfoList']),
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    getTimeStr(time) {
      return formatTimeStr(time)
    },
    getPublic(publicSt) {
      const option = this.publicOptions.find(({ value }) => value === publicSt)
      return option?.label
    },

    getUserRole(item) {
      const roleId = this.bEdit ? item.editValue : item.value

      // 檢視用完整 roleList 作搜尋; 編輯用過濾權限過的 roleList 作搜尋
      let role = null
      if (this.bEdit) {
        role = this.roles.find(({ value }) => value === roleId)
      } else {
        const tmpRole = this.roleList.find(({ id }) => id === roleId)
        if (tmpRole) {
          role = { label: `${tmpRole.name}`, value: tmpRole.id }
        }
      }

      return role ? role : null
    },
    getForce2fa(item) {
      const forced2fa = this.bEdit ? item.editValue : item.value
      const option = this.forced2faOptions.find(
        ({ value }) => value === forced2fa
      )
      return option?.label
    },
    getAccountKind(item) {
      const currKind = isNaN(item.value) ? 0 : Number(item.value)
      const option = this.kindList.find((kind) => kind.value === currKind)

      return option.label
    },
    getWatermark(item) {
      const option = this.watermarkOptions.find(
        ({ value }) => value === item.value
      )
      return option?.label
    },
    async editUserEnabled(enabled) {
      const data = {
        id: this.selectedUserInfo.id,
        enabled: enabled
      }
      const res = await apiEditUser(data)
      if (res.status === 200) {
        this.editEnabled = enabled
        this.getUserInfoList()
        this.bEdit = false
      }
      this.bEdit = false
      this.updateEditMode('editUser')
    },
    getKeepDayOptions() {
      // 事件保留
      this.keepOptions = []
      this.keepOptions.push({ value: 0, label: `365${this.$t('day')}` })
      for (let i = 1; i <= 365; i++)
        this.keepOptions.push({ value: i, label: `${i} ${this.$t('day')}` })
    },
    getUserItemsValue() {
      // console.log(
      //   `[getUserItemsValue] selectedUserInfo:`,
      //   this.selectedUserInfo
      // )
      for (let i = 0; i < this.userItems.length; i++) {
        const { key } = this.userItems[i]
        if (key) {
          let keys = []
          if (typeof key === 'string') {
            keys = key.split('.')
          } else if (typeof key === 'object') {
            keys = Object.keys(key)
          }

          let value = null
          switch (key) {
            case 'info.name':
              value = this.selectedUserInfo[keys[0]][keys[1]]
              break
            case 'role':
              value = this.selectedUserInfo[`${keys[0]}Id`]
              break
            default:
              value = this.selectedUserInfo[keys[0]]
              break
          }

          this.userItems[i].value = value
        }
      }

      // this.userItems[0].value = this.getKind(this.selectedUserInfo.kind ? this.selectedUserInfo.kind : 0)
      // this.userItems[1].value = this.selectedUserInfo.info.name
      // this.userItems[2].value = this.selectedUserInfo.video.title
      // this.userItems[3].value = this.selectedUserInfo.roleName
      // // 事件保留
      // let lprKeepDay = this.keepOptions.find(item => item.value === this.selectedUserInfo.lprKeepDay).label
      // this.userItems[4].value = lprKeepDay
      // // 影片保留
      // let videoKeepDay = this.keepOptions.find(item => item.value === this.selectedUserInfo.videoKeepDay).label
      // this.userItems[5].value = videoKeepDay
      // this.userItems[6].value = getGpsStr(this.selectedUserInfo.gps.latitude, this.selectedUserInfo.gps.longitude)
      // this.userItems[7].value = this.getAccountSrc(this.selectedUserInfo.kind ? this.selectedUserInfo.kind : 0)
      // this.userItems[8].value = moment(this.selectedUserInfo.updatedTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')
      // this.userItems[9].value = moment(this.selectedUserInfo.registerTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')

      // this.editGps = {...this.selectedUserInfo.gps}
      this.editEnabled = this.selectedUserInfo.enabled
      if (this.selectedUserInfo.suspendedTime === null) {
        this.selectTimeOption = null
        this.suspend = ''
      } else {
        this.selectTimeOption = 'custom'
        this.suspend = this.selectedUserInfo.suspendedTime
      }
      // this.selectTimeOption = this.selectedUserInfo.suspendedTime
    },
    startEdit() {
      if (this.editBtn.disabled) {
        return
      }

      for (let i = 0; i < this.userItems.length; i++) {
        const { key } = this.userItems[i]
        if (key) {
          let keys = []
          if (typeof key === 'string') {
            keys = key.split('.')
          } else if (typeof key === 'object') {
            keys = Object.keys(key)
          }

          let editValue = null
          switch (key) {
            case 'info.name':
              // 若是單簽不可修改名稱
              this.userItems[i].canEdit =
                this.selectedUserInfo?.kind === euAccountKind.sso ? false : true
              editValue = this.selectedUserInfo[keys[0]][keys[1]]
              break
            // case 'public':
            //   editValue =
            //     this.selectedUserInfo[keys[0]] === euPublic.public
            //       ? true
            //       : false
            //   break
            case 'role':
              editValue = this.selectedUserInfo[`${keys[0]}Id`]
              break
            default:
              editValue = this.selectedUserInfo[keys[0]]
              break
          }

          this.userItems[i].editValue = editValue
        }
      }

      // // 若是單簽不可修改名稱
      // this.userItems[1].canEdit = this.selectedUserInfo?.kind === 1 ? false : true
      this.editGroupId = `${this.selectedUserInfo.groupId}`
      // this.userItems[0].editValue = this.selectedUserInfo.kind ? this.selectedUserInfo.kind : 0
      // this.userItems[1].editValue = this.selectedUserInfo.info.name
      // this.userItems[2].editValue = this.selectedUserInfo.video.title
      // this.userItems[3].editValue = this.selectedUserInfo.roleId
      // this.userItems[4].editValue = this.selectedUserInfo.lprKeepDay
      // this.userItems[5].editValue = this.selectedUserInfo.videoKeepDay
      // this.userItems[6].editValue = getGpsStr(this.selectedUserInfo.gps.latitude, this.selectedUserInfo.gps.longitude)
      // this.userItems[7].editValue = ''
      // this.userItems[8].editValue = ''
      // this.userItems[9].editValue = ''

      this.bEdit = true
      this.updateEditMode('editing')
    },
    isSSO() {
      if (this.selectedUserInfo.kind === euAccountKind.sso) {
        return true
      }

      return false
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
    getKind(kindVal) {
      return this.kindList.find((item) => item.value === kindVal).label
    },
    isGpsLock(index) {
      return index === 6 && this.selectedUserInfo.gps.lock === 1
    },
    // getAccountSrc(kindVal) {
    //   return kindVal === 1 ? 'SSO(單簽)' : '自建'
    // },
    onOpenPermission(open) {
      this.isRolePermissionShow = open
    },
    handleCancel() {
      this.bEdit = false
      // TODO：需要清除一些變數的值
      // this.userItems = JSON.parse(JSON.stringify(defUserItems))
      this.updateEditMode('editUser');
    },
    async handleLock(param) {
      try {
        const res = await apiLockUser(param)
        if (res.status === 200) {
          await this.getUserInfoList()
          this.getUserItemsValue()

          this.$notify({
            title: `${this.$t('account_lock_success')} ${
              this.selectedUserInfo.info.name
            }`,
            type: 'success',
          })
        }
      } catch (err) {
        this.$notify({
          title: `${this.$t('account_lock_failed')} ${
            this.selectedUserInfo.info.name
          }`,
          message: err.response.data.message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode('editUser')
      }
    },
    async handleUnlock(param) {
      // console.log(`[handleUnlock] param:`, JSON.stringify(param))
      try {
        const res = await apiUnlockUser(param.id)
        if (res.status === 200) {
          await this.getUserInfoList()
          this.getUserItemsValue()
          this.$notify({
            title: `${this.$t('account_unlock_success')} ${
              this.selectedUserInfo.info.name
            }`,
            type: 'success',
          })
        }
      } catch (err) {
        this.$notify({
          title: `${this.$t('account_unlock_failed')} ${
            this.selectedUserInfo.info.name
          }`,
          message: err.response.data.message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode('editUser')
      }
    },
    async handleEditUser(param) {
      // console.log(`[handleEditUser] param:`, JSON.stringify(param))
      try {
        const res = await apiEditUser(param)
        if (res.status === 200) {
          await this.getUserInfoList()
          this.getUserItemsValue()
          this.$notify({
            title: `${this.$t('account_edit_success')} ${this.selectedUserInfo.info.name}`,
            type: 'success',
          })
        }
      } catch (err) {
        const { message } = err.data
        this.$notify({
          title: `${this.$t('account_edit_failed')} ${this.selectedUserInfo.info.name}`,
          message: message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode('editUser');
      }
    },
    getUserItemIdxByKey(key) {
      const idx = this.userItems.findIndex((item) => item.key === key)
      return idx
    },
    checkRole() {
      const roleIdx = this.getUserItemIdxByKey('role')
      const roleId = this.userItems[roleIdx].editValue

      return roleId === 0 ? 'account_required_info' : ''
    },
    async onEditUser() {
      let param = {}

      // 檢查 角色 是否有填
      this.errorRole = this.checkRole()
      if (this.errorRole) {
        // 處理 scrollbar 捲動位置(只抓了大概的位置)
        this.$refs.content.scrollTop = this.$refs.content.offsetHeight / 2
        return
      }

      param.id = this.selectedUserInfo.id

      // 群組
      if (this.editGroupId !== this.selectedUserInfo.groupId)
        param.groupId = `${this.editGroupId}`

      // 帳號名稱
      const nameIdx = this.getUserItemIdxByKey('info.name')
      if (this.userItems[nameIdx].editValue !== this.selectedUserInfo.info.name)
        param.name = this.userItems[nameIdx].editValue

      // 公開
      const publicIdx = this.getUserItemIdxByKey('public')
      if (this.userItems[publicIdx].editValue !== this.selectedUserInfo.public)
        param.public = this.userItems[publicIdx].editValue

      // 帳號類型
      const kindIdx = this.getUserItemIdxByKey('kind')
      if (this.userItems[kindIdx].editValue !== this.selectedUserInfo?.kind)
        param.kind = this.userItems[kindIdx].editValue

      // 角色
      const roleIdx = this.getUserItemIdxByKey('role')
      if (this.userItems[roleIdx].editValue !== this.selectedUserInfo.roleId)
        param.roleId = this.userItems[roleIdx].editValue

      // 雙重驗證
      const twoFactorIdx = this.getUserItemIdxByKey('2faMode')
      if (this.userItems[twoFactorIdx].editValue !== this.selectedUserInfo['2faMode'])
        param['2faMode'] = this.userItems[twoFactorIdx].editValue

      // 語系
      const langIdx = this.getUserItemIdxByKey('language')
      if (this.userItems[langIdx].editValue !== this.selectedUserInfo.language)
        param.language = this.userItems[langIdx].editValue

      // 時區
      const tzIdx = this.getUserItemIdxByKey('timezone')
      if (this.userItems[tzIdx].editValue !== this.selectedUserInfo.timezone)
        param.timezone = this.userItems[tzIdx].editValue

      // 浮水印
      const watermarkIdx = this.getUserItemIdxByKey('watermarkEnabled')
      if (
        this.userItems[watermarkIdx].editValue !==
        this.selectedUserInfo?.watermarkEnabled
      )
        param.watermarkEnabled = this.userItems[watermarkIdx].editValue

      // 暫停
      if (this.suspend) {
        param.suspend = parseInt(this.suspend, 10)
      }

      // Call API ---
      if (param.suspend) {
        await this.handleLock(param)

        if (Object.keys(param).length >= 3) {
          await this.handleEditUser(param)
        }
      } else {
        if (this.selectedUserInfo.suspendedTime) {
          await this.handleUnlock(param)
        }

        if (Object.keys(param).length >= 2) {
          await this.handleEditUser(param)
        }
      }
    },
    openEditPwd() {
      this.showEditPwdPortal = true;
      this.updateAccountPortal('portal-edit-psw')
    },
    closePortal() {
      this.showEditPwdPortal = false
      this.showMoreAction = false
    },
    async changePassword(newPassword) {
      const data = {
        id: this.selectedUserInfo.id,
        password: newPassword
      }
      const res = await apiEditUser(data)
      if (res.status === 200) {
        this.$notify({
          title: this.$t('account_edit_password'),
          message: this.$t('account_pw_success'),
          type: 'success',
        })
      } else {
        const { message } = res.data
        this.$notify({
          title: this.$t('account_edit_password'),
          message: message, // this.$t('account_pw_failed'),
          type: 'error',
        })
      }
      this.showEditPwdPortal = false
    },
    lockGps() {
      this.editGps.lock = (this.editGps.lock === euTF.true) ? euTF.false : euTF.true;
    },
    openMap() {
      this.updateAccountPortal(`account-map`)
      this.showMap = true
    },
    closeMap() {
      this.showMap = false
    },
    confirmMap(editGps) {
      this.showMap = false
      this.editGps = {...editGps}
      this.userItems[6].editValue = getGpsStr(editGps.latitude, editGps.longitude)
    },
    onOpenMoreAction() {
      this.showMoreAction = !this.showMoreAction
    },
    onCloseMoreAction() {
      this.showMoreAction = false
    },
    open2fa() {
      if (this.selectedUserInfo['2faEnabled']) {
        this.show2fa = true
      }
    },
    close2fa() {
      this.show2fa = false
      this.showMoreAction = false
      this.getUserInfoList()
    },
    canEditRole(item) {
      // 編輯者(登入者)角色比被編輯者的高，才能編輯

      // 登入者
      const loggedUser = this.userInfoList.find(
        (user) => user.id == this.getUserInfo.id
      )
      // 登入者角色
      const loggedUserRole = this.roleList.find(
        (role) => loggedUser && role.id == loggedUser.roleId
      )
      // 被編輯者角色
      const editedUserRole = this.roleList.find(
        (role) => role.id == item.editValue
      )

      if (loggedUserRole && editedUserRole) {
        // console.log(`[canEditRole] loggedUserRole:`, loggedUserRole.name, loggedUserRole.permissionV2.topScope)
        // console.log(`[canEditRole] editedUserRole:`, editedUserRole.name, editedUserRole.permissionV2.topScope)

        // const loggedUserRoleScopes = getNodeFamily(this.groupTreeList, loggedUserRole.permissionV2.topScope)
        const editedUserRoleScopes = getNodeFamily(
          this.groupTreeList,
          editedUserRole.permissionV2.topScope
        )

        // 如果被編輯者角色可視範圍=預設, 要可以被修改
        return editedUserRole.permissionV2.topScope === 0 ||
          editedUserRoleScopes.length > 0
          ? true
          : false
      }
      // else if (loggedUserRole && !editedUserRole) {
      //   console.log(`[canEditRole] #2`)
      // } else if (!loggedUserRole && editedUserRole) {
      //   console.log(`[canEditRole] #3`)
      // } else {
      //   console.log(`[canEditRole] #4`)
      // }

      return true
    },
    cleanError() {
      this.errorRole = null
    }
  },
  watch: {
    editMode() {
      this.bEdit = this.editMode === 'editing' ? true : false
      this.cleanError()
    },
    groupId() {
      // this.closeActionList()
    },
    userId() {
      this.getUserItemsValue()
    },
    selectTimeOption(nVal) {
      if (this.selectTimeOption === null) {
        this.suspend = ''
      } else if (this.selectTimeOption === 'custom') {
        this.suspend = '1'
      } else {
        this.suspend = nVal
      }
    }
  },
  mounted() {
    this.getUserInfoList()
    this.getKeepDayOptions()
    this.getUserItemsValue()
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-single-user-edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // font-size: px2rem(24); // 1.5rem;
  font-size: px2rem(20);
  font-weight: 300;
  background: #282942;
  color: #ffffff;
  // line-height: px2rem(36); // 2.25rem;

  .header {
    padding: px2rem(20) px2rem(8) 0 px2rem(20); // 1.25rem 0.5rem 0 1.25rem;
  }

  .group-action:deep {
    display: flex;
    justify-content: space-between;
    margin-bottom: px2rem(6); // 0.375rem;

    .select-tree {
      width: 60%;

      .select-selected {
        text-align: unset;
      }
    }

    .group-name {
      font-weight: 500;
      color: #ffc600;

      img {
        width: px2rem(24); // 1.5rem;
        height: px2rem(24); // 1.5rem;
        vertical-align: text-bottom;
      }
      span {
        margin-left: px2rem(8); // 0.5rem;
      }
    }

    .action {
      position: relative;
      display: flex;
      .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: px2rem(8); // 0.5rem;
        padding: px2rem(6) px2rem(24); // 0.375rem 1.5rem;
        border-radius: px2rem(8); // 0.5rem;
        line-height: px2rem(35); // 2.1875rem;
        background: #4a5c7880; // 50%
        cursor: pointer;

        &.more {
          width: px2rem(47); // 2.9375rem;
          height: px2rem(47); // 2.9375rem;
        }

        &:first-child {
          margin-left: 0;
        }
        &:hover {
          background: #4a5c78;
        }

        &.disable-edit {
          color: rgba(255, 255, 255, 0.2);
          &:hover {
            background: #4a5c7880;
            cursor: unset;
          }
        }
      }
    }
  }

  .action-list {
    position: absolute;
    right: 0;
    margin-right: px2rem(8); // 0.5rem;
    padding: px2rem(4) 0; // 0.25rem 0;
    border-radius: px2rem(8); // 0.5rem;
    border: 1px solid #4a5c78;
    background-color: #151b35;
    z-index: 1;

    .action-list-item {
      display: flex;
      align-items: center;
      padding: 0 px2rem(30); // 0 1.875rem;
      height: px2rem(47); // 2.9375rem;
      &:hover {
        background-color: #4a5c78;
        cursor: pointer;
      }

      &.disabled {
        opacity: 0.2;
        &:hover {
          background-color: unset;
          cursor: unset; // not-allowed;
        }
      }
    }
  }

  .account-id {
    font-size: px2rem(32); // 2rem;
    // font-weight: 300;
    // line-height: px2rem(32); // 2rem;
    margin-bottom: px2rem(4);

    &.account-id-gray {
      opacity: 0.2;
    }
    &.account-id-red {
      color: #f94144;
    }

    img {
      margin-right: px2rem(12); // 0.75rem;
    }
  }
}

.status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .locked {
    // color: #F94144;
  }

  .disabled {
    display: flex;
    align-items: center;
    position: relative;
    &.locked {
      color: #fff;
      padding-left: 2rem;
      &::before {
        left: px2rem(16);
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: px2rem(30); // 1.875rem;
      background: #ffffff;
      top: px2rem(5); // 0.3125rem;
      left: px2rem(-16); // -1remx;
    }

    span {
      margin-left: px2rem(12); // 0.75rem;
    }
  }

  img {
    vertical-align: baseline;
    margin-right: px2rem(8); // 0.5rem;
    position: relative;
    top: px2rem(2);
    cursor: pointer;
  }

  .active {
    margin-right: px2rem(32); // 2rem;
    position: relative;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 1px ;
    //   height: px2rem(30);
    //   background: #ffffff;
    //   top: px2rem(5);
    //   right: px2rem(-16);
    // }
  }
}

.suspend {
  width: px2rem(124);
  margin-left: px2rem(12);
  margin-right: px2rem(8);
  min-height: px2rem(36);
  font-size: px2rem(20);
  line-height: 2.2;
}
.customize-suspend {
  width: px2rem(160);
  min-height: px2rem(36);
  font-size: px2rem(20);
  line-height: 2.2;
}
::v-deep.customize-suspend .select-options {
  left: 0;
  left: unset;
}

.set-suspend {
  display: flex;
  align-items: center;
}

.date-pick {
  width: px2rem(200); // 12.5rem;
}

// ----------
.content {
  flex: 1;
  padding: px2rem(20) px2rem(2) px2rem(20) px2rem(20); // 1.25rem 0.5rem 1.25rem 1.25rem;
  overflow: overlay;
  position: relative;

  .item {
    $HelperW: px2rem(48);

    display: flex;
    margin-bottom: px2rem(12); // 0.75rem;
    color: #fff;
    // background-color: #f00;

    &.edit,
    &.disable {
      color: #000;
    }

    .item-block {
      display: flex;
      flex-direction: column;
      width: calc(100% - $HelperW);
      .item-detail {
        $TitleW: calc(25% * 1.5);

        display: flex;
        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: $TitleW;
          background: #282942;
          border: 1px solid #ffffff80;
          border-right: none;
          border-radius: px2rem(10) 0 0 px2rem(10); // 0.625rem 0 0 0.625rem;
          color: #ffffff;
          padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);

          .required {
            @include required;
          }
          &.title-group {
            border: unset;
            padding: unset;
            width: 100%;
            font-size: px2rem(20);
            color: #ffe99f;
          }

          $TitleIconW: px2rem(32);
          img {
            padding-right: px2rem(8);
            height: $TitleIconW;
            width: $TitleIconW;
          }
        }
        .value {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - $TitleW);
          min-height: px2rem(50); // 3.125rem;
          background-color: #4a5c7880; // 50%
          border: 1px solid #ffffff80;
          border-radius: 0 px2rem(10) px2rem(10) 0; // 0 0.625rem 0.625rem 0;
          color: currentColor;
          padding: px2rem(6) px2rem(40) px2rem(6) px2rem(20); // 0.375rem 2.5rem 0.375rem 1.25rem;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          position: relative;

          .text {
            overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }

          &[ui='dropdown']:deep {
            overflow: visible;
            padding-right: px2rem(10); // 0.625rem;

            .select-selected {
              padding-left: unset;
              text-align: unset;
            }
          }
          &[bEdit=true] {
            background: #fff;
          }
          .switch {
            display: flex;
            align-items: center;
            height: px2rem(20);
            width: px2rem(20);
            // img {
            //   height: 100%;
            //   width: 100%;
            // }
          }
        }
      }

      .item-error {
        font-size: px2rem(20);
        color: #f94144;
      }
    }

    .item-helper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $HelperW;
      // background-color: #00f;

      .role-helper {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          display: flex;
          padding: 0.5rem;
          border-radius: px2rem(8);
          background-color: #ffffff1a; // 白色 10%

          &:hover {
            background-color: #ffffff4d; // 白色 30%
            cursor: pointer;
          }
        }

        .role-permission {
          position: absolute;
          top: 50%;
          right: $HelperW;
          width: 90%;
          z-index: 1;
        }
      }

    .fource2fa_helper {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: px2rem(20);
          height: px2rem(20);
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.lock-img,
.unlock-img {
  cursor: pointer;
}
.lock-img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(2076%) 
    hue-rotate(147deg) brightness(83%) contrast(80%);
}
.unlock-img {
  filter: brightness(0) saturate(100%) invert(76%) sepia(9%) saturate(14%) 
    hue-rotate(4deg) brightness(82%) contrast(88%);
}

.keep-select {
  position: relative;
  top: px2rem(-6); // -0.375rem;
  width: 100%;
  min-height: px2rem(36); // 2.25rem;

  &.role:deep {
    .select-options {
      height: px2rem(200);
      width: auto;
    }
  }
}

.edit-input {
  width: 100%;
  outline: none;
  border: none;
  font-size: px2rem(24); // 1.5rem;
  font-weight: 300;
  // text-align: center;
}
.edit-input[name='gps']:disabled {
  background-color: #ffffff;
}

.lock {
  color: #ffffff80;
}

.role-info,
.map {
  margin-left: px2rem(16); // 1rem;
  cursor: pointer;
}

.editable {
  /* height: 100%; */
  background: #ffffff;
  color: #282942;
  outline: none;
}

.disable {
  opacity: 0.3;
}

.footer {
  height: px2rem(120); // 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;
}

.btn {
  border: 1px solid #ffc600;
  border-radius: px2rem(8); // 0.5rem;
  padding: px2rem(10) px2rem(48); // 0.625rem 3rem;
  margin-left: px2rem(16); // 1rem;
  margin-right: px2rem(16); // 1rem;
  font-size: px2rem(24);
  font-weight: 300;
  white-space: nowrap;
  cursor: pointer;
}

.cancel {
  color: #ffc600;
}

.cancel:hover {
  background: #ffc60033;
}

.confirm {
  background: #ffc600;
  color: #282942;
}

.confirm:hover {
  background: #ffd133;
}
</style>
