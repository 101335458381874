<template>
  <div class="wrap-multi-user-edit">
    <!-- <div class="select-account">
      <div class="title">{{ $t('account_selected') /*已選帳號*/}} ({{ selectedUserList.length }})
        <img v-if="selectedUserList.length > 0" src="@/assets/icons/TrashCan.svg" alt="" @click="clearAllSelectedUser">
      </div>
      <div class="account-list">
        <div class="account" :class="{ selected: userId === selectedAccount,
          'text-gray': isDisabled(userId) || isSuspended(userId), 'text-red': isLocked(userId) }" 
          v-for="(userId, index) in selectedUserList" :key="userId" @click="selectedAccount = userId"
          @mouseover="accountMouseOver(userId)" @mouseleave="accountMouseLeave">
          <div v-if="statusList.length > 0" class="status">
            <img v-if="statusList[index] === 'loading'" class="loading" src="@/assets/icons/loading.svg" alt="">
            <img v-else-if="statusList[index] === 'success'" class="success" src="@/assets/icons/success-check.svg" alt="">
            <img v-else src="@/assets/icons/failed.svg" alt="">
          </div>
          <div class="id">
            <img v-if="isLocked(userId)" src="@/assets/icons/user_locked.svg" alt="">
            <img v-else-if="isDisabled(userId)" src="@/assets/icons/user_disable.svg" alt="">
            <img v-else-if="isSuspended(userId)" src="@/assets/icons/user_pause.svg" alt="">
            <img v-else src="@/assets/icons/user.svg" alt="">
            {{ `${getUserName(userId)}(${userId})` }}
          </div>
          <div v-show="hoverId === userId" class="remove" @click="removeUserId(userId)">
            <img src="@/assets/icons/close.svg" alt="">
          </div>
        </div>
      </div>
    </div> -->
    <div class="batch-edit">
      <div class="batch-ctrl" :class="{ disabled: disableCtrl }">
        <div class="filter-switch">
          <ToggleSwitch
            v-model="multiSelectOnly"
            class="switch"
            id="selected_only"
            :content="
              $t('account_batch_selected_account_only', {
                count: selectedUserList.length
              })
            "
            :disabled="disableCtrl"
            @switch="onSwitchSelectedOnly"
          />
        </div>
        <!-- <div
          class="clean-all-user"
          @click="clearAllSelectedUser"
          :class="{ disabled: disableCtrl }"
        >
          <img src="@/assets/icons/erase-white.svg" alt="" />
        </div> -->
      </div>
      <div class="header">
        <div class="title">
          {{ $t('account_batch_edit_items') /*批次修改項目*/ }}
        </div>
      </div>
      <div class="edit-items">
        <div
          class="item"
          v-for="(item, idx) in userItems"
          :key="`${item.title}_${idx}`"
        >
          <div class="item-block">
            <div class="item-detail">
              <div class="checked" @click="onSelectBatchItem(idx)">
                <img
                  v-if="batchItems[idx].checked"
                  src="@/assets/icons/checkbox-yellow-checked.svg"
                  alt=""
                />
                <img v-else src="@/assets/icons/uncheck.svg" alt="" />
              </div>
              <div
                class="value-wrap"
                :class="{ disabled: !batchItems[idx].checked }"
              >
                <div class="title">
                  <img v-if="item.ui" :src="item.icon" alt="" />
                  <div>{{ $t(item.title) }}</div>
                </div>
                <div class="value">
                  <!-- 帳號公開 -->
                  <Select
                    v-if="item.key === 'public'"
                    class="dropdown"
                    v-model="item.editValue"
                    :isDisabled="!batchItems[idx].checked"
                    :options="publicOptions"
                  />
                  <!-- 角色 -->
                  <Select
                    v-if="isDev && item.key === 'role'"
                    class="dropdown role"
                    v-model="editRoleId"
                    :options="roles"
                    :isDisabled="!batchItems[idx].checked"
                    :placeholder="
                      $t('search_device_placehold') +
                      `(${roles.length})` /*請選擇*/
                    "
                  />
                  <Select
                    v-else-if="item.key === 'role'"
                    class="dropdown"
                    :class="{ disabled: !batchItems[idx].checked }"
                    v-model="editRoleId"
                    :options="roles"
                    :isDisabled="!batchItems[idx].checked"
                    :placeholder="$t('search_device_placehold') /*請選擇*/"
                  />
                  <!-- 強制 2FA -->
                  <Select
                    v-if="item.key === '2faMode'"
                    class="dropdown"
                    v-model="item.editValue"
                    :isDisabled="!batchItems[idx].checked"
                    :options="forced2faOptions"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="item-error"></div> -->
          </div>
          <div class="item-helper">
            <div class="role-helper" v-if="item.helper && item.key === 'role'">
              <div
                class="icon"
                :class="{ disabled: !batchItems[idx].checked }"
                @click="onOpenPermission(true)"
                v-if="editRoleId"
              >
                <img src="@/assets/icons/info.svg" alt="" />
              </div>
              <UserRolePermission
                class="role-permission"
                v-if="isRolePermissionShow"
                :roleId="editRoleId"
                :userRole="editRole"
                :permissions="permissionList"
                @close="onOpenPermission(false)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn cancel" @click="onCancel">
          {{ $t('cancel') }}
        </div>
        <div
          class="btn confirm"
          @click="onEditUser"
          :class="{ disabled: disableFooter }"
        >
          {{ $t('confirm') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { apiEditUser } from '@/api/index.js'
import { console, getAncestorList } from '@/utils/lib.js'
import { DefaultTopScope, permissionList } from '@/config/permissions.js'
import {
  euPublic,
  publicOptions,
  euForced2fa,
  forced2faOptions,
  multiUserItems as defUserItems
} from '@/config/account.js'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import Select from '../../tools/Select.vue'
import UserRolePermission from '@/components/AccountManagement/user/UserEdit/UserRolePermission.vue'

export default {
  name: 'MultiUserEdit',
  components: { ToggleSwitch, Select, UserRolePermission },
  props: ['allowRoles'],
  data() {
    return {
      isDev: console.get(),
      permissionList,
      userItems: [],
      batchItems: defUserItems.map(({ key }) => {
        return { key, checked: false }
      }),
      selectedAccount: '',
      hoverId: '',
      editRoleId: '',
      isRoleOptionsShow: false,
      isRolePermissionShow: false,
      statusList: [],
    }
  },
  computed: {
    ...mapState('userinfo', [
      'selectedUserList',
      'roleList',
      'userInfoList',
      'groupList',
      'groupTreeList',
      'multiSelectOnly'
    ]),
    ...mapGetters('userinfo', ['getUserName', 'loggedUserInfo']),
    editRole() {
      if (!this.editRoleId) return {}
      return this.roleList.find((item) => item.id === this.editRoleId)
    },
    disableCtrl() {
      return this.selectedUserList.length <= 0
    },
    checkItems() {
      const publicIdx = this.batchItems.findIndex(({ key }) => key === 'public')
      const hasPublic = this.batchItems[publicIdx].checked
      const roleIdx = this.batchItems.findIndex(({ key }) => key === 'role')
      const hasRole = this.batchItems[roleIdx].checked && this.editRoleId
      const forced2faIdx = this.batchItems.findIndex(
        ({ key }) => key === '2faMode'
      )
      const hasForced2fa = this.batchItems[forced2faIdx].checked

      return { hasPublic, hasRole, hasForced2fa }
    },
    disableFooter() {
      const { hasPublic, hasRole, hasForced2fa } = this.checkItems
      const hasUser = this.selectedUserList.length > 0

      return !((hasPublic || hasRole || hasForced2fa) && hasUser)
    },
    topParent() {
      let fullParentList = []
      const parentList = []

      this.selectedUserList.forEach(({ id: userId }) => {
        const userInfo = this.userInfoList.find((user) => user.id === userId)
        const parents = getAncestorList(this.groupTreeList, userInfo.groupId)

        fullParentList = [...fullParentList, ...parents]
      })

      fullParentList.forEach((parent) => {
        if (!parentList.find((item) => item.id === parent.id)) {
          if (
            fullParentList.filter((item) => item.id === parent.id).length ===
            this.selectedUserList.length
          ) {
            parentList.push(parent)
          }
        }
      })

      // console.log(`[MultiUserEdit] topParent :`, parentList.map(({name}) => name))
      return parentList
    },
    // 落在 登入者角色(如果沒角色就用群組) ~ 所有被選使用者群組最高父級 之間的角色
    roles() {
      if (!this.allowRoles) return []
      let options = []

      try {
        const topParents = this.topParent
        if (topParents.length > 0) {
          const filteredRoles = []
          this.allowRoles.forEach((role) => {
            const isDef = role.permissionV2.topScope === DefaultTopScope
            const isInclude = topParents
              .map(({ id }) => Number(id))
              .includes(role.permissionV2.topScope)

            if (isDef || isInclude) {
              filteredRoles.push(role)
            }
          })
          if (filteredRoles.length > 0) {
            options = filteredRoles.map((role) => {
              return {
                label: !this.isDev
                  ? role.name
                  : `${role.name} (${role.permissionV2.topScope})`,
                value: role.id
              }
            })
          }
        } else {
          options = this.allowRoles.map((role) => {
            return {
              label: !this.isDev
                ? role.name
                : `${role.name} (${role.permissionV2.topScope})`,
              value: role.id
            }
          })
        }
        console.log(`[MultiUserEdit] roles :`, options.map(({label}) => label).length)
        return options
      } catch (err) {
        console.error(`[MultiUserEdit][roles] err:`, err ? err.massage : err)
      }
      return options
    },
    publicOptions() {
      return this.formatOptions(publicOptions)
    },
    forced2faOptions() {
      return this.formatOptions(forced2faOptions)
    },
  },
  methods: {
    ...mapMutations('userinfo', [
      'updateEditMode',
      'updateSelectedUserState', // 更新設定狀態
      'updateSelectedUserList',
      'clearSelectedUserList',
      'updateMultiSelectOnly',
    ]),
    ...mapActions('userinfo', ['getUserInfoList']),
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    isLocked(userId) {
      const userObj = this.userInfoList.find((user) => user.id === userId)
      return userObj ? (userObj.locked === 1 ? true : false) : false
    },
    isDisabled(userId) {
      const userObj = this.userInfoList.find((user) => user.id === userId)
      return userObj ? (userObj.enabled === 1 ? false : true) : false
    },
    isSuspended(userId) {
      const userObj = this.userInfoList.find(user => user.id === userId)
      return userObj ? (userObj.suspendedTime ? true : false) : false
    },
    accountMouseOver(userId) {
      this.hoverId = userId
    },
    accountMouseLeave() {
      this.hoverId = ''
    },
    removeUserId(userId) {
      this.updateSelectedUserList(userId)
    },
    setEditRoleId(role) {
      // console.log(`[setEditRoleId] role:`, JSON.stringify(role))
      this.editRoleId = role.value
      this.isRoleOptionsShow = false
    },
    handleOptions() {
      this.isRoleOptionsShow = !this.isRoleOptionsShow
    },
    handleRoleInfo() {
      if (!this.editRoleId) this.isRolePermissionShow = false
      this.isRolePermissionShow = !this.isRolePermissionShow
    },
    /*// getPermissionValue(permission) {
    //   let value = -1

    //   if (!permission || !permission.ui) {
    //     return value
    //   }
    //   if (this.editRole.permissionV2) {
    //     value = this.editRole.permissionV2[permission.param]
    //   } else {
    //     const currRoleId = this.userItems[3].editValue
    //     const currRole = this.roleList.find((role) => role.id === currRoleId)

    //     value = currRole.permissionV2[permission.param]
    //   }

    //   return value
    // },
    // getPermissionLabel(permission) {
    //   try {
    //     const value = this.getPermissionValue(permission)

    //     if (permission.param === 'topScope') {
    //       // 若topScope=0, 表示可視範圍為自己所在的群組
    //       if (value === DefaultTopScope) return this.$t('role_topScop_default')
    //       // groupList的id是String, 但permissionV2取得的是Number，這邊要用兩個等於！
    //       const group = this.groupList.find((item) => item.id == value)
    //       return group ? group.name : ''
    //     } else {
    //       const option = permission.options.find(
    //         (option) => option.value === value
    //       )
    //       return option ? option.desc : ''
    //     }
    //   } catch (err) {
    //     console.error(`[MultiUserEdit][getPermissionLabel] err:`, err.message)
    //   }
    //   return ''
    // },*/
    onOpenPermission(open) {
      const roleItem = this.batchItems.find(({ key }) => key === 'role')

      if (roleItem && !roleItem.checked) {
        return
      }
      this.isRolePermissionShow = open
    },
    async editUser(id, editPublic, roleId, editForced2fa) {
      const { hasPublic, hasRole, hasForced2fa } = this.checkItems
      let param = { id }

      if (hasPublic) {
        param = { ...param, ...{ public: editPublic } }
      }
      if (hasRole) {
        param = { ...param, ...{ roleId } }
      }
      if (hasForced2fa) {
        param = { ...param, ...{ '2faMode': editForced2fa } }
      }

      // API
      return await apiEditUser(param)
    },
    async onEditUser() {
      if (this.disableFooter) {
        return
      }

      // for (let i = 0; i < this.selectedUserList.length; i++) {
      //   this.statusList.push('loading')
      // }

      // 初始狀態：設定中(0)
      this.selectedUserList.forEach((user) => {
        const newUser = { ...user, ...{ state: 0 } }
        this.updateSelectedUserState(newUser)
      })

      // API ---
      const editPublic = this.userItems.find(({ key }) => key === 'public')
        .editValue
        ? euPublic.public
        : euPublic.private
      const editForced2fa = this.userItems.find(
        ({ key }) => key === '2faMode'
      ).editValue

      const results = await Promise.all(
        this.selectedUserList.map(async (user) => {
          // const res = this.editUser(user.id, editPublic, this.editRoleId)
          return this.editUser(
            user.id,
            editPublic,
            this.editRoleId,
            editForced2fa
          )
        })
      )

      // console.log(`[onEditUser] results:`, results)

      // 重新取得userInfoList
      this.getUserInfoList()

      let nSuccess = 0
      let nFailed = 0
      // results.forEach((res, index) => {
      //   if (res.status === 200) {
      //     nSuccess++
      //     this.$set(this.statusList, index, 'success')
      //   } else {
      //     nFailed++
      //     this.$set(this.statusList, index, 'failed')
      //   }
      // })
      results.forEach((res) => {
        const { status, config } = res
        const data = JSON.parse(config.data)

        if (status === 200) {
          this.updateSelectedUserState({ id: data.id, state: 1 }) // 成功
          nSuccess++
        } else {
          this.updateSelectedUserState({ id: data.id, state: 2 }) // 失敗
          nFailed++
        }
      })

      this.$notify({
        title: this.$t('account_batch_modified'),
        // message: `${nSuccess}${this.$t('account_n_success')}，${nFailed}${this.$t('account_n_failed')}。`,
        message: this.$t('account_batch_n', {
          pass_count: nSuccess,
          fail_count: nFailed
        }),
        type: 'success',
      })

      // this.editRoleId = ''
    },
    clearAllSelectedUser() {
      if (this.disableCtrl && this.disableFooter) {
        return
      }

      this.editRoleId = ''
      this.clearSelectedUserList()
    },
    onCancel() {
      this.clearAllSelectedUser()
      this.updateEditMode('editUser')
    },
    // ---
    onSwitchSelectedOnly() {
      this.updateMultiSelectOnly(!this.multiSelectOnly)
    },
    onSelectBatchItem(idx) {
      this.batchItems[idx].checked = !this.batchItems[idx].checked
    },
    // onSwitchPublic(value) {
    //   const piblicIdx = this.userItems.findIndex(({ key }) => key === 'public')
    //   this.userItems[piblicIdx].editValue = value
    // }
  },
  // watch: {
  //   userItems: {
  //     deep: true,
  //     handler: (nVal) => {
  //       console.log(`[watch.userItems] nVal:`, JSON.stringify(nVal))
  //     }
  //   }
  // }
  mounted() {
    this.userItems = defUserItems.map((item) => {
      const { key } = item
      if (key === 'public') {
        item.editValue = euPublic.private
      } else if (key === 'role') {
        item.editValue = ''
      } else if (key === '2faMode') {
        item.editValue = euForced2fa.off
      }

      return item
    })
  },
  destroyed() {
    // 清除 ...
    this.updateMultiSelectOnly(false)
    this.userItems = defUserItems.map((item) => {
      const { key } = item
      if (key === 'public') {
        item.editValue = euPublic.private
      } else if (key === 'role') {
        item.editValue = ''
      }

      return item
    })
    this.clearSelectedUserList()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-multi-user-edit {
  $SelectAccountH: 0%; // 46%;

  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;

  // .select-account {
  //   height: 46%;
  //   border-bottom: 1px solid #4A5C78;

  //   .title {
  //     font-size: px2rem(24);
  //     padding: px2rem(20) px2rem(20) px2rem(12);
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;

  //     img {
  //       position: relative;
  //       left: px2rem(-14);
  //       cursor: pointer;
  //     }
  //   }

  //   .account-list {
  //     height: calc(100% - px2rem(80));
  //     overflow: overlay;

  //     .account {
  //       display: flex;
  //       align-items: center;
  //       font-size: px2rem(24);
  //       font-weight: 300;
  //       line-height: px2rem(35);
  //       padding: px2rem(8) 0;
  //       margin-right: px2rem(14);
  //       cursor: pointer;
  //       position: relative;

  //       &:hover {
  //         background: #6E7D9333;
  //       }

  //       &:active,
  //       .selected {
  //         background: #6E7D9399;
  //       }

  //       &.text-gray {
  //         color: #ffffff33;
  //       }
  //       &.text-red {
  //         color: #F94144;
  //       }

  //       .status {
  //         position: absolute;
  //         left: px2rem(20);
  //         top: px2rem(7);

  //         .loading {
  //           animation: rotation 1s infinite linear;
  //         }

  //         @keyframes rotation {
  //           from {
  //             transform: rotate(0deg);
  //           }
  //           to {
  //             transform: rotate(360deg);
  //           }
  //         }

  //         .success {
  //           width: px2rem(20);
  //           height: px2rem(20);
  //         }
  //       }

  //       .id {
  //         display: flex;
  //         padding-left: px2rem(56);
  //         width: 50%;

  //         img {
  //           margin-right: px2rem(12);
  //         }
  //       }
  //       .remove {
  //         position: absolute;
  //         right: px2rem(20);
  //         width: px2rem(24);
  //         height: px2rem(24);
  //         background: #F94144;
  //         border-radius: 2px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;

  //         img {
  //           width: px2rem(12);
  //           height: px2rem(12);
  //         }

  //         &:hover {
  //           background: #ffffff;
  //           img {
  //             filter: brightness(0) saturate(100%) invert(32%) sepia(54%)
  //               saturate(3448%) hue-rotate(338deg) brightness(102%)
  //               contrast(97%);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .batch-edit {
    display: flex;
    flex-direction: column;
    height: calc(100% - $SelectAccountH);
    padding-top: 1rem;
    padding-left: 1rem;

    .batch-ctrl {
      display: flex;
      justify-content: space-between;
      padding-right: 1rem;

      &.disabled {
        opacity: 0.2;
      }
      .filter-switch {
        display: flex;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
      }

      .clean-all-user {
        cursor: pointer;
        &.disabled {
          // opacity: 0.2;
          cursor: unset;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: px2rem(12);
      .title {
        padding: 0;
        font-size: px2rem(24);
        font-weight: 500;
        color: #ffe99f;
      }
    }

    .edit-items {
      height: calc(100% - px2rem(120));
      // padding: px2rem(20) 0 px2rem(12) 0;

      .item {
        $HelperW: px2rem(48);
        display: flex;
        margin-top: px2rem(12);

        .item-block {
          display: flex;
          flex-direction: column;
          width: calc(100% - $HelperW);

          .item-detail {
            $CheckW: calc(px2rem(20) + 1rem);
            $TitleW: calc(25% * 1.5);

            display: flex;
            align-items: center;
            // background-color: #f00;
            .checked {
              display: flex;
              padding-right: 1rem;
              width: $CheckW;
              img {
                cursor: pointer;
              }
            }

            .value-wrap {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: calc(100% - $CheckW);
              height: 100%;
              border-radius: px2rem(8);
              font-size: px2rem(20);
              // font-size: px2rem(24);

              .title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: $TitleW;
                height: inherit;
                border: 1px solid #ffffff80;
                border-right: none;
                border-top-left-radius: px2rem(10);
                border-bottom-left-radius: px2rem(10);
                padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);
                color: #ffffff;
                background: #282942;

                $TitleIconW: px2rem(32);
                img {
                  padding-right: px2rem(8);
                  height: $TitleIconW;
                  width: $TitleIconW;
                }
              }

              .value {
                $LeftGap: 1rem;

                display: flex;
                align-items: center;

                width: calc(100% - $TitleW);
                height: inherit;
                padding-left: $LeftGap;
                border: 1px solid #ffffff80;
                border-left: none;
                border-top-right-radius: px2rem(10);
                border-bottom-right-radius: px2rem(10);
                background-color: #ffffff;
                // background-color: #00f;

                .switch {
                  padding-left: 0.5rem;
                }

                .dropdown:deep {
                  display: flex;
                  .select {
                    .select-selected {
                      padding-left: unset;
                      text-align: unset;
                      // font-size: px2rem(16);
                    }
                  }

                  &.role {
                    .select {
                      .select-options {
                        max-height: 17.5rem;
                      }
                    }
                  }
                }
              }

              &.disabled {
                opacity: 0.2;
              }
            }
          }
          // .item-error {}
        }

        .item-helper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $HelperW;
          // background-color: #00f;

          .role-helper {
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #0f0;

            .icon {
              $RoleInfoColor: #ffffff1a; // 白色 10%

              display: flex;
              padding: 0.5rem;
              border-radius: px2rem(8);
              background-color: $RoleInfoColor;

              &:hover {
                background-color: #ffffff4d; // 白色 30%
                cursor: pointer;
              }

              &.disabled {
                opacity: 0.2;
                &:hover {
                  background-color: $RoleInfoColor;
                  cursor: unset;
                }
              }
            }

            .role-permission {
              position: absolute;
              top: 47%;
              right: $HelperW;
              width: 30%;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.tag-select {
  display: flex;
  align-items: center;
  position: relative;
  font-size: px2rem(24);
  line-height: px2rem(26);
  font-weight: 300;
  cursor: pointer;
}

.tag-select .tag {
  width: 25%;
  height: px2rem(48);
  background: #282942;
  border: 1px solid #FFFFFF80;
  border-radius: px2rem(10) 0px 0px px2rem(10);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-select .select {
  width: 58%;
  height: px2rem(48);
  color: #282942;
  background: #FFFFFF;
  border: 1px solid #ffffff;
  border-radius: 0px px2rem(10) px2rem(10) 0px;
  padding-left: px2rem(20);
  padding-right: px2rem(20);
  margin-right: px2rem(12);
  display: flex;
  align-items: center;
}

.tag-select .select:empty::before {
  content: attr(data-placeholder);
  color: #9D9D9D;
}

.role-info {
  cursor: pointer;
}

.tag-select .remove {
  right: 0;
}

.tag-select-options {
  position: absolute;
  top: px2rem(54);
  width: 83%;
  height: px2rem(200);
  overflow: overlay;
  text-align: center;
  background: #151B35;
  border: 1px solid #4A5C78;
  border-radius: px2rem(8);
  padding: px2rem(6) 0;
}

.tag-select-option {
  padding: px2rem(6) 0;
  margin-right: px2rem(12);
  line-height: px2rem(36);
}

.tag-select-option:hover {
  background: #6E7D9333;
}

.tag-select-option.selected {
  background: #6E7D9399;
}

.role-permission {
  position: absolute;
  right: 0;
  bottom: 104%;
  width: 100%;
  height: px2rem(400);
  background: #151B35;
  color: #ffffff;
  padding: px2rem(12);
  border: 1px solid #4A5C78;
  border-radius: px2rem(8);
  overflow: overlay;

  .auth {
    display: flex;
    font-size: px2rem(24);
    line-height: px2rem(35);
    padding: px2rem(8) 0;
    border-bottom: 1px solid #4A5C78;

    &:last-child {
      border-bottom: unset;
    }

    $AuthTitleW: 40%;
    $AuthGapW: 1%;
    .auth-title {
      padding-left: px2rem(16);
      margin-right: $AuthGapW;
      width: $AuthTitleW;

      &.param-group {
        padding-left: unset;
        color: #ffe99f;
        font-weight: 700;
      }
    }

    .auth-value {
      width: calc(100% - $AuthTitleW - $AuthGapW);
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    // .auth-level {
    //   width: calc(100% - $AuthTitleW);
    //   padding-left: px2rem(16);
    // }
  }
}

.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4A5C78;

  .btn {
    border: 1px solid #ffc600;
    border-radius: px2rem(8);
    padding: px2rem(10) px2rem(48);
    margin-left: px2rem(16);
    margin-right: px2rem(16);
    font-size: px2rem(24);
    font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &.cancel {
      color: #FFC600;
      &:hover {
        background: #FFC60033;
      }
    }

    &.confirm {
      background: #ffc600;
      color: #282942;
      &:hover {
        background: #ffd133;
      }

      &.disabled {
        opacity: 0.2;
        cursor: unset;
        &:hover {
          background: #ffc600;
        }
      }
    }
  }

  // &.disabled {
  //   opacity: 0.2;
  //   .btn {
  //     cursor: unset;
  //     &.cancel {
  //       &:hover {
  //         color: #ffc600;
  //         background: unset;
  //       }
  //     }
  //     &.confirm {
  //       &:hover {
  //         background: #ffc600;
  //       }
  //     }
  //   }
  // }
}

</style>
