<template>
  <div class="wrap-group-edit">
    <GroupEditNew v-if="editMode === euEditMode.new" />
    <GroupEditSingle
      :showEdit="showEdit"
      v-if="
        (editMode === euEditMode.editView || editMode === euEditMode.editing) &&
        groupId
      "
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { euEditMode } from '@/config/account.js'
import GroupEditNew from '@/components/AccountManagement/group/Edit/New.vue'
import GroupEditSingle from '@/components/AccountManagement/group/Edit/Single.vue'

export default {
  name: 'GroupEdit',
  components: { GroupEditNew, GroupEditSingle },
  props: ['showEdit'],
  data() {
    return {
      euEditMode
    }
  },
  computed: {
    ...mapState('userinfo/group', ['editMode', 'groupId'])
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-group-edit {
  width: 34%;
  height: 100%;
  background: #282942;
  color: #ffffff;
}
</style>
