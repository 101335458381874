<template>
  <portal to="account-create-confirm">
    <div class="modal" @click="onClose"></div>
    <div class="box">
      <div class="header">
        <img src="@/assets/icons/warn-icon.svg" alt="" />
        {{ title }}
      </div>
      <div class="content">
        <p>{{ content }}</p>
        <div class="btns">
          <div class="btn cancel" @click="onClose">{{ $t('cancel') }}</div>
          <div class="btn" @click="onConfirm">
            {{ $t('confirm') }}
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'PortalCreateConfirm',
  props: ['title', 'content'],
  data() {
    return {}
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onConfirm() {
      this.$emit('confirm')
    }
    // , onCancel() {
    //   this.$emit('close')
    // }
  }
}
</script>

<style scoped>
.modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  z-index: 3;
}

.header {
  background: #4a5c78;
  color: #ffffff;
  border-radius: 23px 23px 0px 0px;
  font-size: 23px;
  font-weight: 300;
  padding: 15px 32px;
  display: flex;
  align-items: center;
}

.header img {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  text-align: center;
  position: relative;
  top: -32px;
  padding: 0 24px 50px;
}

.content > p {
  font-size: 32px;
  line-height: 44px;
  font-weight: 300;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 10px;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

.cancel {
  border: 1px solid #FFFFFF;
  background: #282942;
  color: #ffffff;
  margin-right: 32px;
}

.cancel:hover {
  background: #3d3f55;
}

.delete {
  border: 1px solid #F94144;
  background: #F94144;
  color: #ffffff;
}

.delete:hover {
  border: 1px solid #F95052;
  background: #F95052;
}
</style>
