<template>
  <div class="wrap-new-account">
    <div class="new-account" ref="content">
      <div class="info" v-html="$t('account_required')"></div>
      <div
        class="item"
        v-for="(item, idx) in userItems"
        :key="`${item.title}_${idx}`"
      >
        <div class="item-block">
          <div class="item-desc" v-if="item.key === 'id' || item.key === 'password'">
            <span v-if="item.key === 'id'">
              {{ account_id_tip }}
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="accSpecialChars">
                <img class="acc-psw-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span v-else-if="item.key === 'password'">
              {{ account_pw_tip }}
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
                <img class="acc-psw-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
          </div>
          <div class="item-detail">
            <div class="title" :class="{ 'group-title': !item.ui }">
              <img v-if="item.ui" :src="item.icon" alt="" />
              <div>{{ $t(item.title) }}</div>
              <span v-if="item.required" class="required">*</span>
            </div>

            <div v-if="item.ui" class="content">
              <!-- 群組 -->
              <SelectTree
                v-if="item.key === 'group'"
                class="select-tree"
                v-model="newAccount.groupId"
                :options="groupTreeList"
                :dataList="groupList"
                :placeholder="$t('am_search_group')"
              />
              <!-- 帳號ID -->
              <form v-else-if="item.key === 'id'" class="account">
                <input
                  type="text"
                  autocomplete="off"
                  :class="{ security: !showAccountId }"
                  v-model="newAccount.id"
                  @input="checkIdInput"
                />
                <img v-if="showAccountId" src="@/assets/icons/EyeOpen.svg" @click="handleAccountIdShow">
                <img v-else src="@/assets/icons/EyeClose.svg" @click="handleAccountIdShow">
              </form>
              <!-- 密碼 -->
              <form v-else-if="item.key === 'password'" class="password">
                <input
                  autocomplete="off"
                  :type="showPsw ? 'text' : 'password'"
                  v-model="newAccount.password"
                  @input="checkPasswordInput"
                />
                <img v-if="showPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handlePswShow">
                <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handlePswShow">
              </form>
              <!-- 確認密碼 -->
              <form v-else-if="item.key === 'password_confirm'" class="password">
                <input
                  autocomplete="off"
                  :type="showConfirmPsw ? 'text' : 'password'"
                  v-model="confirmPassword"
                  @input="checkConfirmPasswordInput"
                />
                <img v-if="showConfirmPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handleConfirmPswShow">
                <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handleConfirmPswShow">
              </form>
              <!-- 帳號名稱 -->
              <div v-else-if="item.key === 'info.name'">
                <input
                  type="text"
                  v-model="newAccount.name"
                  name="name"
                  @input="trimInput"
                />
              </div>
              <!-- 公開狀態 -->
              <Select
                v-else-if="item.key === 'public'"
                class="dropdown"
                v-model="newAccount.public"
                :options="publicOptions"
              />
              <!-- 帳號類型 -->
              <Select
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
                class="dropdown"
                v-model="newAccount.kind"
                :options="kindList"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 角色 -->
              <Select
                v-if="isDev && item.key === 'role'"
                class="dropdown"
                v-model="newAccount.roleId"
                :options="roles"
                :placeholder="
                  $t('search_device_placehold') + `(${roles.length})` /*請選擇*/
                "
              />
              <Select
                v-else-if="item.key === 'role'"
                class="dropdown"
                v-model="newAccount.roleId"
                :options="roles"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 強制 2FA -->
              <Select
                v-else-if="item.key === '2faMode'"
                class="dropdown"
                v-model="newAccount['2faMode']"
                :options="forced2faOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 浮水印 -->
              <Select
                v-else-if="item.key === 'watermarkEnabled'"
                class="dropdown"
                v-model="newAccount.watermarkEnabled"
                :options="watermarkOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 語系 -->
              <Select
                v-else-if="item.key === 'language'"
                class="dropdown"
                v-model="newAccount.language"
                :options="langOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 時區 -->
              <Select
                v-else-if="item.key === 'timezone'"
                class="dropdown"
                v-model="newAccount.timezone"
                :options="timezoneOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />

            </div>
          </div>
          <!-- error message -->
          <div
            class="item-error"
            v-if="
              haveError &&
              (item.key === 'id' ||
                item.key === 'password' ||
                item.key === 'password_confirm' ||
                item.key === 'role')
            "
          >
            <span v-if="item.key === 'id' && errorMsg.id">
              {{ $t(errorMsg.id.key || errorMsg.id, errorMsg.id?.param) }}
              <el-tooltip v-if="errorMsg.id.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="accSpecialChars">
                <img class="acc-psw-err-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span v-else-if="item.key === 'password' && errorMsg.password">
              {{ $t(errorMsg.password.key || errorMsg.password, errorMsg.password?.param) }}
              <el-tooltip v-if="errorMsg.password.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
                <img class="acc-psw-err-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span
              v-else-if="item.key === 'password_confirm' && errorMsg.password_confirm"
            >
              {{ $t(errorMsg.password_confirm.key || errorMsg.password_confirm) }}
            </span>
            <!-- <span
              v-else-if="
                errorKind && item.key === 'kind' && item.title === 'account_from'
              "
            >
              {{ $t(errorKind) }}
            </span> -->
            <span v-else-if="item.key === 'role' && errorMsg.roleId">
              {{ $t(errorMsg.roleId) }}
            </span>
            <!-- <span v-else>
              {{ $t('account_required_info') }}
            </span> -->
          </div>
        </div>
        <div class="item-helper">
          <div
            class="role-helper"
            v-if="item.helper && item.key === 'role' && newAccount.roleId"
          >
            <div
              class="icon"
              @click="onOpenPermission(true)"
              v-if="newAccount.roleId"
            >
              <img src="@/assets/icons/info.svg" alt="" />
            </div>
            <UserRolePermission
              class="role-permission"
              v-if="isRolePermissionShow"
              :roleId="newAccount.roleId"
              :userRole="newAccountRole"
              :permissions="permissionList"
              @close="onOpenPermission(false)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn cancel" @click="handleCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onAddUser">{{ $t('confirm') }}</div>
    </div>
    <PortalCreateConfirm
      v-if="showPortalCreateConfirm"
      :title="`${$t('am_user_create')}` /*新增使用者*/"
      :content="`${$t(
        'am_user_create_confirm'
      )}` /*使用者新增後將無法刪除, 您確定要新增嗎？*/"
      @close="handleCancel"
      @confirm="handleAddUser"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { DefaultTopScope, permissionList } from '@/config/permissions.js'
import { apiCreateUser } from '@/api/index.js'
import {
  console,
  pswLen,
  pswSpecialChars,
  validatePwsLen,
  validatePswChar,
  validatePswSpecialChar,
  accLen,
  accSpecialChars,
  validateAccountLen,
  validateAccountIDChar,
  getAncestorList
} from '@/utils/lib.js'
import {
  euAccountKind,
  userKindOptions,
  // euTF,
} from '@/utils/global.js'
import {
  euPublic,
  publicOptions,
  euForced2fa,
  forced2faOptions,
  euWaterMark,
  watermarkOptions,
  createUserItems as defUserItems
} from '@/config/account.js'
import { supportLangs } from '@/i18n/i18n.js'
import SelectTree from '../../tools/SelectTree.vue'
import Select from '../../tools/Select.vue'
// import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import UserRolePermission from '@/components/AccountManagement/user/UserEdit/UserRolePermission.vue'
import PortalCreateConfirm from '@/components/AccountManagement/portal/PortalCreateConfirm.vue'

export default {
  name: 'NewAccount',
  components: {
    SelectTree,
    Select,
    // ToggleSwitch,
    UserRolePermission,
    PortalCreateConfirm
  },
  data() {
    return {
      permissionList,
      isDev: console.get(),
      accLen,
      accSpecialChars,
      pswLen,
      pswSpecialChars,

      userItems: JSON.parse(JSON.stringify(defUserItems)),
      newAccount: {
        groupId: '', // 群組
        id: null, // 帳號
        password: undefined, // 密碼
        name: '', // 帳號名稱
        public: euPublic.private, // 帳號公開
        kind: euAccountKind.user, // 帳號類型
        roleId: null, // 角色
        '2faMode': euForced2fa.off,
        language: '', // 語系
        timezone: '', // 時區
        watermarkEnabled: euWaterMark.off, // 浮水印
        '2faEnabled': 0, // 二因子驗證
      },
      newAccountRole: null,
      confirmPassword: null,
      showAccountId: false,
      showPsw: false,
      showConfirmPsw: false,
      kindList: userKindOptions
        .filter((option) => !option.disabled)
        .map((option) => {
          return {
            ...option,
            ...{ label: this.$t(`account_kind_${option.label}`) }
          }
        }),
      langOptions: supportLangs.map((lang) => {
        return { label: lang.str, value: lang.key }
      }),
      timezoneOptions: moment.tz.names().map((tz) => {
        return { label: tz, value: tz }
      }),
      keepOptions: [],
      isRolePermissionShow: false,

      // errorKind: false,
      errorRole: false,
      errorMsg: {
        id: null,
        password: undefined,
        password_confirm: null,
        kind: null,
        roleId: null
      },

      showPortalCreateConfirm: false
    }
  },
  props: ['allowRoles'],
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['staff', 'userFlatObj', '2faMode']),
    ...mapState('userinfo', [
      'userId',
      'groupList',
      'groupTreeList',
      'roleList',
      'addAccountGroupId',
      'userInfoList'
    ]),
    // 落在 登入者角色(如果沒角色就用群組) ~ 增加使用者群組(newAccount.groupId) 之間的角色
    account_id_tip() {
      const { min, max } = accLen
      return this.$t('account_id_tip', { min, max })
    },
    account_pw_tip() {
      const { min, max } = pswLen
      const param = { min, max }

      return this.$t('account_pw_tip', param)
    },
    roles() {
      if (!this.allowRoles) return []
      let options = []

      // Step1: 取得登入者可用群組（先用角色 再用 群組）
      // 已經在過濾 可配置角色 時, 過濾過了 => 所以這邊不需要處理了

      // Step2: 如果新增角色與manager同層, 則新增角色可選擇角色為=>所有角色
      // 6/2 重新定義 manager 結果：
      // 因帳號於 “manager” 群組下, 但 色可視範圍為 “admin“, “admin“ 比 “manager“ 位階低, 所以無法新增
      // 登入者
      // const loggedUser = this.userInfoList.find(
      //   (user) => user.id == this.getUserInfo.id
      // )
      // if (loggedUser.groupId === this.newAccount.groupId && this.staff) {
      //   options = this.allowRoles.map((role) => {
      //     return {
      //       label: role.name,
      //       value: role.id
      //     }
      //   })
      //   return options
      // }

      // Step3: 取得新增使用者群組的所有直屬父群組
      const newAccountAncestorList = getAncestorList(
        this.groupTreeList,
        this.newAccount.groupId
      )
      // console.log(`[NewAccount][roles] newAccountAncestorList:`, newAccountAncestorList.map(({name}) => name))

      if (newAccountAncestorList.length > 0) {
        this.allowRoles.forEach((role) => {
          const isDef = role.permissionV2.topScope === DefaultTopScope
          const isIncludes = newAccountAncestorList
            .map((group) => Number(group.id))
            .includes(role.permissionV2.topScope)

          if (isDef || isIncludes) {
            options.push({
              label: !this.isDev
                ? role.name
                : `${role.name} (${role.permissionV2.topScope})`,
              value: role.id,
            })
          }
        })
      } /*else {
        options = this.allowRoles.map((role) => {
          return {
            label: !this.isDev
              ? role.name
              : `${role.name} (${role.permissionV2.topScope})`,
            value: role.id
          }
        })
      }*/

      console.log(`[NewAccount]roles:`, options.map(({label}) => label).length)
      return options
    },
    rolePermissionV2() {
      return this.roleList.find((item) => item.id === this.newAccount.roleId)
        .permissionV2
    },
    publicOptions() {
      return this.formatOptions(publicOptions)
    },
    forced2faOptions() {
      return this.formatOptions(forced2faOptions)
    },
    watermarkOptions() {
      return this.formatOptions(watermarkOptions)
    },
    haveError() {
      const errors = Object.keys(this.errorMsg)
      const eKey = errors.find((key) => this.errorMsg[key])

      return eKey ? true : false
    },
  },
  methods: {
    ...mapMutations('userinfo', [
      'updateUserId',
      'updateEditMode',
      'updateAccountPortal'
    ]),
    ...mapActions('userinfo', ['getUserInfoList']),
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    // getKeepDayOptions() {
    //   // 事件保留
    //   this.keepOptions = []
    //   this.keepOptions.push({
    //     value: 0,
    //     label: `365${this.$t('day')}`
    //   })
    //   for (let i = 1; i <= 365; i++)
    //     this.keepOptions.push({ value: i, label: `${i} ${this.$t('day')}` })
    // },
    checkIdInput(event) {
      const key = 'id'
      const { value } = event.target

      this.errorMsg[key] = null
      this.newAccount[key] = value.trim() // 移除字串起始及結尾處的空白字元

      if (this.newAccount[key] == null) {
        return
      }

      if (this.newAccount[key].length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info',
        }
      }
      // else if (this.newAccount[key].length < accLen.min) {}
      else if (!validateAccountIDChar(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_id_char_error',
          info: true
        }
      } else if (!validateAccountLen(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_id_length_error',
          param: {
            min: accLen.min,
            max: accLen.max
          }
        }
      }
    },
    checkPasswordInput(event) {
      const key = 'password'
      const { value } = event.target

      this.errorMsg[key] = null
      this.newAccount[key] = value.trim() // 移除字串起始及結尾處的空白字元

      if (this.newAccount[key] == null) {
        return
      }

      if (this.newAccount[key].length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info'
        }
      } else if (!validatePswChar(this.newAccount[key])) {
        console.log(``)
        this.errorMsg[key] = {
          key: 'account_pw_char_error', // 密碼只允許英數，大小寫，及特殊字元 {chars}
          info: true
        }
      } else if (!validatePwsLen(this.newAccount[key])) {
        const { min, max } = pswLen
        this.errorMsg[key] = {
          key: 'account_pw_length_error', // 密碼長度需{min}～{max}個字元
          param: {
            min,
            max
          }
        }
      } else if (!validatePswSpecialChar(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_pw_special_char_error', // 密碼須包含任一特殊字元 {chars}
          info: true
        }
      }

      this.checkConfirmPasswordInput()
    },
    checkConfirmPasswordInput(event) {
      const key = 'password_confirm'
      const value = event ? event.target.value.trim() : this.confirmPassword

      this.errorMsg[key] = null
      this.confirmPassword = value

      if (this.confirmPassword === null) {
        return
      }

      if (this.confirmPassword.length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info'
        }
      } else if (this.confirmPassword !== this.newAccount.password) {
        this.errorMsg[key] = {
          key: 'account_pw_error'
        }
      }
    },
    trimInput(event) {
      const { name, value } = event.target

      if (name) {
        this.newAccount[name] = value.trim()
      }
    },
    handleAccountIdShow() {
      this.showAccountId = !this.showAccountId
    },
    handlePswShow() {
      this.showPsw = !this.showPsw
    },
    handleConfirmPswShow() {
      this.showConfirmPsw = !this.showConfirmPsw
    },
    checkAllRequired() {
      let key = `id`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `password`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `password_confirm`
      if (!this.confirmPassword || this.confirmPassword.length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `roleId`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      return !this.haveError
    },
    handleCancel() {
      // 回到檢視帳號模式
      if (this.userId) {
        this.updateEditMode('editUser')
        this.updateUserId(this.userId)
      } else {
        this.updateEditMode('')
        this.updateUserId('')
      }
      this.showPortalCreateConfirm = false
    },
    async handleAddUser() {
      // console.log(`[handleAddUser] newAccount:`, this.newAccount)
      // if (this.isDev) return

      try {
        const { public: newPublic } = this.newAccount
        this.newAccount.public = newPublic ? euPublic.public : euPublic.private

        const res = await apiCreateUser(this.newAccount)
        // const res = { status: 200, data: {} } // for debug

        if (res.status === 200) {
          // 重新取得使用者資訊, 並切換到檢視模式
          await this.getUserInfoList()
          this.updateUserId(this.newAccount.id)
          this.updateEditMode('editUser')

          this.$notify({
            title: this.$t('account_add'),
            message: this.$t('account_add_success'),
            type: 'success',
          })
        }
      } catch (err) {
        this.$notify({
          title: this.$t('account_add'),
          message: `${this.$t('account_add_failed')}: ${
            err.response.data.message
          }`,
          type: 'error',
        })
      } finally {
        this.showPortalCreateConfirm = false
      }
    },
    onAddUser() {
      if (!this.checkAllRequired()) {
        this.$nextTick(() => {
          const errElem = document.querySelector(`.item-error:has(span)`)
          if (errElem) {
            errElem.scrollIntoView({block: "end", inline: "nearest", behavior: 'smooth' })
          }
        })

        return
      }

      this.updateAccountPortal('account-create-confirm')
      this.showPortalCreateConfirm = true
    },
    // onSwitchPublic(value) {
    //   this.newAccount.public = value
    // },
    onOpenPermission(open) {
      this.isRolePermissionShow = open
      this.newAccountRole = this.roleList.find(
        ({ id }) => id == this.newAccount.roleId
      )
    },
  },
  watch: {
    addAccountGroupId() {
      this.newAccount.groupId = `${this.addAccountGroupId}`
    },
    'newAccount.groupId'(nVal) {
      this.newAccount.groupId = `${nVal}` // 後台是接收字串的 groupId
    },
    'newAccount.roleId'() {
      this.errorMsg.roleId = this.newAccount.roleId ? null : 'account_required_info'
    }
  },
  created() {
    this.newAccount.groupId = `${this.addAccountGroupId}`
    this.newAccount.language = this.userFlatObj?.language
    this.newAccount.timezone = this.userFlatObj?.timezone

    this.newAccount['2faMode'] = this['2faMode']
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-new-account {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  font-size: 1rem;
  // font-weight: 300;
  line-height: px2rem(36);
  display: flex;
  flex-direction: column;

  .new-account {
    height: calc(100% - px2rem(120));
    padding: px2rem(8) 0 px2rem(20) px2rem(20);
    overflow: overlay;

    .info::v-deep {
      margin-bottom: px2rem(12);
      .required {
        @include required;
      }
    }

    .item {
      $HelperW: px2rem(48);
      display: flex;
      margin-top: px2rem(12);

      .item-block {
        display: flex;
        flex-direction: column;
        width: calc(100% - $HelperW);
        // background-color: #f00;
        .item-desc {
          margin-bottom: px2rem(4);
          font-size: 1rem;

          .acc-psw-i {
            @include img_icon_16x16;
            @include filter_FFF_50;
            &:hover {
              @include filter_FFF;
            }
          }
        }
        .item-detail {
          $TitleW: calc(25% * 1.5);

          display: flex;
          .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: $TitleW;
            border: 1px solid #ffffff80;
            border-right: none;
            border-radius: px2rem(10) 0px 0px px2rem(10);
            padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);
            color: #ffffff;
            background: #282942;

            &.group-title {
              border: unset;
              border-radius: unset;
              padding: unset;
              margin-top: px2rem(8);
              font-size: 1rem;
              font-weight: 700;
              color: #ffe99f;
            }

            $TitleIconW: px2rem(32);
            img {
              padding-right: px2rem(8);
              height: $TitleIconW;
              width: $TitleIconW;
            }

            .required {
              @include required;
            }
          }

          .content {
            $LeftGap: 1rem;

            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - $TitleW);
            min-height: px2rem(50);
            border-radius: 0px px2rem(10) px2rem(10) 0px;
            // padding-left: $LeftGap;
            color: #ffffff;
            background-color: #ffffff;
            // background-color: #00f;

            input {
              width: 100%;
              // height: 2rem;
              outline: none;
              border: none;
              padding-left: $LeftGap;
              // font-size: 1rem;
              font-size: 1.5rem;
              // font-weight: 300;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            div {
              width: calc(100% - $LeftGap);
            }

            .select-tree:deep {
              width: 100%;
              .select-selected {
                text-align: unset;
              }
            }

            .account,
            .password {
              $EyeW: px2rem(24);
              display: flex;
              width: 100%;
              // background-color: #f00;
              input {
                width: calc(100% - $LeftGap - $EyeW);
                &.security {
                  // IE & Firefox 不支援...
                  -webkit-text-security: disc;
                  -moz-text-security: disc;
                  text-security: disc;
                }
              }

              // eye
              img {
                width: $EyeW;
                height: $EyeW;
              }
            }

            .switch {
              padding-left: 1.5rem;
            }

            .dropdown:deep {
              height: fit-content;
              .select {
                .select-selected {
                  text-align: unset;
                }
              }
            }

            img {
              position: absolute;
              right: px2rem(12);
              cursor: pointer;
            }
          }
        }

        .item-error {
          font-size: 1rem;
          color: $color_F94144;

          .acc-psw-err-i {
            @include img_icon_16x16;
            @include filter_F94144_50;
            &:hover {
              @include filter_F94144;
            }
          }
        }
      }

      .item-helper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $HelperW;
        // background-color: #00f;

        .role-helper {
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: #0f0;

          .icon {
            display: flex;
            padding: 0.5rem;
            border-radius: px2rem(8);
            background-color: #ffffff1a; // 白色 10%

            &:hover {
              background-color: #ffffff4d; // 白色 30%
              cursor: pointer;
            }
          }

          .role-permission {
            position: absolute;
            top: 50%;
            right: $HelperW;
            width: 30%;
            z-index: 1;
          }
        }
      }
    }
  }
}

// .content > div {
//   width: 100%;
// }

// .my-select {
//   width: 100%;
// }

// .seperator {
//   width: 100%;
//   height: 1px;
//   background: #ffffff80;
//   margin-top: px2rem(12);
//   margin-bottom: px2rem(20);
// }

// .role-info {
//   margin-left: px2rem(16);
// }

// .role-permission {
//   width: 86%;
//   position: absolute;
//   right: px2rem(58);
//   top: px2rem(260);
//   bottom: px2rem(20);
//   background: #151B35;
//   color: #ffffff;
//   padding: px2rem(12);
//   border: 1px solid #4A5C78;
//   border-radius: px2rem(8);
//   overflow: overlay;
// }

// .role-permission .auth {
//   display: flex;
//   font-size: px2rem(24);
//   line-height: px2rem(35);
//   padding: px2rem(8) 0;
//   border-bottom: 1px solid #4A5C78;
// }

// .auth-title {
//   width: 60%;
// }

// .auth-value {
//   width: 40%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// -----------------------------------------
.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;

  .btn {
    border: 1px solid #ffc600;
    border-radius: px2rem(8);
    padding: px2rem(10) px2rem(48);
    margin-left: px2rem(16);
    margin-right: px2rem(16);
    font-size: 1rem;
    // font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &.cancel {
      color: #ffc600;
      &:hover {
        background: #ffc60033;
      }
    }

    &.confirm {
      background: #ffc600;
      color: #282942;
      &:hover {
        background: #ffd133;
      }
    }
  }
}
</style>
