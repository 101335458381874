<template>
  <div class="group-user-list">
    <div class="add-account">
      <div class="groups">
        <div class="check" :class="{ show: isMultiMode }" @click="onSelectAll">
          <img
            v-if="isIndeterminate"
            src="@/assets/icons/tree-indeterminate-yellow.svg"
            alt=""
          />
          <img
            v-else-if="checkAll"
            src="@/assets/icons/checkbox-yellow-checked.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/uncheck.svg" alt="" >
        </div>
        <div class="group-family">
          <div
            class="group-family-item"
            v-for="group in groupFamily(groupId)"
            :key="group.id"
            @click="updateGroupId(group.id)"
          >
            <img src="@/assets/icons/group-icon.svg" alt="" />
            <span>{{ group.name }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="quota">
        <span>{{ $t('account_viewing_quota') }}</span>
        <span>{{ quotaUsed }}/{{ quotaGroup }}/{{ quotaTotal }}</span>
        <div
          class="btn"
          :style="{ visibility: showEdit ? 'visible' : 'hidden' }"
          @click="onCreateAccount(groupId)"
        >
          {{ $t('account_add') }}
        </div>
      </div> -->
    </div>

    <div class="user-content">
      <div
        class="row"
        :class="{
          'text-gray': user.enabled === 0,
          // 'text-red': user.locked === 1,
          selected: user.id === userId,
          self: isSelf(user)
        }"
        v-for="user in userList"
        :key="user.id"
        @click="onSelectUser(user.id)"
      >
        <div class="col">
          <div
            class="check"
            :class="{ show: isMultiMode }"
            @click.stop="onMultiSelectUser(user.id)"
          >
            <img
              v-if="isUserChecked(user.id)"
              src="@/assets/icons/checkbox-yellow-checked.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/uncheck.svg" alt="" />
          </div>
        </div>
        <div class="col">
          <template v-if="true">
            <img
              v-if="user.public === euPublic.public"
              class="public-icon"
              src="@/assets/icons/account_detail_public.svg"
              alt=""
            />
            <img
              v-else
              class="public-icon"
              src="@/assets/icons/account_detail_private.svg"
              alt=""
            />
          </template>
          <img class="user-icon" :src="getUserIcon(user)" alt="" />
          <template v-if="false">
            <img
              v-if="user.public === euPublic.public"
              class="public-icon"
              src="@/assets/icons/account_detail_public.svg"
              alt=""
            />
            <img
              v-else
              class="public-icon"
              src="@/assets/icons/account_detail_private.svg"
              alt=""
            />
          </template>
          <TooltipElement :content="user.id" placement="bottom">
            {{ user.id }}
          </TooltipElement>
        </div>
        <div class="col">{{ user.info.name }}</div>
        <div class="col">
          {{ user.roleName }}
        </div>
        <div class="col">
          <img
            v-if="user.locked === 1 && user.id === userId"
            class="lock"
            src="@/assets/icons/lock.svg"
            @click="unLock(user.id)"
            alt=""
          />
          <!-- <img v-if="user.id === userId" class="lock" 
            src="@/assets/icons/lock.svg" alt="" @click="unLock(user.id)"> -->
          <img
            v-if="isMultiMode && isSelectedUser(user)"
            :src="getMultiModeStateIconUrl(user)"
            class="multi-mode"
          />
        </div>
      </div>
    </div>
    <PortalEditMessage
      v-if="showPortalEditMessage"
      :title="portal.title"
      :content="portal.content"
      :tmpData="portal.tmpData"
      @close="closePortalEditMessage"
      @confirm="handlePortalEditConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { apiUnlockUser } from '@/api/index.js'
import { euPublic } from '@/config/account.js'

export default {
  name: 'UserTableList',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    PortalEditMessage: () =>
      import('@/components/AccountManagement/portal/PortalEditMessage.vue')
  },
  props: {
    showEdit: {
      type: Boolean
    },
    userList: {
      type: Array
    },
    groupId: {
      type: String
    }
  },
  data() {
    return {
      selectRoles: [],
      getAbnormalAccount: '',
      multiSelectShow: false,
      euPublic,
      // edit confirm
      showPortalEditMessage: false,
      portal: {
        title: '',
        content: '',
        // action: '',
        tmpData: '',
        actionIndex: -1
      }
    }
  },
  computed: {
    ...mapState(['permissionV2', 'liveList']),
    ...mapState('userinfo', {
      userId: (state) => state.userId,
      filterText: (state) => state.filterText,
      roleList: (state) => state.roleList,
      userInfoList: (state) => state.userInfoList,
      selectedUserList: (state) => state.selectedUserList,
      editMode: (state) => state.editMode,
      treeGroupId: (state) => state.groupId, // alias
    }),
    ...mapGetters(['getUserInfo']),
    ...mapGetters('userinfo', ['isUserChecked', 'groupFamily']),
    isMultiMode() {
      return this.editMode === 'multiSelect'
    },
    roles() {
      if (!this.roleList) return []
      let options = []
      this.roleList.forEach((role) => {
        options.push({
          label: role.name,
          value: role.id
        })
      })
      return options
    },
    checkAll() {
      const notCheckList = this.userList.filter(
        (user) =>
          !this.selectedUserList.find(
            (selectedUser) => user.id == selectedUser.id
          )
      )
      return notCheckList.length === 0
    },
    isIndeterminate() {
      let count = 0
      this.userList.forEach((user) => {
        if (
          this.selectedUserList.find(
            (selectedUser) => user.id == selectedUser.id
          )
        )
          count++
      })
      return count > 0 && count < this.userList.length
    },
    // quotaUsed() {
    //   let count = 0
    //   this.liveList.forEach(live => {
    //     if (live.groupId === this.groupId) count++
    //   })
    //   return count
    // },
    // quotaGroup() {
    //   const family = this.groupFamily(this.groupId, this.treeGroupId)
    //   return family.length > 0 ? family[family.length - 1].maxIn : 0
    // },
    // quotaTotal() {
    //   const family = this.groupFamily(this.groupId, this.treeGroupId)
    //   return family.length > 0 ? family[0].maxIn : 0
    // },
  },
  methods: {
    ...mapMutations('userinfo', [
      'updateGroupId',
      'updateUserId',
      'updateSelectedUser',
      'addSelectedUserList',
      'removeSelectedUserList',
      'updateEditMode',
      // 'updateAddAccountGroupId',
      'updateAccountPortal'
    ]),
    ...mapActions('userinfo', ['getUserInfoList']),
    isSelf(userInfo) {
      if (this.getUserInfo) {
        return this.getUserInfo.id === userInfo.id
      }
      return false
    },
    isSelectedUser(userInfo) {
      const checked = this.selectedUserList.find(
        (user) => user.id === userInfo.id
      )

      return checked ? checked.state !== -1 : false
    },
    getUserIcon(user) {
      if (user.enabled === 0) {
        return require(`@/assets/icons/user_disable.svg`)
      } else if (user.locked === 1) {
        return require(`@/assets/icons/user_locked.svg`)
      } else if (user.suspendedTime) {
        return require(`@/assets/icons/user_pause.svg`)
      }
      return require(`@/assets/icons/user.svg`)
    },
    getMultiModeStateIconUrl(userInfo) {
      const userIdx = this.selectedUserList.findIndex(
        (user) => user.id === userInfo.id
      )

      if (userIdx < 0) {
        return
      }

      const { state } = this.selectedUserList[userIdx]
      switch (state) {
        case 0: // 設定中
          return require('@/assets/icons/loading.svg')
        case 1: // 成功
          return require('@/assets/icons/success-check.svg')
        case 2: // 失敗
          return require('@/assets/icons/failed.svg')
        default:
          return
      }
    },
    onSelectAll() {
      if (this.checkAll) this.removeSelectedUserList(this.userList)
      else this.addSelectedUserList(this.userList)
    },
    onMultiSelectUser(userId) {
      // if (this.isSelf(user)) return
      this.updateSelectedUser(userId)
    },
    onSelectUser(userId) {
      this.portal.tmpData = userId

      this.updateUserId(userId)
      switch (this.editMode) {
        case '':
        case 'editUser':
          // single select user
          this.updateEditMode('editUser')
          break
        case 'multiSelect':
          this.onMultiSelectUser(userId)
          break
        default:
          this.portal.title = this.$t('am_edit_not_complete')
          this.portal.content = this.$t('am_cancel_edit')
          this.portal.action = 'confirm'
          this.portal.actionIndex = -1
          this.updateAccountPortal('portal-edit-message')
          this.showPortalEditMessage = true
      }
    },
    closePortalEditMessage() {
      // keep editMode = euEditMode.editing
      this.showPortalEditMessage = false
    },
    handlePortalEditConfirm() {
      this.showPortalEditMessage = false
      this.updateEditMode('editUser')
      this.updateUserId(this.portal.tmpData)
    },
    // onCreateAccount(groupId) {
    //   // this.updateUserId('') // 先移除先前檢視的帳號
    //   this.updateEditMode('addUser')
    //   this.updateAddAccountGroupId(groupId)
    // },
    async unLock(userId) {
      const res = await apiUnlockUser(userId)
      if (res.status === 200) {
        // 重新取得使用者資訊, 並切換到檢視模式
        await this.getUserInfoList()
        this.updateEditMode('editUser')
        this.updateUserId(userId)
        this.$notify({
          title: this.$t('account_unlock'),
          message: this.$t('account_unlock_success'),
          type: 'success',
        })
      } else {
        this.$notify({
          title: this.$t('account_unlock'),
          message: this.$t('account_unlock_failed'),
          type: 'error',
        })
      }
    },
  },
  // watch: {
  //   selectedUserList: {
  //     deep: true,
  //     handler: (nVal) => {
  //       console.log(`[Watch.selectedUserList] selectedUserList:`, nVal)
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
$ActionIconW: 3%; // 1rem;
$ShortInfoW: 10%; // 5rem;
$LongInfoW: calc($ShortInfoW * 2); // 10rem;
$InfoPadding: 1rem;
$fontSize: px2rem(24);

* {
  box-sizing: border-box;
}
.group-user-list {
  margin-bottom: px2rem(12);

  .add-account {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: px2rem(4);
    margin-right: px2rem(13);
    border: 1px solid #4a5c78;
    padding: px2rem(7) px2rem(16) px2rem(7) 0.75rem;
    background: #39425d;
    z-index: 1;

    .groups {
      display: flex;
      align-items: center;

      .group-family {
        img {
          width: px2rem(24);
          height: px2rem(24);
          vertical-align: text-bottom;
        }
        .group-family-item {
          position: relative;
          display: inline-block;
          margin-right: px2rem(36);
          font-size: px2rem(20);
          font-weight: 500;
          color: #ffc600;
          cursor: pointer;

          span {
            position: relative;
            margin-left: px2rem(8);

            &:hover {
              border-bottom: 2px solid #ffc600;
              cursor: pointer;
            }
          }

          &:not(:last-child):before {
            content: '';
            position: absolute;
            left: calc(100% + px2rem(10));
            top: px2rem(6);
            width: px2rem(24);
            height: px2rem(24);
            background-image: url('../../../../assets/icons/RightArrow.svg');
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .user-content {
    margin-left: px2rem(4);
    margin-right: px2rem(13);
    border: 1px solid #4a5c78;
    border-top: none;

    .row {
      display: flex;
      align-items: center;
      // padding: 0 px2rem(4);
      min-height: px2rem(50);

      &:hover {
        background: #6E7D9333;
      }
      &:active {
        background: #6E7D9399;
      }

      &.self {
        background-color: $SelfAccountBGC;
      }

      &.text-gray {
        color: #ffffff33;
      }
      &.text-red {
        color: #f94144;
      }
      &.selected {
        background: #6e7d9399;
      }

      // > .col {
      //   display: flex;
      //   align-items: center;
      //   padding-left: px2rem(52);

      //   img {
      //     margin-right: px2rem(12);
      //   }
      // }

      .col:deep {
        display: flex;
        margin-right: 0.4rem;
        padding-left: 0.5rem;
        width: px2rem(24); // 基準
        font-size: px2rem(20);

        // TooltipElement
        .tooltip-wrap {
          display: inline !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        > img {
          margin-right: 0.4rem;
        }
        // 多選帳號 checkbox
        &:first-child {
          margin-left: 0.75rem;
          padding-left: unset;
        }
        // 帳號(包含 帳號ICON, 公開ICON, 帳號ID)
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: calc($LongInfoW + $ShortInfoW);
        }
        &:last-child {
          margin-right: unset;
          padding-left: unset;
        }

        img.multi-mode {
          width: px2rem(20);
          height: px2rem(20);
          // background-color: #f00;

          &[src*='loading'] {
            animation: rotation 1s infinite linear;
          }
          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  .check {
    display: flex;
    margin-right: px2rem(16);
    width: px2rem(20);
    height: px2rem(20);
    cursor: pointer;
    visibility: hidden;

    &.show {
      // margin-bottom: -0.5rem;
      visibility: visible;
    }

    // .user-icon {}
  }

  .public-icon {
    margin-top: 0.25rem;
    width: px2rem(19);
    height: px2rem(19);
    &.disabled {
      opacity: 0.2;
    }
  }
}

.lock {
  margin-left: -0.5rem;
  filter: brightness(0) saturate(100%) invert(28%) sepia(39%) saturate(5449%) 
    hue-rotate(340deg) brightness(107%) contrast(95%);
  cursor: pointer;
}
</style>
