<template>
  <div class="wrap-group-table">
    <div class="group-table">
      <div class="header">
        <div class="row">
          <div class="col col-group">{{ $t('am_group_title') }}</div>
          <div class="col">{{ $t('am_view_quota_title') }}</div>
          <div class="col">{{ $t('am_live_quota_title') }}</div>
          <div
            class="col col-edit"
            :style="{
              visibility: showEdit ? 'visible' : 'hidden'
            }"
          >
          <div v-if="!bEdit" class="edit-quota" @click="onEdit">{{ this.$t('am_quota_edit') }}</div>
          <div v-else class="edit-quota" @click="onEdit">{{ this.$t('am_quota_editing') }}</div>
          </div>
        </div>
      </div>
      <div class="group-content">
        <div class="group-table-description">
          <div class="col col-group"></div>
          <div class="col">{{ $t('am_quota_desc') }}</div>
          <div class="col">{{ $t('am_quota_desc') }}</div>
          <div class="col col-create"></div>
        </div>
        <div class="group-tree">
          <el-tree
            ref="elTree"
            :data="groupTreeList"
            :props="defaultProps"
            node-key="id"
            :empty-text="$t('tree_processing_info')"
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            @node-click="onNodeClick"
          >
            <div
              class="tree-node"
              slot-scope="{ node, data }"
              :class="{ 'self-group': isSelfGroup(node) }"
            >
              <div class="name">
                <div class="arrow">
                  <i
                    class="el-tree-node__expand-icon"
                    :class="{ expanded: node.expanded }"
                  />
                </div>
                <div class="icon">
                  <img
                    v-if="data.children.length > 0"
                    src="@/assets/icons/group-icon.svg"
                    alt=""
                  />
                  <img
                    v-else-if="data.children.length <= 0 && hasLimitError(data)"
                    src="@/assets/icons/group-error.svg"
                    alt=""
                  />
                  <img
                    v-else-if="
                      data.children.length <= 0 && !hasLimitError(data)
                    "
                    src="@/assets/icons/group-icon.svg"
                    alt=""
                  />
                  <img
                    v-if="hasLimitError(data)"
                    src="@/assets/icons/atention.svg"
                    alt=""
                    class="error"
                  />
                </div>
                <div
                  :class="{
                    limit: data.children.length <= 0 && hasLimitError(data)
                  }"
                  class="text"
                >
                  {{ node.label }}
                </div>
              </div>
              <div class="view">
                <div
                  class="value"
                  :class="{ limit: isLimitError(data, 'maxOut') }"
                >
                  <div>{{ getUsedCnt(data, true) }}</div>
                  /
                  <div>{{ getDistributedCnt(data, 'maxOut') }}</div>
                  /
                  <div>{{ getAllCnt(data, 'maxOut') }}</div>
                </div>
                <div class="active">
                  <div class="one">
                    <div
                      class="plus"
                      :edit="bEdit"
                      @click.stop="() => onAddQuota(data, 'maxOut', 1)"
                    >
                      +1
                    </div>
                    <div
                      class="minus"
                      :edit="bEdit"
                      @click.stop="() => onMinusQuota(data, 'maxOut', 1)"
                    >
                      -1
                    </div>
                  </div>
                  <div class="ten">
                    <div
                      class="plus"
                      :edit="bEdit"
                      @click.stop="() => onAddQuota(data, 'maxOut', 10)"
                    >
                      +10
                    </div>
                    <div
                      class="minus"
                      :edit="bEdit"
                      @click.stop="() => onMinusQuota(data, 'maxOut', 10)"
                    >
                      -10
                    </div>
                  </div>
                </div>
              </div>
              <div class="live">
                <div
                  class="value"
                  :class="{ limit: isLimitError(data, 'maxIn') }"
                >
                  <div>{{ getUsedCnt(data, false) }}</div>
                  /
                  <div>{{ getDistributedCnt(data, 'maxIn') }}</div>
                  /
                  <div>{{ getAllCnt(data, 'maxIn') }}</div>
                </div>
                <div class="active">
                  <div class="one">
                    <div
                      class="plus"
                      :edit="bEdit"
                      @click.stop="() => onAddQuota(data, 'maxIn', 1)"
                    >
                      +1
                    </div>
                    <div
                      class="minus"
                      :edit="bEdit"
                      @click.stop="() => onMinusQuota(data, 'maxIn', 1)"
                    >
                      -1
                    </div>
                  </div>
                  <div class="ten">
                    <div
                      class="plus"
                      :edit="bEdit"
                      @click.stop="() => onAddQuota(data, 'maxIn', 10)"
                    >
                      +10
                    </div>
                    <div
                      class="minus"
                      :edit="bEdit"
                      @click.stop="() => onMinusQuota(data, 'maxIn', 10)"
                    >
                      -10
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="create-group"
                :edit="showEdit && !bEdit"
                @click.stop="() => onCreateGroup(data)"
              >
                {{ $t('am_add_group') }}
              </div>
              <!-- <div
                class="create-group"
                :edit="!bEdit"
                @click.stop="() => onDeleteGroup(data)"
              >
                {{ `刪除群組` }}
              </div> -->
            </div>
          </el-tree>
        </div>
      </div>
    </div>
    <PortalEditMessage
      v-if="showPortalEditMessage"
      :title="portal.title"
      :content="portal.content"
      :tmpData="portal.tmpData"
      @close="closePortalEditMessage"
      @confirm="confirmPortalEditMessage"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { euEditMode } from '@/config/account.js'
import PortalEditMessage from '@/components/AccountManagement/portal/PortalEditMessage.vue'
import {
  apiGetLiveList,
  apiGetServerList /*, apiEditGroup*/
} from '@/api/index.js'
import { getNode, getNodeKids, sumNum } from '@/utils/lib.js'

export default {
  name: 'GroupTable',
  components: { PortalEditMessage },
  props: ['showEdit'],
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      // editGroupTreeList: [],
      refreshSec: 5000,
      refreshIDLive: null,
      refreshIDView: null,
      viewList: [],
      liveList: [],
      bEdit: false,
      // editQuotaMsg: this.$t('am_quota_edit'),
      showPortalEditMessage: false,
      portal: {
        title: '',
        content: '',
        // action: '',
        tmpData: '',
        actionIndex: -1
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState('userinfo', ['groupTreeList', 'groupInfoList']),
    ...mapState('userinfo/group', [
      'filterText',
      'groupId',
      'editMode',
      'editQuota'
    ])
  },
  async mounted() {
    await this.getLiveList() // 每 refreshSec(5)秒 重複執行
    await this.getViewList() // 每 refreshSec(5)秒 重複執行

    if (this.filterText) {
      this.$refs?.elTree?.filter(this.filterText)
    }
  },
  watch: {
    filterText(val) {
      this.$refs?.elTree?.filter(val)
    },
    editQuota(val) {
      this.bEdit = val
    },
    // bEdit(val) {
    //   this.editQuotaMsg = val
    //     ? this.$t('am_quota_editing')
    //     : this.$t('am_quota_edit')
    // },
    groupTreeList() {
      if (this.filterText) {
        // console.log(`[table.watch.groupTreeList]`);
        this.$refs?.elTree?.filter(this.filterText)
      }
    }
  },
  methods: {
    ...mapMutations('userinfo', ['updateAccountPortal']),
    ...mapMutations('userinfo/group', [
      'updateEditMode',
      'updateParentGroupId',
      'updateGroupId',
      'updateEditQuota'
    ]),
    isSelfGroup(node) {
      const { data } = node

      return this.getUserInfo?.groupId === data.id
    },
    cleanLiveRefresh() {
      if (this.refreshIDLive) {
        clearTimeout(this.refreshIDLive)
        this.refreshIDLive = null
      }
    },
    async getLiveList() {
      const res = await apiGetLiveList()
      if (res.status === 200) {
        this.liveList = res.data.liveList
      }

      // TODO
      // if(!this.refreshIDLive) {
      //   this.refreshIDLive = setTimeout(async () => {
      //     try {
      //       const res = await apiGetLiveList()
      //       if( res.status === 200 ) {
      //         this.liveList = res.data.liveList

      //         this.cleanLiveRefresh()
      //         this.getLiveList()
      //       } else {
      //         throw res
      //       }
      //     } catch (err) {
      //       console.log(err)
      //     } finally {
      //       // console.log(`[getLiveList] this.liveList:`, this.liveList);
      //     }
      //   }, this.refreshSec)
      // }
    },
    cleanViewRefresh() {
      if (this.refreshIDView) {
        clearTimeout(this.refreshIDView)
        this.refreshIDView = null
      }
    },
    async getViewList() {
      const res = await apiGetServerList()
      if (res.status === 200) {
        const raw = res.data
        const siteName = Object.keys(raw)
        const serverId = Object.keys(raw[siteName])
        const { service } = raw[siteName][serverId]

        this.viewList = []
        service['streamer-manager'].live.forEach((live) => {
          if (live.viewer) {
            this.viewList = [...this.viewList, ...live.viewer]
          }
        })
      }

      // TODO
      // if(!this.refreshIDView) {
      //   this.refreshIDView = setTimeout(async () => {
      //     try {
      //       const res = await apiGetServerList()
      //       if( res.status === 200 ) {
      //         const raw = res.data
      //         const siteName = Object.keys(raw)
      //         const serverId = Object.keys(raw[siteName])
      //         const { service } = raw[siteName][serverId]

      //         this.viewList = []
      //         service['streamer-manager'].live.forEach((live) => {
      //           if(live.viewer) {
      //             this.viewList = [...this.viewList, ...live.viewer]
      //           }
      //         })

      //         this.cleanViewRefresh()
      //         this.getViewList()
      //       } else {
      //         throw res
      //       }
      //     } catch (err) {
      //       console.error(`[getViewList] err:`, err)
      //     } finally {
      //       // console.log(`[getViewList] this.viewList:`, this.viewList);
      //     }
      //   }, this.refreshSec)
      // }
    },
    getUsedCnt(grp, isView) {
      let used = 0
      const kidsId = getNodeKids(this.groupTreeList, grp.id).map(
        (kid) => kid.id
      )
      // 發布的list
      if (!isView && this.liveList.length > 0) {
        if (grp.children.length > 0) {
          // node
          // const kidsId = getNodeKids(this.groupTreeList, grp.id).map((kid) => kid.id)
          const liveKids = this.liveList.filter((live) =>
            kidsId.includes(live.groupId)
          )
          const self = this.liveList.filter((live) => live.groupId === grp.id)

          used += self ? self.length : 0
          used += liveKids.length
        } else {
          // leaf
          const liveKids = this.liveList.filter(
            (live) => live.groupId === grp.id
          )
          used += liveKids.length
        }
      }
      // 收看的list
      else if (isView && this.viewList.length > 0) {
        if (grp.children.length > 0) {
          // node
          // const kidsId = getNodeKids(this.groupTreeList, grp.id).map((kid) => kid.id)
          const viewers = this.viewList.filter((view) =>
            kidsId.includes(view.groupId)
          )
          const self = this.viewList.filter((live) => live.groupId === grp.id)

          used += self ? self.length : 0
          used += viewers.length
        } else {
          // leaf
          const viewers = this.viewList.filter(
            (view) => view.groupId === grp.id
          )
          used += viewers.length
        }
      }
      // console.log(`[getUsedCnt] ${grp.name} used:`, used)
      return used
    },
    getDistributedCnt(grp, key) {
      let distributed = 0
      if (this.groupInfoList.length > 0) {
        if (grp.children.length > 0) {
          // node
          // 算出下一層(=子層) (不含下下層) maxIn 或 maxOut
          grp.children.forEach((kid) => {
            const grpCfg = this.groupInfoList.find((item) => item.id === kid.id)
            distributed += grpCfg[key] ? grpCfg[key] : 0
          })
        } else {
          // leaf
          const grpCfg = this.groupInfoList.find((item) => item.id === grp.id)
          distributed = grpCfg[key] ? grpCfg[key] : 0
        }
      }
      // console.log(`[getDistributedCnt] ${grp.name} distributed:`, distributed)

      return distributed
    },
    getAllCnt(grp, key) {
      let all = 0
      if (this.groupInfoList.length > 0) {
        const grpCfg = this.groupInfoList.find((item) => item.id === grp.id)
        all = grpCfg[key] ? grpCfg[key] : 0
      }

      // console.log(`[getAllCnt] ${grp.name} all:`, all)
      return all
    },
    hasLimitError(grp) {
      if (this.groupInfoList.length > 0) {
        const maxInAll = this.getAllCnt(grp, 'maxIn')
        const maxOutAll = this.getAllCnt(grp, 'maxOut')

        if (maxInAll <= 0 || maxOutAll <= 0) {
          return true
        }
        if (grp.children.length > 0) {
          const maxInDistributed = this.getDistributedCnt(grp, 'maxIn')
          const maxOutDistributed = this.getDistributedCnt(grp, 'maxOut')

          if (maxInDistributed >= maxInAll || maxOutDistributed >= maxOutAll) {
            return true
          }

          const kidHasError = grp.children.map(
            (kid) =>
              this.isLimitError(kid, 'maxIn') ||
              this.isLimitError(kid, 'maxOut')
          )
          if (kidHasError.filter((errorKid) => errorKid === true).length > 0) {
            return true
          }
        }
      }
      return false
    },
    isLimitError(grp, key) {
      if (this.groupInfoList.length > 0) {
        const all = this.getAllCnt(grp, key)

        if (all <= 0) {
          return true
        }
        if (grp.children.length > 0) {
          const distributed = this.getDistributedCnt(grp, key)

          if (distributed >= all) {
            return true
          }
        }
      }

      return false
    },
    filterNode(value, data) {
      if (!value) return true

      const name = data.name.toLowerCase()
      return name.indexOf(value.toLowerCase()) >= 0
    },
    onNodeClick(data) {
      // console.log(`[onNodeClick] data:`, JSON.stringify(data))
      // 重複點選已經選中的節點，則取消選中
      let setValue = data.id === this.groupId ? false : true

      if (setValue) {
        this.portal.tmpData = data.id
        if (
          this.editMode === euEditMode.none ||
          this.editMode === euEditMode.editView
        ) {
          this.updateEditMode(euEditMode.editView)
          this.updateGroupId(data.id)
          this.$refs.elTree.setCurrentKey(data.id)
        } else {
          this.portal.title = this.$t('am_edit_not_complete')
          this.portal.content = this.$t('am_cancel_edit')
          // this.portal.action = 'confirm'
          this.portal.actionIndex = -1

          this.updateAccountPortal('portal-edit-message')
          this.showPortalEditMessage = true
        }
      } else {
        this.updateEditMode(euEditMode.none)
        this.updateGroupId(null)
        this.$refs.elTree.setCurrentKey(null)
      }
    },
    onCreateGroup(data) {
      // console.log(`[onCreateGroup] data:`, JSON.stringify(data))
      this.updateEditMode(euEditMode.new)
      this.updateGroupId(null)
      this.updateParentGroupId(data.id)
    },
    // async onDeleteGroup(data) {
    //   try {
    //     const { apiDeleteGroup } = require(`@/api/index.js`)
    //     console.log(`[onDeleteGroup] data:`, data.id)
    //     const res = await apiDeleteGroup(data.id)

    //     console.log(`[onDeleteGroup] res:`, res)
    //     if (res.status === 200) {
    //       this.updateGroupId(null)
    //       this.updateEditMode(euEditMode.none)
    //       this.$notify({
    //         title: this.$t('刪除群組'),
    //         message: this.$t('已成功刪除群組'),
    //         type: 'success',
    //       })
    //     } else {
    //       throw res
    //     }
    //   } catch (err) {
    //     console.error(`[onDeleteGroup] apiDeleteGroup fail.`, err)
    //     this.$notify({
    //       title: this.$t('刪除群組'),
    //       message: `${this.$t('刪除群組發生錯誤')}: ${
    //         err.response?.data.message
    //       }`,
    //       type: 'error',
    //     })
    //   }
    // },
    onEdit(/*event*/) {
      this.bEdit = !this.bEdit

      this.updateEditQuota(this.bEdit)
      this.updateEditMode(this.bEdit ? euEditMode.none : euEditMode.editView)
    },
    async onAddQuota(node, key, num) {
      const editGroup = this.groupInfoList.find((group) => group.id === node.id)

      // Check parent has quota or not
      const parentNode = getNode(this.groupTreeList, editGroup.parent)
      const kidGroups = parentNode.children.map((kid) =>
        this.groupInfoList.find((group) => group.id === kid.id)
      )
      const quota = sumNum(kidGroups.map((kid) => kid[key])) // parent 已分配多少
      const parentGroup = this.groupInfoList.find(
        (group) => group.id === editGroup.parent
      )

      if (parentGroup[key] - quota < num) {
        // 配額不足
        this.$notify({
          title: `${this.$t('am_quota_short_hint1')} ${
            parentGroup.name
          } ${this.$t('am_quota_short_hint2')}`,
          type: 'error',
        })
        return
      }

      editGroup[key] = editGroup[key] + num
    },
    async onMinusQuota(node, key, num) {
      const editGroup = this.groupInfoList.find((group) => group.id === node.id)

      // Check quota has been used or not
      let quota = 0
      if (node.children.length > 0) {
        const kidGroups = node.children.map((kid) =>
          this.groupInfoList.find((group) => group.id === kid.id)
        )
        quota = sumNum(kidGroups.map((kid) => kid[key])) // kid 已分配多少
      }

      if (editGroup[key] - quota < num) {
        // 配額不足
        this.$notify({
          title: `${this.$t('am_quota_short_hint1')} ${
            editGroup.name
          } ${this.$t('am_quota_short_hint2')}`,
          type: 'error',
        })
        return
      }

      editGroup[key] = editGroup[key] - num
    },
    closePortalEditMessage() {
      this.showPortalEditMessage = false
      // const node = getNode(this.groupTreeList, this.groupId)
      // this.onNodeClick(node)
      this.updateEditMode(euEditMode.editing)
      this.updateGroupId(this.groupId)
    },
    confirmPortalEditMessage() {
      this.showPortalEditMessage = false
      this.updateEditMode(euEditMode.editView)
      this.updateGroupId(this.portal.tmpData)
    }
  },
  destroyed() {
    this.cleanLiveRefresh()
    this.cleanViewRefresh()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-group-table {
  width: 66%;
  height: 100%;
  background: #282942;
  border-right: 1px solid #4a5c78;
}

.group-table {
  height: calc(100%);
  color: #ffffff;
  font-size: px2rem(24);
  font-weight: 300;
  line-height: px2rem(30);
  height: 100%;
}

.header {
  font-size: px2rem(20);
  height: px2rem(50);
}
.header .row {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.header .row:hover {
  background: #6e7d9333;
}
.header .row:active {
  background: #6e7d9399;
}
.header .row .col {
  padding-left: px2rem(20);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.header .col {
  width: calc(((100% - px2rem(55)) - 30% - 5%) / 2);
  position: relative;
}
.col-group {
  width: 30% !important;
  justify-content: flex-start !important;
}
.col-create {
  width: 5% !important;
}

.col-edit {
  // width: 10% !important;
  justify-content: flex-end !important;
}

.edit-quota {
  cursor: pointer;
  display: flex;
  // width: px2rem(100);
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #ffffff33;
  text-align: center;
  font-size: px2rem(20);
  border-radius: px2rem(8);
  // margin: px2rem(2) px2rem(16);
  padding: px2rem(4);
}
.edit-quota:hover {
  background-color: #ffffff99;
}

.group-content {
  height: calc(100% - px2rem(50));
  overflow: hidden;
}

.group-table-description {
  background: #39425d;
  border: 1px solid #4a5c78;
  padding: px2rem(7) px2rem(16) px2rem(7) px2rem(20);
  font-size: px2rem(20);
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}
.group-table-description .col {
  width: calc(((100% - px2rem(55)) - 30% - 5%) / 2);
  position: relative;
  text-align: center;
}
.group-tree {
  background-color: #151b35;
  padding-right: px2rem(11);
  height: calc(100% - px2rem(50));
  overflow: overlay;
}
/* tree node */
.tree-node {
  display: flex;
  width: 100%;
  // height: px2rem(56);
  padding: px2rem(8) 0;
}
.tree-node .name {
  display: flex;
  padding: px2rem(8) 0;
  width: 30%;
}
.tree-node .name .arrow {
  color: #ffffff;
}
.tree-node .name .icon {
  position: relative;
  display: flex;
  height: px2rem(24);
  width: px2rem(24);
}
.tree-node .icon img {
  height: 100%;
  width: 100%;
  font-size: 0;
}
.tree-node .icon .error {
  position: absolute;
  height: px2rem(20);
  width: px2rem(20);
  top: -35%;
  right: -42%;
}
.tree-node .name .text {
  color: #ffc600;
  padding-left: px2rem(8);
}
.tree-node .view,
.tree-node .live {
  display: flex;
  color: #ffffff;
  width: calc(((100% - px2rem(55)) - 30% - 5%) / 2);
  align-items: center;
  justify-content: flex-end;
}
.value {
  display: flex;
  /* background-color: #f00; */
}
.value div {
  padding: 0 px2rem(8);
  width: 4rem;
  text-align: center;
}
/*.el-tree-node__content:hover*/
.active {
  display: flex;
  font-size: px2rem(16);
  text-align: center;
  padding-left: 1rem;
  width: 7.75rem;
  height: 4.125rem;
}
.el-tree-node__content:hover .plus[edit='true'],
.el-tree-node__content:hover .minus[edit='true'],
.el-tree-node__content:hover .create-group[edit='true'] {
  visibility: visible;
  // background-color: #F94144;
}
.active .plus,
.active .minus {
  width: px2rem(34);
  visibility: hidden;
  flex-direction: column;
  margin: 2px;
  background-color: #ffffff33;
}
.active .plus {
  border-radius: px2rem(4) px2rem(4) 0 0;
}
.active .minus {
  border-radius: 0 0 px2rem(4) px2rem(4);
}
.active .plus:hover,
.active .minus:hover,
.tree-node .create-group:hover {
  background-color: #ffffff99;
}

.tree-node .create-group {
  visibility: hidden;
  // width: px2rem(100);
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #ffffff33;
  text-align: center;
  font-size: px2rem(20);
  border-radius: px2rem(8);
  margin: px2rem(2) px2rem(16);
}
.limit {
  color: #f94144 !important;
}

::v-deep .el-tree {
  background: #151b35;
  min-width: 100%;
  display: inline-block;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: px2rem(6) 0;
  align-items: center;
}

::v-deep .el-tree-node .el-tree-node__children {
  overflow: visible;
}

::v-deep .el-tree-node__label {
  font-size: px2rem(20);
  font-weight: 300;
  line-height: px2rem(30);
  color: #ffc600;
}

/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;

  &:has(.self-group) {
    background-color: $SelfGroupBGC;
  }
}

::v-deep .el-tree-node__content .is-leaf {
  color: transparent;
  cursor: default;
}

::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon .is-leaf {
  cursor: default;
}

::v-deep .el-tree-node__expand-icon {
  color: #f3f4f4;
  font-size: px2rem(20);
}

::v-deep .el-tree-node__content:hover {
  background: #6e7d9333 !important;
  &:has(.self-group) {
    background-color: $SelfGroupBGC !important;
  }
}

/*::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon {
   //color: #2a303e; 
}*/

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background-color: #6e7d9399 !important;
}

::v-deep .el-tree > .el-tree-node.is-expanded > .el-tree-node__content {
  background: #151b35;
}

/*::v-deep
  .el-tree-node:focus
  > .el-tree-node__content
  .el-tree-node__expand-icon {
   //color: #2a303e; 
}*/

/*::v-deep
  .el-tree-node.is-current
  .el-tree-node__content
  .el-tree-node__expand-icon {
   //color: #2a303e; 
}*/

::v-deep .el-tree-node > .el-tree-node__content {
  min-height: px2rem(32);
}

::v-deep .el-tree-node__children > .el-tree-node__content {
  min-height: px2rem(32);
}
</style>
