<template>
  <portal to="account-helper">
    <div class="account-helper-modal" @click="closeModal"></div>
    <div class="account-helper-box">
      <div class="container">
        <div class="title">{{ $t(content.title) }}</div>
        <div class="intro">{{ $t(content.intro) }}</div>
        <ul
          class="desc"
          v-for="(desc, idx) in content.desc"
          :key="`${desc}_${idx}`"
        >
          <li v-if="desc.subDesc">
            {{ $t(desc.str) }}
            <ul
              class="sub-desc"
              v-for="(subDesc, subIdx) in desc.subDesc"
              :key="`${subDesc}_${subIdx}`"
            >
              <li>{{ $t(subDesc) }}</li>
            </ul>
          </li>
          <li v-else>{{ $t(desc.str) }}</li>
        </ul>
        <div class="btns">
          <div class="btn" @click="closeModal">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'PortalHelper',
  props: ['content'],
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  }

}
</script>

<style lang="scss" scoped>
$textIndent4: 4rem;
$gap: 2rem;

* {
  box-sizing: border-box;
}
.account-helper-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.account-helper-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50rem + 2 * $gap); // calc(800px + 2 * $gap);
    padding: 2rem;
    border: 1px solid #4a5c78;
    color: #ffffff;
    background-color: #282942;
    .title {
      margin-bottom: 1rem;
      font-size: 2.25rem; // 36px;
      font-weight: 700;
      line-height: 2.25rem; // 36px;
      // color: #ffffff;
    }
    .intro {
      margin-bottom: 2rem;
      font-size: 1.5rem; // 24px;
      font-weight: 500;
      text-indent: $textIndent4; // 縮排
      color: #ffe99f;
    }

    ul.desc {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-bottom: 2rem;
      font-size: 1.5rem; // 24px;
      font-weight: 500;
      line-height: 2.25rem; // 36px;

      list-style-type: disc; // 實心原點
      list-style-position: inside; // 點在文字<li>內

      ul.sub-desc {
        text-indent: 1.5rem; // 24px; // 縮排
        list-style-type: disc; // 實心原點
        list-style-position: inside; // 點在文字<li>內
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      .btn {
        display: inline-block;
        background: #ffc600;
        border-radius: 0.5rem; //8px;
        font-size: 1.5rem; // 24px;
        font-weight: 300;
        color: #282942;
        padding: 0.625rem 3rem; // 10px 48px;
        cursor: pointer;

        &:hover {
          background: #ffd231;
        }
      }
    }
  }
}

</style>
