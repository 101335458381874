<template>
  <div class="wrap-new-group">
    <div class="new-group">
      <div class="info" v-html="$t('account_required')"></div>
      <div class="item">
        <div class="title">
          <div>{{ $t('am_parent_group') /*所屬群組*/ }}<span>*</span></div>
        </div>
        <div class="content">
          <SelectTree
            class="select-tree"
            v-model="newGroup.parent"
            :options="groupTreeList"
            :dataList="groupInfoList"
            :placeholder="$t('am_parent_group_hint')"
            @input="onSelectParent"
          />
        </div>
      </div>
      <div class="item">
        <div class="title">
          <div>{{ $t('am_group_name') /*群組名稱*/ }}<span>*</span></div>
        </div>
        <div class="content">
          <input
            type="text"
            v-model="newGroup.name"
            name="name"
            :placeholder="$t('am_group_name_hint')"
            @input="onInput"
          />
        </div>
      </div>
      <div v-if="errorName" class="error">{{ $t(errorName) }}</div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="btn cancel" @click="onCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onSave">{{ $t('confirm') }}</div>
    </div>
    <PortalCreateConfirm
      v-if="showPortalCreateConfirm"
      :title="`${$t('am_add_group')}` /*新增群組*/"
      :content="`${$t(
        'am_group_create_confirm'
      )}` /*群組新增後將無法刪除, 您確定要新增嗎？*/"
      @close="onCancel"
      @confirm="handleAddGroup"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { apiCreateGroup } from '@/api/index.js'
import { euEditMode } from '@/config/account.js'
import SelectTree from '@/components/AccountManagement/tools/SelectTree.vue'
import PortalCreateConfirm from '@/components/AccountManagement/portal/PortalCreateConfirm.vue'

export default {
  name: 'GroupEditNew',
  components: { SelectTree, PortalCreateConfirm },
  data() {
    return {
      newGroup: {
        auth: 0,
        id: 0,
        layer: 0,
        maxIn: 0,
        maxOut: 0,
        name: '',
        parent: '0'
      },
      errorName: null,
      showPortalCreateConfirm: false
    }
  },
  computed: {
    ...mapState('userinfo', ['groupInfoList', 'groupTreeList']),
    ...mapState('userinfo/group', ['parentGroupId', 'groupId'])
  },
  mounted() {
    this.setGroupInfo(this.parentGroupId)
  },
  methods: {
    ...mapActions('userinfo', ['getUserTree', 'getGroupInfoList']),
    ...mapMutations('userinfo', ['updateAccountPortal']),
    ...mapMutations('userinfo/group', [
      'updateParentGroupId',
      'updateEditMode',
      'updateGroupId'
    ]),
    setGroupInfo(parentId) {
      const parentGroup = this.groupInfoList.find(
        (group) => String(group.id) === this.parentGroupId
      )
      this.newGroup.parent = parentId
      this.newGroup.layer = parentGroup.layer + 1
    },
    onSelectParent(groupId) {
      this.updateParentGroupId(groupId)
      this.setGroupInfo(this.parentGroupId)
    },
    onInput(event) {
      const { name, value } = event.target
      this.newGroup[name] = value.trim()
    },
    onCancel() {
      if (this.groupId) {
        this.updateEditMode(euEditMode.editView)
        this.updateGroupId(this.groupId)
      } else {
        this.updateEditMode(euEditMode.none)
        this.updateGroupId(null)
      }
      this.showPortalCreateConfirm = false
    },
    async handleAddGroup() {
      const lastGroup = this.groupInfoList[this.groupInfoList.length - 1]
      this.newGroup.id = lastGroup.id + 1
      // console.log(`[handleAddGroup] newGroup:`, JSON.stringify(this.newGroup))
      try {
        const res = await apiCreateGroup(this.newGroup)
        // const res = { status: 200, data: {} } // for Debug

        if (res.status === 200) {
          // 重新取得群組資訊, 並切換到檢視模式
          await this.getUserTree() // get new group
          await this.getGroupInfoList()

          this.updateGroupId(this.newGroup.id)
          this.updateEditMode(euEditMode.editView)
          this.$notify({
            title: this.$t('am_add_group'),
            message: this.$t('am_add_group_pass'),
            type: 'success',
          })
        } else {
          throw res
        }
      } catch (err) {
        // console.error(`[handleAddGroup] apiCreateGroup fail.`, err)
        this.$notify({
          title: this.$t('am_add_group'),
          message: `${this.$t('am_add_group_fail')}: ${
            err.response?.data.message
          }`,
          type: 'error',
        })
      } finally {
        this.showPortalCreateConfirm = false
      }
    },
    async onSave() {
      this.errorName = null
      if (!this.newGroup.name) {
        this.errorName = 'account_required_info'
        return
      }

      this.updateAccountPortal('account-create-confirm')
      this.showPortalCreateConfirm = true
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-new-group {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  font-size: px2rem(24);
  // font-weight: 300;
  line-height: px2rem(36);
  display: flex;
  flex-direction: column;
}
.new-group {
  height: calc(100% - px2rem(120));
  padding: px2rem(8) px2rem(20) px2rem(20) px2rem(20);
  overflow: overlay;

  .info:deep {
    margin-bottom: px2rem(12);
    .required {
      @include required;
    }
  }
}
.item {
  display: flex;
  margin-top: px2rem(12);
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: stretch;
  width: 28%;
  background: #282942;
  border: 1px solid #ffffff80;
  border-right: none;
  border-radius: px2rem(10) 0px 0px px2rem(10);
  color: #ffffff;
  padding: px2rem(6) px2rem(20);
}

.title span {
  color: #ffc600;
}

.content {
  display: flex;
  align-items: center;
  width: 65%;
  min-height: px2rem(50);
  background: #ffffff;
  /* border: 1px solid #FFFFFF80; */
  border-radius: 0px px2rem(10) px2rem(10) 0px;
  color: #ffffff;
  /* padding: px2rem(6) px2rem(40) px2rem(6) px2rem(20); */
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  position: relative;
}

input {
  width: 100%;
  outline: none;
  border: none;
  font-size: px2rem(24);
  // font-weight: 300;
  padding-left: px2rem(20);
  padding-right: px2rem(40);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.error {
  font-size: px2rem(20);
  color: #f94144;
}

/* footer */
.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;
}
.btn {
  border: 1px solid #ffc600;
  border-radius: px2rem(8);
  padding: px2rem(10) px2rem(48);
  margin-left: px2rem(16);
  margin-right: px2rem(16);
  font-size: px2rem(24);
  // font-weight: 300;
  white-space: nowrap;
  cursor: pointer;
}
.cancel {
  color: #ffc600;
}
.cancel:hover {
  background: #ffc60033;
}
.confirm {
  background: #ffc600;
  color: #282942;
}
.confirm:hover {
  background: #ffd133;
}
</style>
