<template>
  <div class="wrap-user-edit">
    <MultiUserEdit v-if="isMulti" :allowRoles="allowRoles" />
    <NewAccount v-else-if="isCreate" :allowRoles="allowRoles" />
    <SingleUserEdit
      v-else-if="userId"
      :allowRoles="allowRoles"
      :showEdit="showEdit"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MultiUserEdit from './UserEdit/MultiUserEdit.vue'
import SingleUserEdit from './UserEdit/SingleUserEdit.vue'
import NewAccount from './UserEdit/NewAccount.vue'
import { console, getNodeFamily , /*, getAncestorList*/ } from '@/utils/lib'
import { DefaultTopScope } from '@/config/permissions.js'

export default {
  name: 'UserEdit',
  components: { MultiUserEdit, SingleUserEdit, NewAccount },
  props: ['showEdit'],
  data() {
    return {
      isDev: console.get(),
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapGetters('userinfo', ['selectedUserInfo']),
    ...mapState(['staff']),
    ...mapState('userinfo', [
      'editMode',
      'userId',
      'groupList',
      'groupTreeList',
      'roleList',
      'userInfoList',
    ]),
    isMulti() {
      return this.editMode === 'multiSelect'
    },
    isCreate() {
      return this.editMode === 'addUser'
    },
    // 可配置角色: 該角色的（可視範圍==0(預設) 或 可視範圍<=(包含)當前操作者）且（所有權限層級<=當前操作者）
    allowRoles() {
      let roles = []
      if (!this.roleList) return []

      // 登入者
      const loggedUser = this.userInfoList.find(
        (user) => user.id == this.getUserInfo.id
      )
      // 登入者角色
      const loggedUserRole = this.roleList.find(
        (role) => loggedUser && role.id == loggedUser.roleId
      )
      // 登入者可用群組(treelist)
      const userTopScopes = getNodeFamily(
        this.groupTreeList,
        loggedUserRole
          ? loggedUserRole.permissionV2.topScope
          : loggedUser.groupId
      )

      roles = this.roleList.filter((role) => {
        // 1. 該角色 可視範圍==0(預設)
        const isDef = role.permissionV2.topScope === DefaultTopScope

        // 2. 該角色 可視範圍<=(包含)當前操作者
        const lowerThenUserScopes = userTopScopes.filter(
          ({ id }) => Number(id) === role.permissionV2.topScope
        )
        const isLowerThenUserScope = lowerThenUserScopes.length > 0
        // console.log(`[UserEdit][allowRoles] ${role.name} userTopScopes:`, userTopScopes.map(({ name }) => name))

        // 3. 該角色 所有權限層級<=當前操作者(如果操作者沒有角色, 則不比對權限)
        const permissionList = Object.keys(role.permissionV2).filter(
          (key) => key !== 'topScope'
        )
        const isLowerThanUserAuth = !loggedUserRole
          ? true
          : permissionList.filter((key) => {
              return role.permissionV2[key] <= loggedUserRole.permissionV2[key]
            }).length === permissionList.length
        // console.log(`[UserEdit][allowRoles] ${role.name} isLowerThanUserAuth:`, isLowerThanUserAuth)

        const ret =
          (isDef && isLowerThanUserAuth) ||
          (!isDef && isLowerThenUserScope && isLowerThanUserAuth)

        // console.log(
        //   `[UserEdit][allowRoles] ${role.name} isDef, isLowerThenUserScope, isLowerThanUserAuth:`,
        //   isDef,
        //   isLowerThenUserScope,
        //   isLowerThanUserAuth,
        //   '=>',
        //   ret
        // )
        // console.log(`[UserEdit][allowRoles] ${role.name} => `, ret)
        return ret
      })
      console.log(
        `[UserEdit][allowRoles]`,
        `${roles.length} / ${this.roleList.length}`,
        roles.map(({ name }) => name)
      )
      return roles
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-user-edit {
  width: 34%;
  height: 100%;
  padding-bottom: calc(100vh * 0.01);
  background: #282942;
  color: #ffffff;
}

// .select-account {
//   height: 46%;
//   border-bottom: 1px solid #4A5C78;
// }

// .title {
//   font-size: px2rem(24);
//   padding: px2rem(20) px2rem(20) px2rem(12);
// }

// .account-list {
//   height: calc(100% - 80px);
//   overflow: overlay;
// }

// .account {
//   display: flex;
//   align-items: center;
//   font-size: px2rem(24);
//   font-weight: 300;
//   line-height: px2rem(35);
//   padding: px2rem(8) 0;
//   margin-right: px2rem(14);
//   cursor: pointer;
//   position: relative;
// }

// .account:hover {
//   background: #6E7D9333;
// }

// .account:active, .account.selected {
//   background: #6E7D9399;
// }

// .account.text-gray {
//   color: #ffffff33;
// }

// .account.text-red {
//   color: #F94144;
// }

// .status {
//   position: absolute;
//   left: px2rem(20);
//   top: px2rem(7);
// }

// .loading {
//   animation: rotation 1s infinite linear;
// }

// @keyframes rotation {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// .success {
//   width: px2rem(20);
//   height: px2rem(20);
// }

// .id, .name {
//   padding-left: px2rem(56);
//   width: 50%;
// }

// .id img {
//   margin-right: px2rem(12);
// }

// .remove {
//   position: absolute;
//   right: px2rem(20);
//   width: px2rem(24);
//   height: px2rem(24);
//   background: #F94144;
//   border-radius: 2px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .remove img {
//   width: px2rem(12);
//   height: px2rem(12);
// }

// .remove:hover {
//   background: #ffffff;
// }

// .remove:hover img {
//   filter: brightness(0) saturate(100%) invert(32%) sepia(54%) saturate(3448%) 
//     hue-rotate(338deg) brightness(102%) contrast(97%);
// }

// .batch-edit {
//   height: 54%;
// }

// .edit-items {
//   height: calc(100% - px2rem(120));
//   padding: px2rem(20) px2rem(20) px2rem(12);
// }

// .header {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: px2rem(12);
// }

// .header .title {
//   font-size: px2rem(24);
//   padding: 0;
// }

// .add-item {
//   width: px2rem(168);
// }

// .tag-select {
//   display: flex;
//   align-items: center;
//   position: relative;
//   font-size: px2rem(24);
//   line-height: px2rem(26);
//   font-weight: 300;
//   cursor: pointer;
// }

// .tag-select .tag {
//   width: 25%;
//   height: px2rem(48);
//   background: #282942;
//   border: 1px solid #FFFFFF80;
//   border-radius: px2rem(10) 0px 0px px2rem(10);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .tag-select .select {
//   width: 58%;
//   height: px2rem(48);
//   color: #282942;
//   background: #FFFFFF;
//   border: 1px solid #ffffff;
//   border-radius: 0px px2rem(10) px2rem(10) 0px;
//   padding-left: px2rem(20);
//   padding-right: px2rem(20);
//   margin-right: px2rem(12);
//   display: flex;
//   align-items: center;
// }

// .tag-select .select:empty::before {
//   content: attr(data-placeholder);
//   color: #9D9D9D;
// }

// .role-info {
//   cursor: pointer;
// }

// .tag-select .remove {
//   right: 0;
// }

// .tag-select-options {
//   position: absolute;
//   top: px2rem(54);
//   width: 83%;
//   height: px2rem(200);
//   overflow: overlay;
//   text-align: center;
//   background: #151B35;
//   border: 1px solid #4A5C78;
//   border-radius: px2rem(8);
//   padding: px2rem(6) 0;
// }

// .tag-select-option {
//   padding: px2rem(6) 0;
//   margin-right: px2rem(12);
//   line-height: px2rem(36);
// }

// .tag-select-option:hover {
//   background: #6E7D9333;
// }

// .tag-select-option.selected {
//   background: #6E7D9399;
// }

// .role-permission {
//   width: 80%;
//   height: px2rem(400);
//   position: absolute;
//   right: 0;
//   bottom: 104%;
//   background: #151B35;
//   color: #ffffff;
//   padding: px2rem(12);
//   border: 1px solid #4A5C78;
//   border-radius: px2rem(8);
//   overflow: overlay;
// }

// .role-permission .auth {
//   display: flex;
//   font-size: px2rem(24);
//   line-height: px2rem(35);
//   padding: px2rem(8) 0;
//   border-bottom: 1px solid #4A5C78;
// }

// .auth-title {
//   width: 60%;
// }

// .auth-value {
//   width: 40%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }


// .footer {
//   height: px2rem(120);
//   display: flex;
//   justify-content: center;
//   align-items: center; 
//   border-top: 1px solid #4A5C78;
// }

// .btn {
//   border: 1px solid #FFC600;
//   border-radius: px2rem(8);
//   padding: px2rem(10) px2rem(48);
//   margin-left: px2rem(16);
//   margin-right: px2rem(16);
//   font-size: px2rem(24);
//   font-weight: 300;
//   white-space: nowrap;
//   cursor: pointer;
// }

// .cancel {
  
//   color: #FFC600;
// }

// .cancel:hover {
//   background: #FFC60033;
// }

// .confirm {
//   background: #FFC600;
//   color: #282942;
// }

// .confirm:hover {
//   background: #FFD133;
// }
</style>