<template>
  <div v-if="false" class="wrap-user">
    <UserTop :showEdit="showEdit" />
    <div class="container-user">
      <GroupTree />
      <UserTable :showEdit="showEdit"/>
      <UserEdit :showEdit="showEdit" />
    </div>
  </div>
  <div v-else class="wrap-user2">
    <GroupTree class="group-tree-block" :showEdit="showEdit"/>
    <div class="container-user">
      <UserTop class="container-user-top" :showEdit="showEdit" />
      <div class="container-user-detail" :showEdit="showEdit">
        <UserTable class="user-table" :showEdit="showEdit" />
        <div class="vertical-line"></div>
        <UserEdit class="user-detail" :showEdit="showEdit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { console } from '@/utils/lib.js'
import { PermissionLevel } from '@/config/permissions.js'
import { all } from '@/config/account'

import GroupTree from './user/GroupTree.vue'
import UserTop from './user/UserTop.vue'
import UserTable from './user/UserTable.vue'
import UserEdit from './user/UserEdit.vue'

export default {
  name: 'User',
  components: {
    GroupTree,
    UserTop,
    UserTable,
    UserEdit
  },
  data() {
    return {
      isDev: console.get(),
    }
  },
  computed: {
    ...mapState(['staff', 'permissionV2']),
    ...mapState('userinfo', ['roleList']),
    showEdit() {
      return this.permissionV2.account >= PermissionLevel.L2
    }
  },
  methods: {
    ...mapMutations('userinfo', [
      // DeviceGroupTree
      'updateGroupId',
      'clearSelectedGroupList',
      // UserTop
      'updateFilterText',
      'cleanFilterRoles',
      'cleanFilterUseStates',
      'updateFilterPublic',

      // Edit
      'updateEditMode',
      // Edit - Single
      'updateUserId',
      'updateSelectedUser',
      // Edit - New
      'updateAddAccountGroupId',
      // Edit - Multiple
      'clearSelectedUserList',
      'updateMultiSelectOnly',
    ]),
    init() {
      // init: 清除 store.userinfo 的資料

      // GroupTree
      this.updateGroupId('')
      this.clearSelectedGroupList()

      // UserTop
      this.updateFilterText('')
      this.cleanFilterRoles()
      this.cleanFilterUseStates()
      this.updateFilterPublic(all)

      // Edit
      this.updateEditMode('editUser')
      // Edit - Single
      this.updateUserId('')
      this.updateSelectedUser('')
      // Edit - New
      this.updateAddAccountGroupId('')
      // Edit - Multiple
      this.clearSelectedUserList()
      this.updateMultiSelectOnly(false)
    }
  },
  created() {
    console.time(`User.created`)
    this.init()
    console.timeEnd(`User.created`)
  },
  mounted() {
    console.time(`User.mounted`)
    console.timeEnd(`User.mounted`)
  },
  // destroyed() {}
}
</script>

<style lang="scss" scoped>
$NavH: 70px;
$groupTreeW: 15%;
$topMaxH: px2rem(160); // fit-content;
$topMinH: px2rem(89);
$tableW: 60%;
$detailW: calc(100% - $tableW);
.wrap-user {
  width: 100%;
  height: 100%;
  .container-user {
    display: flex;
    width: 100%;
    height: calc(100% - $NavH);
  }
}

.wrap-user2 {
  display: flex;
  width: 100%;
  height: 100%;

  .group-tree-block {
    width: $groupTreeW;
    overflow: hidden;
  }

  .container-user {
    display: flex;
    flex-direction: column;
    width: calc(100% - $groupTreeW);
    height: calc(100vh - $NavH);

    .container-user-top {
      min-height: $topMinH;
      max-height: $topMaxH;
    }

    .container-user-detail {
      display: flex;
      width: 100%;
      height: calc(100% - $topMinH + px2rem(8));

      &[showEdit='true'] {
        height: calc(100% - $topMaxH + px2rem(13));
      }

      .user-table {
        width: $tableW;
      }
      .vertical-line {
        height: 100%;
        width: 1px;
        background-color: #4a5c78;
        // border-left: 1px solid #4a5c78;
      }
      .user-detail {
        width: calc($detailW - 1px);
      }
    }
  }
}
</style>
