<template>
  <div v-if="false" class="wrap-device">
    <DeviceTop :showEdit="showEdit" />
    <div class="container-device">
      <DeviceGroupTree />
      <DeviceTable :showCreate="showCreate" />
      <DeviceEdit :showEdit="showEdit" />
    </div>
  </div>
  <div v-else class="wrap-device2">
    <DeviceGroupTree class="group-tree-block" :showCreate="showCreate" />
    <div class="container-device">
      <DeviceTop
        class="container-device-top"
        :showEdit="showEdit"
        :showCreate="showCreate"
      />
      <div class="container-device-detail" :showEdit="showEdit">
        <DeviceTable class="device-table" />
        <div class="vertical-line"></div>
        <DeviceEdit class="device-detail" :showEdit="showEdit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { console } from '@/utils/lib.js'
import { PermissionLevel } from '@/config/permissions.js'
import { all, euEditMode } from '@/config/account.js'

import DeviceGroupTree from './device/GroupTree.vue'
import DeviceTop from './device/Top.vue'
import DeviceTable from './device/Table.vue'
import DeviceEdit from './device/Edit.vue'

export const genDeviceModelOptions = (deviceModels) => {
  const options = []

  for (let i = 0; i < deviceModels.length; i++) {
    const { id, name, icon } = deviceModels[i]
    let classify = null

    // BoviCam Series
    if (id >= 10 && id < 100) {
      classify = 10
    }
    // AiCam Series
    else if (id >= 100 && id < 200) {
      classify = 100
    }
    // Bodyworn Cam Series
    else if (id >= 200 && id < 500) {
      classify = 200
    }
    // FaceArk Series
    else if (id === 500) {
      classify = 500
    }
    // Android APP Series
    else if (id >= 1000 && id < 2000) {
      classify = 1000
    }
    // iOS APP Series
    else if (id <= 2000 && id < 3000) {
      classify = 2000
    }
    // Mac Series
    else if (id === 3000) {
      classify = 3000
    }
    // 其他
    else {
      // classify = 0
    }

    if (classify && !options.find((o) => o?.classify === classify)) {
      options.push({
        classify,
        label: `account_device_series_${classify}`,
        value: classify
      })
    }

    const option = {
      label: name,
      value: id,
      icon: `device_model_${icon}.svg`
    }

    options.push(option)
  }

  return options
}

export default {
  name: 'Device',
  components: {
    DeviceGroupTree,
    DeviceTop,
    DeviceTable,
    DeviceEdit
  },
  data() {
    return {
      isDev: console.get()
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    showEdit() {
      return this.permissionV2.device >= PermissionLevel.L2
    },
    showCreate() {
      return this.permissionV2.device >= PermissionLevel.L4
    }
  },
  methods: {
    ...mapMutations('userinfo/device', [
      // DeviceGroupTree
      'updateGroupId',
      'cleanSelectedGroupList',
      // DeviceTop
      'updateFilterText',
      'cleanFilterDeviceModels',
      'cleanFilterDeviceStates',
      'updateFilterPublic',
      'updateFilterLinkMode',

      // Edit
      'updateEditMode',
      // Edit - Single
      'updateDeviceId',
      'updateSelectedDevice',
      // Edit - New
      'updateAddAccountGroupId',
      // Edit - Multiple
      'clearSelectedDeviceList',
      'updateMultiSelectOnly'
    ]),
    init() {
      // init: 清除 store.userinfo.device 的資料

      // DeviceGroupTree
      this.updateGroupId('')
      this.cleanSelectedGroupList()
      // DeviceTop
      this.updateFilterText('')
      this.cleanFilterDeviceModels()
      this.cleanFilterDeviceStates()
      this.updateFilterPublic(all)
      this.updateFilterLinkMode(all)

      // Edit
      this.updateEditMode(euEditMode.editView)
      // Edit - Single
      this.updateDeviceId('')
      this.updateSelectedDevice('')
      // Edit - New
      this.updateAddAccountGroupId('')
      // Edit - Multiple
      this.clearSelectedDeviceList()
      this.updateMultiSelectOnly(false)
    }
  },
  created() {
    console.time(`Device.created`)
    this.init()
    console.timeEnd(`Device.created`)
  },
  mounted() {
    console.time(`Device.mounted`)
    console.timeEnd(`Device.mounted`)
  },
  // destroyed() {}
}
</script>

<style lang="scss" scoped>
$NavH: 70px;
$groupTreeW: 15%;
$topMaxH: px2rem(160); // fit-content;
$topMinH: px2rem(89);
$tableW: 60%;
$detailW: calc(100% - $tableW);

* {
  box-sizing: border-box;
}
.wrap-device {
  width: 100%;
  height: 100%;

  .container-device {
    display: flex;
    width: 100%;
    height: calc(100% - px2rem(87));
  }
}

.wrap-device2 {
  display: flex;
  width: 100%;
  height: 100%;

  .group-tree-block {
    width: $groupTreeW;
    overflow: hidden;
  }
  .container-device {
    display: flex;
    flex-direction: column;
    width: calc(100% - $groupTreeW);
    height: calc(100vh - $NavH);

    .container-device-top {
      min-height: $topMinH;
      max-height: $topMaxH;
    }

    .container-device-detail {
      display: flex;
      width: 100%;
      height: calc(100% - $topMinH + px2rem(8));

      &[showEdit='true'] {
        height: calc(100% - $topMaxH + px2rem(13));
      }

      .device-table {
        width: $tableW;
      }
      .vertical-line {
        height: 100%;
        width: 1px;
        background-color: #4a5c78;
      }
      .device-detail {
        width: calc($detailW - 1px);
      }
    }
  }
}
</style>
