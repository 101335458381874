<template>
  <div class="wrap-select" tabindex="0" @focusout="handleFocusOut">
    <div class="title">{{ title }}</div>
    <div class="select" :class="{ active: optionShow }" :style="selectStyle">
      <el-tooltip
        v-if="tooltip === true"
        popper-class="el-tooltip"
        effect="dark"
        v-delTabIndex
        :visible-arrow="false"
        :content="selectLabel"
        placement="bottom"
      >
        <div
          class="select-selected"
          :class="{
            'text-overflow': !multiSelect,
            'disabled-selected': isDisabled,
            'has-filter': multiSelect && hasFilter
          }"
          @click.stop="handleClick"
        >
          <span>{{ selectLabel }}</span>
          <img src="@/assets/icons/Drop.svg" alt="" :class="{ dark: this.effect === 'dark', active: optionShow }">
          <!-- <div class="triangle" :class="{ active: optionShow }"></div> -->
        </div>
      </el-tooltip>
      <div
        v-else
        class="select-selected"
        :class="{
          'text-overflow': !multiSelect,
          'disabled-selected': isDisabled,
          'has-filter': multiSelect && hasFilter
        }"
        @click.stop="handleClick"
      >
        <span>{{ selectLabel }}</span>
        <img src="@/assets/icons/Drop.svg" alt="" :class="{ dark: this.effect === 'dark', active: optionShow }">
        <!-- <div class="triangle" :class="{ active: optionShow }"></div> -->
      </div>
      <div
        v-if="optionShow"
        class="select-options"
        :class="{ 'top-options': position === 'top' }"
      >
        <div
          v-for="item in options"
          :key="`${item.value}${item?.classify}`"
          class="select-option"
          :class="{
            selected: isSelected(value, item),
            'multi-select-option': multiSelect,
            'disabled-option': disabledOption(item),
            classify: item.classify
          }"
          @click="optionSelect(item)"
        >
          <div v-if="item.classify" class="classify-text">
            <span>{{ $t(item.label) }}</span>
          </div>
          <template v-else>
            <div
              v-if="multiSelect"
              class="multi-check"
              :class="{ checked: value.includes(item.value) }"
            >
              <img
                v-if="value.includes(item.value)"
                src="@/assets/icons/checkbox-yellow-checked.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="" />
            </div>
            <div v-if="item.icon" class="icon">
              <img :src="getIcon(item.icon)" alt="" />
            </div>
            <span>{{ item.label }}</span>
          </template>
        </div>
      </div>
      <div
        v-if="isForRole && optionShow"
        class="options-detail"
        :class="{ 'top-options': position === 'top' }"
      >
        <div class="options-detail-wrap">
          <div class="title">
            <span>{{ options ? $t(`role_${options[0].param}`) : '' }}</span>
          </div>
          <ul>
            <li
              class="level"
              v-for="(option, idx) in options"
              :key="`${option.label}${idx}`"
            >
              {{ $t(option.desc) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    title: {},
    multiSelect: {
      type: Boolean,
      default: false
    },
    // Used it with multiSelect
    hasFilter: {
      type: Boolean,
      default: false
    },
    classifySelect: {
      type: Boolean,
      default: false
    },
    value: {},
    options: {},
    isDisabled: {},
    effect: {
      default: 'light'
    },
    placeholder: {},
    tooltip: {
      default: false
    },
    isForRole: {
      default: false
    },
    position: {
      default: 'bottom'
    },
  },
  data() {
    return {
      optionShow: false,
    }
  },
  computed: {
    selectLabel() {
      let str = ''
      if (this.multiSelect) {
        let selectLabel = ''
        this.value.forEach((val) => {
          let option = this.options.find((item) => item.value === val && !item.classify)
          selectLabel += selectLabel === '' ? '' : '、'
          selectLabel += option ? option.label : ''
        })
        // 改成不帶入 過濾器標題
        // str = this.value.length > 0 ? `${this.placeholder}：${selectLabel}` : this.placeholder
        str = this.value.length > 0 ? `${selectLabel}` : this.placeholder
      } else if (this.classifySelect) {
        str = this?.options.find(item => !item.classify && item.value === this.value)?.label
      } else
        str = this?.options.find(item => item.value === this.value)?.label
      return str ? str : this.placeholder
    },
    selectStyle() {
      if (this.effect === 'dark') {
        return {
          '--background': this.optionShow ? '#4A5C78' : '#4A5C7880',
          '--color': '#ffffff',
          '--triangle-bg': '#ffffff',
        }
      } else {
        return {
          '--background': '#ffffff',
          '--color': '#191919',
          '--triangle-bg': '#282942',
        }
      }
    },
  },
  methods: {
    getIcon(fn) {
      return require(`@/assets/icons/${fn}`)
    },
    isSelected(value, item) {
      if (!this.classifySelect) {
        return value === item.value
      }
      return item.classify !== undefined ? false : value === item.value
    },
    handleClick() {
      if (!this.isDisabled) {
        this.optionShow = !this.optionShow
      }

      // Use at role management
      this.$emit('showDetail')
    },
    optionSelect(item) {
      // 分類不點擊
      if (item.classify) return

      const { value } = item
      // 多選模式下不關閉下拉選單, 單選才關閉
      let tmpVal = value
      let disabled = false
      if (this.multiSelect) {
        tmpVal = [...this.value]
        const idx = this.value.findIndex((item) => item === value)
        if (idx >= 0) tmpVal.splice(idx, 1)
        else tmpVal.push(value)
      } else if (item?.disabled === true) {
        disabled = true
      } else if (!item || item.disabled !== true) {
        this.optionShow = false
      } else {
        this.optionShow = false
      }
      if (!disabled) this.$emit('input', tmpVal)
    },
    handleFocusOut() {
      this.optionShow = false
    },
    disabledOption(item) {
      return item?.disabled === true ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
$ElementH: px2rem(48);
$FontSize: px2rem(24);
* {
  box-sizing: border-box;
  user-select: none;
}

.wrap-select {
  width: 100%;
  height: $ElementH;
  line-height: $ElementH;
  font-size: $FontSize;
}
.title {
  color: $color_FFF;
  font-size: px2rem(20);
  // font-weight: 300;
  line-height: px2rem(32);
  // margin-bottom: px2rem(4);
  cursor: pointer;
}

.select {
  position: relative;
  width: 100%;
}

.select-selected {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: var(--background);
  color: var(--color);
  border: 2px solid transparent;
  border-radius: px2rem(8);
  // padding-left: px2rem(24);
  // padding-right: px2rem(46);
  padding: 0 px2rem(24);
  // font-weight: 300;
  text-align: center;
  margin-bottom: px2rem(4);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &.has-filter {
    border-color: $color_FFF;
  }

  img {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(0deg);
    transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);
    @include filter_282942;

    &.dark {
      filter: unset;
    }
    &.active {
      transform: rotate(180deg);
    }
  }
  // .triangle {
  //   position: absolute;
  //   content: '';
  //   top: 40%;
  //   right: px2rem(16);
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: px2rem(10) px2rem(7.5) 0 px2rem(7.5);
  //   border-color: var(--triangle-bg) transparent transparent transparent;
  //   transform: rotate(0deg);
  //   transform-origin: 50% 50%;
  //   transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);

  //   &.active {
  //     transform: rotate(180deg);
  //   }
  // }
}

.disabled-selected{
  cursor: text;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-options {
  position: absolute;
  width: 100%;
  height: 60vh;
  left: 0;
  color: $color_FFF;
  background: $color_151B35;
  padding: px2rem(6) px2rem(6) px2rem(12) px2rem(6);
  border: 1px solid $color_4A5C78;
  border-radius: px2rem(8);
  z-index: 2;
  transition: all 375ms ease-in-out;
  min-width: px2rem(200);
  max-height: px2rem(500);
  overflow: overlay;

  &.top-options {
    top: calc(-2.2 * $ElementH);
  }

  .select-option {
    display: flex;
    // justify-content: center;
    align-items: center;

    padding: px2rem(4) 0 px2rem(4) px2rem(20);
    // padding: px2rem(4) 0;
    // font-size: px2rem(24);
    line-height: px2rem(32);
    cursor: pointer;
    user-select: none;

    // background-color: #f00;

    .multi-check {
      // position: relative;
      width: px2rem(18);
      height: px2rem(18);
      margin-right: 1rem;

      img {
        // height: 100%;
        // width: 100%;
        vertical-align: super;
      }
    }

    .icon {
      width: px2rem(20);
      margin-right: 0.5rem;
      display: flex;
      justify-content: center;

      img {
        @include img_icon_24x24;
      }
    }

    span {
      // font-weight: 300;
      word-wrap: break-word;
    }

    &:hover {
      background-color: $color_6E7D93_20;
    }

    &:not(.selected):not(.multi-select-option):not(.disabled):not(.classify):hover {
      background-color: $color_6E7D93_20;
    }

    &.classify {
      padding: 0.5rem 0 px2rem(4) 0.5rem;
      // color: #FFF99F;
      cursor: unset;

      &:hover {
        background-color: unset;
        cursor: unset;
      }
      .classify-text {
        width: 100%;
        border-bottom: 0.125rem solid $color_4A5C78;
        // span {
        //   font-weight: 500;
        // }
      }
    }
  }
}

.selected {
  background: $color_6E7D93;
}

.multi-select-option {
  padding-left: px2rem(20);
  justify-content: start;
}

.selected.multi-select-option {
  background: $color_151B35;
}

.disabled-option {
  // color: #F00;
  color: $color_39425D;
  cursor: not-allowed;
}
.select-option:is(.disabled):hover {
  background: reset;
}

::-webkit-scrollbar {
  width: px2rem(4);
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: $color_9D9D9D;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: px2rem(6);
}

::-webkit-scrollbar-corner {
  background: $color_151B35;
}

.options-detail {
  position: relative;
  left: calc(100% + 1rem);
  top: calc(-3rem * 2);
  width: px2rem(420);
  padding: px2rem(20);
  border: 1px solid $color_4A5C78;
  border-radius: px2rem(8);
  background-color: $color_151B35;
  z-index: 2;

  &.top-options {
    top: unset;
    bottom: calc(3rem * 3);
  }

  .options-detail-wrap {
    // background-color: $color_151B35;

    .title {
      margin-bottom: 1rem;
      font-size: px2rem(24);
      font-weight: 500;
      text-align: left;
      // color: #fff99f;
    }

    .level {
      text-align: left;
      list-style-type: disc; // 實心原點
      list-style-position: inside; // 點在文字<li>內
    }
  }
}

@media screen and (max-width: 1680px) {
  .title {
    font-size: px2rem(20);
  }
  .select-option {
    font-size: px2rem(24);
    // padding-left: px2rem(30);
  }
  .select-option .icon {
    margin-right: px2rem(8);
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: px2rem(18);
  }
  .select-selected {
    font-size: px2rem(18);
  }
  .select-option {
    font-size: px2rem(18);
    // padding-left: px2rem(10);
  }
  .select-option .icon {
    margin-right: px2rem(6);
  }
}
</style>
