<template>
  <div class="wrap-group-tree">
    <div class="search">
      <TextSearchBar
        class="text-search"
        v-model="filterText"
        :placeholder="$t('am_group_tree_search')"
        @clean="clearInput"
        @search="searchTree"
      />
    </div>
    <div class="group-tree">
      <el-tree
        ref="elTree"
        node-key="id"
        default-expand-all
        :data="uiGroupTreeList"
        :props="defaultProps"
        :empty-text="uiGroupTreeList === null ? $t('tree_no_data') : $t('search_hint_nodata')"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        @node-click="onNodeClick"
        @node-expand="onNodeExpand"
        @node-contextmenu="onNodeRightClick"
      >
        <div
          slot-scope="{ node }"
          class="tree-node"
          :class="{ 'self-group': isSelfGroup(node) }"
        >
          <img
            class="checkbox"
            :src="nodeCheckedIconUrl(node)"
            @click.stop="onSelectGroup(node)"
          />
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span class="tree-node-label">
            {{ node.data.name }}
            <span>({{ node.data.kids }})</span>
          </span>
        </div>
      </el-tree>
      <div
        v-if="openContextMenu"
        ref="contextMenu"
        class="node-contextmenu"
        v-click-outside="onCloseContentMenu"
      >
        <div class="close-contextmenu">
          <div @click="onCloseContentMenu">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="create-account-content" @click="onCreateAccount">
          <i class="fas fa-plus"></i>
          <span>{{ $t('account_add') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import { getNodeFamily, getNodeKids, filterTreeByName } from '@/utils/lib.js'
import { euAccountKind } from '@/utils/global.js'
import TextSearchBar from '@/components/tools/TextSearchBar.vue'

export default {
  name: 'GroupTree',
  components: { TextSearchBar },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      clickInterval: 500,
      clickCnt: 1,
      clickTimer: null,
      openContextMenu: false
    }
  },
  props: {
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState('userinfo', [
      // 'editMode',
      'groupTreeList',
      'selectedGroupList',
      'groupId',
      'userInfoList'
    ]),
    // isMultiMode() {
    //   return this.editMode === 'multiSelect'
    // },
    uiGroupTreeList() {
      let newGroupTreeList = JSON.parse(JSON.stringify(this.groupTreeList))

      this.genGroupTreeList(newGroupTreeList)
      if (this.filterText) {
        newGroupTreeList = filterTreeByName(newGroupTreeList, this.filterText)
      }

      return newGroupTreeList || null
    },
  },
  methods: {
    ...mapMutations('userinfo', [
      'updateGroupId',
      'updateEditMode',
      'updateAddAccountGroupId',
      'updateSelectedGroupList'
    ]),
    genGroupTreeList(treeList) {
      return treeList.map((group) => {
        const userList = this.userInfoList.filter((user) => {
          const isUser = user.kind !== euAccountKind.device
          return user.groupId === group.id && isUser
        })
        group['kids'] = userList.length
        if (group.children.length > 0) {
          return this.genGroupTreeList(group.children)
        }
        return group
      })
    },
    searchTree(val) {
      this.filterText = val
      this.$refs.elTree.filter(this.filterText)
    },
    nodeCheckedIconUrl(node) {
      const { data } = node
      const checked = this.selectedGroupList.find((grpId) => grpId === data.id)

      if (node.isLeaf) {
        return checked
          ? require(`@/assets/icons/checkbox-yellow-checked.svg`)
          : require('@/assets/icons/uncheck.svg')
      } else {
        const groupKids = getNodeKids(this.uiGroupTreeList, data.id)
        const checkList = groupKids.filter((group) => {
          const checked = this.selectedGroupList.find(
            (grpId) => grpId === group.id
          )
          return checked ? true : false
        })

        if (checked) {
          return require('@/assets/icons/checkbox-yellow-checked.svg')
        } else {
          if (checkList.length <= 0) {
            return require('@/assets/icons/uncheck.svg')
          } /* else if (checkList.length === groupKids.length) {
            return require('@/assets/icons/checkbox-yellow-checked.svg')
          }*/ else if (checkList.length > 0) {
            return require('@/assets/icons/tree-indeterminate-yellow.svg')
          }
        }
      }
    },
    clearInput() {
      this.filterText = ''
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    },
    isSelfGroup(node) {
      const { data } = node

      return data.id === this.getUserInfo?.groupId
    },
    cleanClickTimer() {
      if (this.clickTimer) {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
        this.clickCnt = 1
      }
    },
    singleClick(data) {
      // 重複點選已經選中的節點，則取消選中
      let setValue = data.id === this.groupId ? false : true
      if (setValue) {
        this.updateGroupId(data.id)
        this.$refs.elTree.setCurrentKey(data.id)
      } else {
        this.updateGroupId(null)
        this.$refs.elTree.setCurrentKey(null)
      }
    },
    onNodeClick(data, node) {
      // 關閉 contextMenu
      this.onCloseContentMenu()
      // ---
      if (node.isLeaf) {
        this.singleClick(data)
      }
      // node control
      else {
        // Trigger click
        if (this.clickCnt < 2) {
          this.singleClick(data)
          this.clickCnt++
          if (!this.clickTimer) {
            this.clickTimer = setTimeout(() => {
              this.cleanClickTimer()
            }, this.clickInterval)
          }
        }
        // Trigger double click
        else {
          this.cleanClickTimer()

          if (node.expanded) {
            node.expanded = false
          } else {
            if (data.children) {
              node.expanded = true
            }
          }
          this.$refs.elTree.handleNodeExpand(node)
        }
      }
    },
    onNodeExpand(/*data*/) {
      // for debug
      // console.log(`[onNodeExpand] data:`, data)
    },
    onNodeRightClick(e, data) {
      // 有編輯權限才可以 新增使用者帳號
      if (!this.showEdit) return

      // 點不同的 group 才需要執行 左鍵點擊
      if (data.id !== this.groupId) {
        this.singleClick(data)
      }
      this.onCloseContentMenu()
      this.onOpenContentMenu()

      let left = e.pageX
      let top = e.pageY + 5
      this.$nextTick(() => {
        this.$refs.contextMenu.style.left = left + `px`
        this.$refs.contextMenu.style.top = top + `px`
      })
    },
    onOpenContentMenu() {
      if (!this.openContextMenu) {
        this.openContextMenu = true
      }
    },
    onCloseContentMenu() {
      if (this.openContextMenu) {
        this.openContextMenu = false
      }
    },
    onSelectGroup(node) {
      const { data } = node
      if (node.isLeaf) {
        this.updateSelectedGroupList(data.id)
      } else {
        // console.log(`[onSelectGroup] 全選`)
        const isGroupExist = this.selectedGroupList.find(
          (grpId) => grpId === data.id
        )
        const groupFamily = getNodeFamily(this.uiGroupTreeList, data.id)

        groupFamily.forEach((group) => {
          const exist = this.selectedGroupList.find(
            (grpId) => grpId === group.id
          )

          if ((isGroupExist && exist) || (!isGroupExist && !exist)) {
            this.updateSelectedGroupList(group.id)
          }
        })
      }
    },
    // onSelectAllGroup(data, node) {
    //   if (!node.isLeaf) {
    //     // console.log(`[onSelectAllGroup] 全選`)
    //     const isGroupExist = this.selectedGroupList.find(
    //       (grpId) => grpId === data.id
    //     )
    //     const groupFamily = getNodeFamily(this.uiGroupTreeList, data.id)

    //     groupFamily.forEach((group) => {
    //       const exist = this.selectedGroupList.find(
    //         (grpId) => grpId === group.id
    //       )

    //       if ((isGroupExist && exist) || (!isGroupExist && !exist)) {
    //         this.updateSelectedGroupList(group.id)
    //       }
    //     })
    //   }
    // },
    onCreateAccount() {
      this.updateAddAccountGroupId(this.groupId)
      this.updateEditMode('addUser')
      this.onCloseContentMenu()
    }
  },
  watch: {
    // groupId() {
    //   if (this.groupId && this.groupTreeList.length > 0) {
    //     this.$nextTick(() => {
    //       this.$refs.elTree.setCurrentKey(this.groupId)
    //     })
    //   }
    // },
  },
  beforeDestroy() {
    this.cleanClickTimer()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}
.wrap-group-tree {
  width: 100%;
  height: 100%;
  border-right: 1px solid #4a5c78;
  background: #151b35;

  .search:deep {
    display: flex;
    align-items: center;
    width: 100%;
    padding: px2rem(8);

    .text-search {
      color: #fff;
      border-radius: px2rem(4);
      background-color: #4a5c7866; // 40%

      input {
        border-color: transparent;
        width: 100%;
        // height: 1.7rem;
        // padding: px2rem(4);
        font-size: px2rem(20);
        color: #fff;
        background-color: #4A5C7800; // 0%
      }

      .control-wrap {
        .clean {
          &:hover {
            img {
              @include filter_FFF;
            }
          }
        }
        .search {
          height: px2rem(22);
          width: px2rem(22);
          img {
            height: 100%;
            width: 100%;
          }
          &:hover {
            img {
              @include filter_FFF;
            }
          }
        }
      }
    }
  }

  .group-tree {
    padding-right: px2rem(8);
    padding-bottom: px2rem(8);
    height: calc(100% - px2rem(60));
    overflow: overlay;

    .tree-node {
      position: relative;
      display: flex;
      align-items: center;

      img {
        width: px2rem(24);
        height: px2rem(24);
        vertical-align: text-bottom;

        &.checkbox {
          $CheckboxW: px2rem(20);

          width: $CheckboxW;
          height: $CheckboxW;
          margin-right: px2rem(8);
        }
      }
      .tree-node-label {
        margin-left: px2rem(8);
        font-size: px2rem(20);
        font-weight: 500;
        line-height: px2rem(30);
        color: #ffc600;

        span {
          padding-left: px2rem(2);
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }

    .node-contextmenu {
      position: fixed;
      z-index: 4;
      display: flex;
      flex-direction: column;
      // width: px2rem(160);
      padding: 0.5rem 0 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid #4A5C78;
      font-size: px2rem(16);
      color: #ffffff;
      background-color: #39425D;

      .close-contextmenu {
        display: flex;
        justify-content: flex-end;
        padding-right: 0.5rem;
        margin-bottom: 0.25rem;

        div {
          cursor: pointer;
        }
      }
      .create-account-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.25rem px2rem(16);
        cursor: pointer;

        &:hover {
          background-color: #4a5c78;
        }

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

::v-deep .el-tree {
  display: inline-block;
  min-width: 100%;
  background: #282942; // #151B35;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  align-items: center;
  height: 100%;
  padding: px2rem(6) 0;
}

::v-deep .el-tree-node .el-tree-node__children {
  overflow: visible;
}

::v-deep .el-tree-node__label {
  font-size: px2rem(20);
  font-weight: 300;
  line-height: 1.5;
  color: #FFC600;
}

// 設定點擊才不會閃白底
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
  &:has(.tree-node.self-group) {
    background-color: $SelfGroupBGC;
  }
}

::v-deep .el-tree-node__content .is-leaf {
  color: transparent;
  cursor: default;
}

::v-deep .el-tree-node__content:hover .el-tree-node__expand-icon .is-leaf {
  cursor: default;
}

::v-deep .el-tree-node__expand-icon {
  color: #f3f4f4;
  font-size: px2rem(20);
}

::v-deep .el-tree-node__content:hover {
  background: #6E7D9333 !important;
  &:has(.self-group) {
    background-color: $SelfGroupBGC !important;
  }
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background-color: #6E7D9399 !important;
}

::v-deep .el-tree > .el-tree-node.is-expanded > .el-tree-node__content {
  background: #151b35;
}

::v-deep .el-tree-node > .el-tree-node__content {
  min-height: px2rem(32);
}

::v-deep .el-tree-node__children > .el-tree-node__content {
  min-height: px2rem(32);
}
</style>
