<template>
  <div class="wrap-role">
    <div class="container-role" ref="containerRole">
      <table>
        <thead>
          <tr>
            <th></th>
            <th
              v-for="(item, index) in localRoleList"
              :key="item.id"
              :class="{
                disabled: editRoleIndex >= 0 && index !== editRoleIndex
              }"
            >
              <div
                class="th-div"
                :class="{ 'self-role': item.isLoggedUserRole }"
              >
                <div v-if="index === editRoleIndex" class="edit-name">
                  <input type="text" v-model="item.name" />
                </div>
                <div v-else class="name">
                  {{ item.name }}
                </div>
                <div
                  v-if="showEdit && index !== editRoleIndex"
                  class="th-menu"
                  :class="{
                    'th-menu-disabled': !item.canEdit
                  }"
                  @click="toggleMenu(item, index)"
                >
                  <img src="@/assets/icons/menu.svg" alt="" />
                  <ul v-if="index === showMenuIndex" class="th-menu-dropdown">
                    <li @click="startEditRole(index)">
                      <img src="@/assets/icons/Role-Pen.svg" alt="" />
                      {{ $t('account_edit_role') }}
                    </li>
                    <li @click="startCopyRole(index)">
                      <img src="@/assets/icons/Copy.svg" alt="" />
                      {{ $t('account_copy_role') }}
                    </li>
                    <li @click="startDeleteRole(item, index)">
                      <img src="@/assets/icons/TrashCan.svg" alt="" />
                      {{ $t('account_delete_role') }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <th v-if="showEdit">
              <div
                v-if="editRoleIndex < 0"
                class="th-div add-role"
                @click="startCreateRole"
              >
                <img src="@/assets/icons/plus-role.svg" alt="" />
                {{ $t('account_add_role') /*新增角色*/ }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, pIdx) in permissionList"
            :key="item.param"
            @mouseover="paramMouseOver(item.param)"
            @mouseleave="paramMouseLeave"
          >
            <!-- Permission -->
            <td v-if="!item.param"></td>
            <td v-else-if="!item.ui">
              <div
                class="td-div param param-group"
                @click="onOpenPeremissionGroup(item.param)"
              >
                <span
                  class="triangle-open"
                  v-show="permissionGroup[item.param]"
                >
                  <i class="fas fa-caret-down"></i>
                </span>
                <span
                  class="triangle-close"
                  v-show="!permissionGroup[item.param]"
                >
                  <i class="fas fa-caret-right"></i>
                </span>
                <span>{{ $t(`role_${item.param}`) }}</span>
              </div>
            </td>
            <td
              v-else-if="
                item.ui && (!item.group || permissionGroup[item.group])
              "
            >
              <div
                class="td-div param"
                :class="{ 'param-hover': item.param === hoverParam }"
              >
                <span>{{ $t(`role_${item.param}`) }}</span>
                <img
                  class="info-icon"
                  src="@/assets/icons/info.svg"
                  alt=""
                  @mouseover="tooltipMouseEnter"
                  @mouseleave="tooltipMouseOut"
                />
                <!-- Permission:tooltip -->
                <div
                  v-if="item.param === hoverParam && showTooltip"
                  class="param-tooltip"
                >
                  <div class="title">{{ $t(item.tooltip.title) }}</div>
                  <div
                    class="desc"
                    v-for="(desc, idx) in item.tooltip.desc"
                    :key="`${desc}_${idx}`"
                  >
                    {{ $t(desc) }}
                  </div>
                  <ul class="level">
                    <li
                      v-for="(level, idx) in item.tooltip.level"
                      :key="`${level}_${idx}`"
                    >
                      {{ $t(level) }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>

            <!-- Roles -->
            <td
              v-for="(role, index) in localRoleList"
              :key="role.id"
              :class="{
                disabled: editRoleIndex >= 0 && index !== editRoleIndex,
                'empty-td': !item.param
              }"
            >
              <div v-if="!item.param" class="td-div empty"><!--空--></div>
              <!-- Edit Mode -->
              <div v-else-if="index === editRoleIndex" class="td-div">
                <Checkbox
                  v-if="item.ui === 'checkbox'"
                  v-model="role.permissionV2[item.param]"
                />
                <SelectTree
                  v-else-if="item.param === 'topScope'"
                  class="drop-down"
                  placement="bottom"
                  optionsWidth="138%"
                  v-model="role.permissionV2[item.param]"
                  :isForRole="true"
                  :options="groupTreeList"
                  :optionDetail="item"
                  :dataList="groupInfoList"
                  :placeholder="$t('am_search_group')"
                />
                <template v-else-if="item.ui === 'dropDown'">
                  <Select
                    v-if="role.permissionV2[item.param] == undefined"
                    class="drop-down not-available"
                    :value="notAvailable.value"
                    :isDisabled="true"
                    :options="item.options"
                    :isForRole="true"
                    :position="
                      pIdx >= permissionList.length - 1 ? 'top' : 'bottom'
                    "
                  />
                  <Select
                    v-else
                    class="drop-down"
                    v-model="role.permissionV2[item.param]"
                    :options="item.options"
                    :isForRole="true"
                    :position="
                      pIdx >= permissionList.length - 1 ? 'top' : 'bottom'
                    "
                  />
                </template>

              </div>
              <!-- View Mode -->
              <div
                v-else
                class="td-div"
                :class="{ 'param-hover': item.param === hoverParam && item.ui }"
              >
                <div v-if="!item.ui"></div>
                <div
                  v-else-if="role.permissionV2[item.param] === undefined"
                  class="not-available"
                >
                  {{ notAvailable.str }}
                </div>
                <div v-else-if="item.ui === 'checkbox'">
                  <img
                    v-if="role.permissionV2[item.param] === 1"
                    :class="{ gray: editRoleIndex >= 0 }"
                    src="@/assets/icons/checked.svg"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/icons/uncheck.svg"
                    :class="{ gray: editRoleIndex >= 0 }"
                    alt=""
                  />
                </div>
                <div v-else-if="item.param === 'topScope'" class="view-group">
                  <img src="@/assets/icons/group-icon.svg" alt="" />
                  <span
                    :class="{
                      'default-scope': role.permissionV2[item.param] === 0
                    }"
                  >
                    {{
                      getGroupName(role.permissionV2[item.param], groupInfoList)
                    }}
                  </span>
                </div>
                <div
                  v-else-if="
                    !item.group || (item.group && permissionGroup[item.group])
                  "
                >
                  {{
                    getValueLabel(role.permissionV2[item.param], item.options)
                  }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <!--空-->
            <td class="empty-td">
              <div class="td-div param empty"><!-- 空 --></div>
            </td>
            <td
              v-for="(role, index) in localRoleList"
              class="empty-td"
              :key="`${role.id}${index}`"
            >
              <div
                class="td-div empty"
                :class="{
                  'empty-disabled':
                    editRoleIndex >= 0 && index !== editRoleIndex
                }"
              ></div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="editRoleIndex >= 0">
          <tr>
            <td></td>
            <td v-for="(role, index) in localRoleList" :key="role.id">
              <div v-if="index === editRoleIndex" class="td-div">
                <div class="btn cancel" @click="handleCancel">
                  {{ $t('cancel') }}
                </div>
                <div class="btn confirm" @click="handleConfirm(role, index)">
                  {{ $t('confirm') }}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="hori-line"></div>
    <div class="vert-line"></div>
    <PortalMessage
      v-if="showPortalMessage"
      :title="portal.title"
      :content="portal.content"
      :action="portal.action"
      @close="closePortalMessage"
      @confirm="handlePortalConfirm"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { apiGetGroup, editRole, createRole, deleteRole } from '@/api/index.js'
import {
  permissionList,
  PermissionLevel,
  permissionGroup
} from '@/config/permissions.js'
import Select from './tools/Select.vue'
import Checkbox from './tools/Checkbox.vue'
import SelectTree from './tools/SelectTree.vue'
import PortalMessage from './portal/PortalMessage.vue'
import { console, getNodeFamily } from '@/utils/lib.js'

const DefaultTopScope = 0

export default {
  name: 'Role',
  components: { Select, Checkbox, SelectTree, PortalMessage },
  data() {
    return {
      permissionGroup,

      notExistTopScopeList: null,
      loggedUser: null,
      localRoleList: [],
      origRoleList: [],
      showMenuIndex: -1,
      editRoleIndex: -1,
      hoverParam: '',
      showTooltip: false,
      action: '', // edit / create / copy / delete
      showPortalMessage: false,
      portal: {
        title: '',
        content: '',
        action: '',
        actionIndex: -1
      },

      showOptionsDetail: null,
    }
  },
  async created() {
    await this.getNotExistTopScopeList()
    this.getRoles()
    this.updateAccountPortal('account-message')
  },
  computed: {
    ...mapState(['staff', 'permissionV2']), // 登入者的權限
    ...mapState('userinfo', [
      'userInfoList',
      'groupTreeList',
      'groupInfoList',
      'roleList'
    ]),
    // ...mapGetters(['getUserInfo']),
    ...mapGetters('userinfo', ['loggedUserInfo']),
    notAvailable() {
      return {
        str: Object.keys(PermissionLevel)[PermissionLevel.L0],
        value: PermissionLevel.L0
      }
    },
    // authItems() {
    //   if (this.localRoleList.length > 0) {
    //     const role = this.localRoleList[0]
    //     return Object.keys(role.permissionV2)
    //   }
    //   return []
    // },
    showEdit() {
      return this.permissionV2.roleManagement >= PermissionLevel.L2
    },
    permissionList() {
      // 1. 所有權限預設為最低層級=0
      //   每一權限可配置層級<=當前操作者 // 新增|編輯|拷貝 時, 才需要做該檢查
      // console.log(`[permissionList] permissionList: `, JSON.stringify(permissionList))
      let newPermissionList = JSON.parse(JSON.stringify(permissionList))

      try {
        if (
          this.action === 'create' ||
          this.action === 'edit' ||
          this.action === 'copy'
        ) {
          const thisRole = this.localRoleList[this.editRoleIndex]
          const thisRolePermission = thisRole.permissionV2

          newPermissionList = newPermissionList.map((permission) => {
            if (!permission.options.length) {
              return permission
            }

            const options = permission.options.map((option /*, oIdx*/) => {
              const sPrem = this.staff //  staff登入者
              const rPerm = thisRolePermission[permission.param] // 角色權限
              const uPerm = this.permissionV2[permission.param] // 登入者權限
              const oPrem = option.value // 預設權限
              const enable = sPrem || (rPerm <= uPerm && oPrem <= uPerm)

              // console.log(
              //   `[permissionList] ${permission.param} ${option.label}: rPerm, uPerm, oPrem, enable =>`,
              //   rPerm,
              //   uPerm,
              //   oPrem,
              //   enable
              // )

              // 每一權限可配置層級<=當前操作者
              option.disabled = !enable

              return option
            })

            permission.options = [...options]
            return permission
          })
        }
      } catch (err) {
        console.error(`[permissionList] err:`, err)
      }

      newPermissionList = newPermissionList.filter((item) => {
        if (item?.group) {
          return this.permissionGroup[item.group]
        }
        return true
      })

      // console.log(`[permissionList] newPermissionList:`, JSON.stringify(newPermissionList))
      return newPermissionList
    }
  },
  watch: {
    // getUserInfo() {
    //   this.getRoles()
    // },
    loggedUserInfo() {
      this.getRoles()
    },
    groupTreeList() {
      this.getRoles()
    },
    roleList() {
      // 無法管理的話, 全部都顯示，但是不能編輯。(不然也無法判斷"重名")
      this.getRoles()
    },
  },
  methods: {
    ...mapActions('userinfo', ['getRoleList', 'getUserInfoList']),
    ...mapMutations('userinfo', ['updateAccountPortal']),
    async getNotExistTopScopeList() {
      if (!this.notExistTopScopeList) {
        this.notExistTopScopeList = []
        const groupIdList = this.groupInfoList.map((group) => {
          return group.id
        })
        const notExistTopScopeList = this.roleList.filter((role) => {
          const topScope = role.permissionV2.topScope.toString()
          return !groupIdList.includes(topScope) && topScope !== '0'
        })

        if (notExistTopScopeList.length >= 0) {
          notExistTopScopeList.forEach(async (role) => {
            const res = await apiGetGroup(role.permissionV2.topScope)
            const group = res.data
            this.notExistTopScopeList.push(group)
          })
        }
      }
    },
    getRoles() {
      // 重構UI需要的 roleList
      const loggedUserRole = this.roleList.find((role) => {
        return role.id === this.loggedUserInfo?.roleId
      })

      // 增加 loggedUserRole, isLoggedUserRole, canEdit 給 UI 判斷
      let localRoleList = this.roleList.map((role) => {
        const isLoggedUserRole = role.id === this.loggedUserInfo?.roleId
        const canEdit = this.checkRole(
          { ...role, ...{ loggedUserRole, isLoggedUserRole } },
          'getRoles'
        )

        return { ...role, ...{ loggedUserRole, isLoggedUserRole, canEdit } }
      })

      // Note: 要維持所有角色都顯示, 不然無法在完整的角色列表中判斷"重名"; 但要處理可不可以編輯
      this.localRoleList = JSON.parse(JSON.stringify(localRoleList))
      this.origRoleList = JSON.parse(JSON.stringify(localRoleList))
    },
    getGroupName(value, groupList) {
      if (value == 0) return this.$t('role_topScop_default')
      let group = groupList.find((item) => item.id == value)

      if (!group) {
        group = this.notExistTopScopeList.find((item) => item.id == value)
      }

      return group ? group.name : ''
    },
    getValueLabel(value, options) {
      let option = options.find((item) => item.value === value)
      return option ? option.label : value
    },
    toggleMenu(item, index) {
      if (!item.canEdit) {
        return
      }
      if (this.editRoleIndex >= 0 && index !== this.editRoleIndex) return
      if (index === this.showMenuIndex) {
        this.showMenuIndex = -1
        return
      }
      this.showMenuIndex = index
    },
    startCreateRole() {
      this.openAllPermissionGroup()
      // ---
      let newRole = { name: this.$t('role_new_name') }
      let permissionV2 = {}
      this.permissionList.forEach((item) => {
        permissionV2[item.param] = 0
      })
      newRole.permissionV2 = permissionV2
      this.localRoleList.push(newRole)
      this.action = 'create'
      this.editRoleIndex = this.localRoleList.length - 1
      this.$nextTick(() => {
        this.$refs.containerRole.scrollLeft += 300
      })
    },
    startEditRole(index) {
      this.openAllPermissionGroup()
      // ---
      this.editRoleIndex = index
      this.showMenuIndex = -1
      this.action = 'edit'
    },
    startCopyRole(index) {
      let copyRole = JSON.parse(JSON.stringify(this.localRoleList[index]))
      copyRole.name = copyRole.name + '(1)'
      delete copyRole.id // 為了避免重複的id
      this.localRoleList.push(copyRole)
      this.editRoleIndex = this.localRoleList.length - 1
      this.showMenuIndex = -1
      this.action = 'copy'
      this.$nextTick(() => {
        this.$refs.containerRole.scrollLeft += 300 * (this.editRoleIndex - index)
      })
    },
    startDeleteRole(role, index) {
      // 檢查是否有使用者在使用此角色
      const useRoleUsers = this.userInfoList.filter(
        (user) => user.roleId === role.id
      )
      if (useRoleUsers.length > 0) {
        this.portal.title = this.$t('role_message_error_delete_title')
        this.portal.content = this.$t('role_message_error_delete_transfer', {
          name: role.name
        })
        this.portal.action = 'confirm'
        this.portal.actionIndex = -1
        this.showPortalMessage = true
        return
      }

      this.portal.title = this.$t('account_delete_role')
      this.portal.content = this.$t(`role_notify_delete_confirm`, {
        name: role.name
      })
      this.portal.action = 'delete'
      this.portal.actionIndex = index
      this.showPortalMessage = true
    },
    handleCancel() {
      switch (this.action) {
        case 'edit':
        case 'create':
        case 'copy':
          this.localRoleList = JSON.parse(JSON.stringify(this.origRoleList))
          break
        case 'delete':
          break
      }
      this.showMenuIndex = -1
      this.editRoleIndex = -1
      this.action = ''
    },
    handleConfirm(role, index) {
      switch (this.action) {
        case 'edit':
          this.editRole(role, index)
          break
        case 'create':
        case 'copy':
          this.createRole()
          break
        case 'delete':
          break
      }
    },
    hasRepeatName(name, index) {
      let repeat = 0
      this.origRoleList.forEach((item, i) => {
        if (i !== index && item.name.toLowerCase() === name.toLowerCase()) {
          repeat++
        }
      })

      return repeat
    },
    getEditData(role, index) {
      let editData = { id: role.id, permissionV2: {} }
      let count = 0
      if (role.name !== this.origRoleList[index].name) {
        editData.name = role.name
        count++
      } 
      Object.keys(role.permissionV2).forEach((key) => {
        if (
          role.permissionV2[key] !== this.origRoleList[index].permissionV2[key]
        ) {
          editData.permissionV2[key] = role.permissionV2[key]
          count++
        }
      })
      return {
        count: count,
        editData: editData
      }
    },
    async editRole(role, index) {
      // 檢查是否修改name, 以及是否已有重複的name
      if (this.hasRepeatName(role.name, index) > 0) {
        this.portal.title = this.$t('role_message_error_name_title')
        this.portal.content = this.$t('role_message_error_name_content')
        this.portal.action = 'confirm'
        this.portal.actionIndex = -1
        this.showPortalMessage = true
        return
      }
      const { count, editData } = this.getEditData(role, index)

      if (count > 0) {
        const res = await editRole(editData)
        if (res.status === 200) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            title: this.$t('role_notify_edit_success'),
            message: this.$t('role_notify_edit_info', { name: role.name }),
            type: 'success',
          })
        }
        await this.getRoleList()
      }
      this.editRoleIndex = -1
      this.action = ''
    },
    async createRole() {
      const newRole = this.localRoleList[this.editRoleIndex]
      // 檢查是否已有重複的name
      if (this.hasRepeatName(newRole.name, this.editRoleIndex) > 0) { 
        this.portal.title = this.$t('role_message_error_name_title')
        this.portal.content = this.$t('role_message_error_name_content')
        this.portal.action = 'confirm'
        this.portal.actionIndex = -1
        this.showPortalMessage = true
        return
      }
      try {
        const res = await createRole(newRole)
        if (res.status === 200) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            title: this.$t('role_notify_add_success'),
            message: this.$t('role_notify_add_info', { name: newRole.name }), // `新增角色：${newRole.name}`,
            type: 'success',
          })
        }
      } catch (err) {
        console.error('createRole ==> err = ', err)
        this.$notify({
          title: this.$t('role_notify_add_fail'),
          message: err,
          type: 'error',
        })
      }

      await this.getRoleList()
      this.editRoleIndex = -1
      this.portal.action = ''
    },
    paramMouseOver(param) {
      this.hoverParam = param
    },
    paramMouseLeave() {
      this.hoverParam = ''
    },
    tooltipMouseEnter(/*event*/) {
      this.showTooltip = true
      this.$nextTick(() => {
        const paramRect = document
          .querySelector('.param-hover')
          .getBoundingClientRect()
        const { scrollTop } = this.$refs.containerRole
        const tooltip = document.querySelector('.param-tooltip')
        const tooltipRect = tooltip.getBoundingClientRect()

        let newTop = 0
        if (scrollTop <= 0) {
          newTop = 0
        } else if (tooltipRect.bottom >= window.innerHeight) {
          newTop = (tooltipRect.height - paramRect.height) * -1
        } /* else {
          newTop = (tooltipRect.height / 2) * -1
        }*/
        tooltip.setAttribute('style', `top: ${newTop}px`)
      })
    },
    tooltipMouseOut(/*event*/) {
      this.showTooltip = false
      // this.$nextTick(() => {
      //   this.$refs.containerRole.scrollTop -= 300
      // })
    },
    closePortalMessage() {
      this.showPortalMessage = false
    },
    async handlePortalConfirm() {
      if (this.portal.action === 'delete' && this.portal.actionIndex >= 0) {
        // delete role
        try {
          let deleteId = this.localRoleList[this.portal.actionIndex].id
          const res = await deleteRole(deleteId)
          if (res.status === 200) {
            this.$notify({
              dangerouslyUseHTMLString: true,
              title: this.$t('role_notify_delete_success_title'),
              message: this.$t('role_notify_delete_success_msg', {
                name: this.localRoleList[this.portal.actionIndex].name
              }),
              type: 'success',
            })
          }
          // console.log('res = ', res)
        } catch (err) {
          this.$notify({
            title: this.$t('role_message_error_delete_title'),
            message: err,
            type: 'error',
          })
        }
        this.showPortalMessage = false
        // this.portal.title = this.$t('role_message_error_delete_title')
        // this.portal.content = this.$t('role_message_error_delete_prefix') + this.localRoleList[this.portal.actionIndex].name + this.$t('role_message_error_delete_suffix')
        // this.portal.action = 'confirm'
        // this.portal.actionIndex = -1
        // this.showPortalMessage = true
        await this.getRoleList()
      }
    },
    checkRole(role /*, caller*/) {
      // 目的： 登入者角色的權限 VS. 可檢視角色的權限
      // true: 可以編輯, false: 不可編輯
      const { loggedUserInfo, groupTreeList } = this
      const loggedUserGroupIdList = getNodeFamily(
        groupTreeList,
        loggedUserInfo?.groupId
      ).map((grp) => Number(grp.id)) // 登入者群組
      const { loggedUserRole, isLoggedUserRole } = role // 登入者角色
      const loggedUserRoleGroupIdList = getNodeFamily(
        groupTreeList,
        loggedUserRole?.permissionV2?.topScope
      ).map((grp) => Number(grp.id)) // 登入者角色可視範圍(群組)

      // console.log(`[checkRole] #0 登入者角色:${loggedUserRole?.name} VS. 現在角色：${role.name}`, isLoggedUserRole)
      // 0. 登入者有編輯角色的權限
      // if (!this.showEdit) {
      //   console.log(`[checkRole] #0 登入者角色頁面權限:L${this.permissionV2.roleManagement} (無權編輯)`)
      //   return false
      // }

      // 1. 登入者角色=該角色 => 不能編輯自己的角色
      if (isLoggedUserRole) {
        console.log(`[checkRole] #1 ${role.name} => 同登入者角色(${loggedUserRole?.name})`)
        return false
      }

      // 2. 角色（各）權限 是否比 登入者角色（各）權限 高 => 登入者 不能編輯 有權限比自己高的角色
      const highterPermission = Object.keys(role.permissionV2)
        .filter((key) => key !== 'topScope') // topScope 權限暫時不參與比較, TODO
        .filter((key) => {
          // if (key !== 'topScope') return false
          return role.permissionV2[key] > this.permissionV2[key]
        })

      if (highterPermission.length > 0) {
        console.log(`[checkRole] #2 ${role.name} 有權限(${highterPermission})比登入者(${loggedUserInfo?.id})高`)
        return false
      }

      // 3. 不能編輯 topScope 比 自己(登入者)群組 或 自己(登入者)角色可識範圍 高的角色
      // 3.1 有 登入者角色可視群組(範圍)以下 的角色
      const isLoggedRoleScopeGroup = loggedUserRoleGroupIdList.includes(
        role.permissionV2.topScope
      )
      if (isLoggedRoleScopeGroup) {
        const loggedTopScopeGroup = this.groupInfoList.find((grp) => Number(grp.id) === loggedUserRole.permissionV2.topScope)
        console.log(`[checkRole] #3.1 ${role.name} 可視範圍, 有登入者(${loggedUserInfo?.id})可視範圍(${loggedTopScopeGroup?.name})以下的`)
      }

      // 3.2 有 登入者群組以下 的角色
      const isLoggedRoleGroup = loggedUserGroupIdList.includes(
        role.permissionV2.topScope
      )

      if (isLoggedRoleGroup) {
        const group = this.groupInfoList.find((grp) => grp.id === loggedUserInfo?.groupId)
        console.log(`[checkRole] #3.2 ${role.name} 可視範圍, 有登入者(${loggedUserInfo?.id})群組(${group?.name})以下的`)
      }

      const groupScopeRet = isLoggedRoleScopeGroup || isLoggedRoleGroup
      console.log(`[checkRole] #3 ${role.name} 可視範圍,`, groupScopeRet)

      // 4. topScope = 0 的角色; 若登入者也是 topScope = 0 的角色 => 可編輯
      const check_topScope = role.permissionV2.topScope === DefaultTopScope
      if (check_topScope) {
        console.log(`[checkRole] #4 ${role.name} 可視範圍, 為預設角色(topScop = 0)`)
        // return groupScopeRet
      }

      // *. final
      const ret = groupScopeRet || check_topScope
      console.log(`[checkRole] #5 ${role.name} ,`, ret)
      return ret
    },
    onOpenPeremissionGroup(param) {
      if (this.editRoleIndex < 0) {
        this.permissionGroup[param] = !this.permissionGroup[param]
      }
    },
    openAllPermissionGroup() {
      if (this.editRoleIndex < 0) {
        Object.keys(this.permissionGroup).forEach((key) => {
          this.permissionGroup[key] = true
        })
      }
    }
  },
  beforeDestroy() {
    this.notExistTopScopeList = null
  }
}
</script>

<style lang="scss" scoped>
$leftBarW: 50px;
$paramW: px2rem(400);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrap-role {
  width: 100%;
  height: 100%;
  background: #282942;
  position: relative;

  .container-role {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #282942;
    position: relative;

    table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;
      background: #282942;
    }

    td:first-child,
    th:first-child {
      position: sticky;
      left: 0; /* 首行永遠固定於左 */
      z-index: 2;
    }

    thead tr th {
      position: sticky;
      top: 0; /* 列首永遠固定於上 */
      padding-bottom: px2rem(4);
      z-index: 2;
    }

    th:first-child {
      z-index: 3;
    }

    th {
      width: px2rem(260);
      height: px2rem(70);
      background: #282942;
      padding: px2rem(16) px2rem(8) 0 0; // 1.25rem 0.5rem 0 0;
      color: #ffffff;
    }

    th:first-child {
      width: $paramW;
    }

    .th-div {
      width: 100%;
      height: 100%;
      background-color: #6e7d9380;
      font-size: px2rem(24); // 1.5rem;
      font-weight: 500;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &.self-role {
        background-color: $SelfRoleBGC;
      }

      .edit-name {
        padding: 0 px2rem(12); // 0 0.75rem;

        input {
          width: 100%;
          font-size: px2rem(24); // 1.5rem;
          font-weight: 300;
          line-height: px2rem(30); // 1.875rem;
          border: none;
          outline: none;
          background: #4d556c;
          color: #ffffff;
          border-bottom: 1px solid #ffffff;
        }
      }

      .name {
        padding: 0 px2rem(60); // 0 3.75rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .th-menu {
        position: absolute;
        right: px2rem(4);
        top: px2rem(24);
        width: px2rem(36);
        height: px2rem(36);
        border-radius: px2rem(8);
        padding: px2rem(6) px2rem(6);
        cursor: pointer;

        &:not(.disabled .th-div .th-menu):hover {
          background: #ffffff1a;
          // background-color: #f00;
          &.th-menu-disabled {
            background-color: unset;
            cursor: unset;
          }
        }
        &.th-menu-disabled {
          background-color: unset;
          opacity: 0.2;
          cursor: unset;
        }

        img {
          vertical-align: top;
          // cursor: pointer;
        }
      }
    }
  }
}

.th-menu-dropdown {
  position: absolute;
  left: px2rem(-80);
  top: px2rem(60);
  width: px2rem(200);
  color: #ffffff;
  background: #151b35 0% 0% no-repeat padding-box;
  padding: px2rem(4) 0; // 0.25rem 0;
  border: 1px solid #4a5c78;
  border-radius: px2rem(8); // 0.5rem;
  z-index: 5;

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    padding: 0 px2rem(30); // 0 1.875rem;
    height: px2rem(47); // 2.9375rem;
    font-size: px2rem(24);
    font-weight: 300;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #4a5c78;
    }

    img {
      margin-right: px2rem(8);
      vertical-align: baseline;
    }
  }
}
.add-role {
  background: #6e7d9333;
  cursor: pointer;

  img {
    width: px2rem(20);
    height: px2rem(20);
    margin-right: px2rem(8);
  }
}

td {
  background: #282942;
  padding-right: px2rem(8);
  color: #ffffff;
  height: px2rem(67);
  font-size: px2rem(24);
  line-height: px2rem(26);
  font-weight: 300;
  // text-align: center;
}

.td-div {
  width: 100%;
  height: 100%;
  background: #6e7d9333;
  display: flex;
  justify-content: center;
  align-items: center;

  .view-group {
    color: #ffc600;
    font-weight: 500;

    img {
      vertical-align: bottom; // 使圖片與文字對齊
    }
    .default-scope {
      font-style: italic;
    }
  }

  .wrap-select-tree:deep {
    width: 95%;
    .tree-options {
      left: 0;
      // z-index: 3;
    }
  }
}

.not-available {
  opacity: 0.2;
}

.param {
  position: relative;
  z-index: -1;
  padding: px2rem(20) px2rem(30);
  background: #282942;
  justify-content: flex-start;

  img {
    visibility: hidden;

    &.info-icon {
      position: absolute;
      right: px2rem(16);
      top: px2rem(26);
      cursor: pointer;
    }
  }

  &:hover {
    background: #41475f;

    img {
      visibility: visible;
    }
  }

  span {
    padding-left: 4rem;
  }

  .param-tooltip {
    $padding: px2rem(24);
    $textIndent: 4rem; // 縮排

    position: absolute;
    left: calc(100% + 0.5rem);
    top: 0;
    background: #151b35;
    text-align: left;
    width: px2rem(483);
    padding: $padding;
    border: 1px solid #4a5c78;
    border-radius: px2rem(8);
    font-size: px2rem(24);
    line-height: px2rem(30);

    div,
    ul {
      width: 100%;
      padding-bottom: $padding;
    }
    .title {
      font-weight: 500;
      // color: #ffe99f;
    }
    .desc {
      text-indent: $textIndent;
    }
    ul.level {
      text-indent: $textIndent;
      list-style-type: disc; // 實心原點
      list-style-position: inside; // 點在文字<li>內
    }
  }
}

.param-group {
  $tringlaeWidth: px2rem(8);
  $tringlaeColor: #fff99f;
  $tringlaeLeft: px2rem(-24);

  justify-content: flex-start;
  padding-left: 3rem;
  color: $tringlaeColor;
  font-weight: 500;
  cursor: pointer;

  .triangle-open,
  .triangle-close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    width: 1rem;
    margin-right: px2rem(8);
  }

  span {
    padding-left: 0;
  }

  &:hover {
    background: unset;
  }
}

.disabled {
  color: #545268;

  .th-div {
    background: #2f314a;

    .th-menu {
      img {
        filter: brightness(0) saturate(100%) invert(36%) sepia(2%)
          saturate(4400%) hue-rotate(190deg) brightness(88%) contrast(83%);
        cursor: default;
      }
    }
  }

  .td-div {
    background: #2b2c45;
  }
}

.drop-down {
  width: 94%;
}

.btn {
  font-size: px2rem(24);
  padding: px2rem(7) px2rem(16);
  border: 1px solid #ffc600;
  border-radius: px2rem(8);
  cursor: pointer;
}

.cancel {
  margin-right: px2rem(8);
  color: #ffc600;
}

.cancel:hover {
  background: #ffc60033;
}

.confirm {
  background: #ffc600;
  color: #282942;
}

.confirm:hover {
  background: #ffd133;
}

.info-icon {
  position: absolute;
  right: px2rem(16);
  top: px2rem(26);
  cursor: pointer;
}

.param-hover {
  background: #41475f;
}

img.gray {
  filter: brightness(0) saturate(100%) invert(26%) sepia(5%) saturate(2562%) 
    hue-rotate(191deg) brightness(98%) contrast(92%);
}

table tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

tfoot tr td .td-div {
  border-top: 1px solid #4a5c78;
}

.hori-line {
  position: fixed;
  width: 100%;
  height: 1px;
  background: #4a5c78;
  top: px2rem(166);
  z-index: 3;
}

.vert-line {
  position: fixed;
  width: 1px;
  height: 100%;
  background: #4a5c78;
  top: px2rem(70);
  left: calc($paramW + $leftBarW);
  z-index: 3;
}

.empty-td {
  height: 100%;

  .td-div.empty {
    background-color: rgba(110, 125, 147, 0.2);
    padding: px2rem(20) px2rem(30);
    &.empty-disabled {
      background-color: #2b2c45;
    }
  }
  .param.empty {
    background-color: #282942;
    &.empty-disabled {
      background-color: #2b2c45;
    }
  }
}
</style>
