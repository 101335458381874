<template>
  <portal to="account-map">
    <div class="account-map-modal" @click="onClose"></div>
    <div class="account-map-box">
      <div class="coordinate">
        <div class="view" v-if="isView">
          <img src="@/assets/icons/gps.svg" alt=""/>
          <span>{{ gpsText }}</span>
        </div>
        <div class="search" v-else>
          <input type="text" v-model="searchText" :placeholder="$t('am_map_search_hint')" @focus="onSelectInput" @blur="onCleanInput" @input="onTrimInput"/>
          <div class="search-btn" @click="onSearch">
            <img src="@/assets/icons/feather-search-w.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="map">
        <GmapMap
          ref="ammap"
          :center="center"
          :zoom="16"
          :minZoom="1"
          :options="getMapOptions()"
          style="width: 100%; height: 100%"
        >
          <GmapMarker
            ref="ammarker"
            :icon="{
              url: require('@/assets/icons/pin_s.svg'),
              scaledSize: { width: 30, height: 30 },
            }"
            :position="position"
            :clickable="false"
            :draggable="!isView"
            @drag="onDragMarker"
          />
        </GmapMap>
      </div>
      <div class="reset">
        <div v-if="!isView" class="error-msg">
          <div v-if="error_msg" style="display:none">{{ error_msg }}</div>
        </div>
        <div class="reset-btn">
          <img v-if="!isView" src="@/assets/icons/gps.svg" alt="" @click="onReset" />
          <span v-if="!isView" @click="onReset" >{{ $t('am_map_reset') }}</span>
        </div>
      </div>
      <div class="btns">
        <div v-if="!isView" class="btn cancel" @click="onClose">{{ $t('cancel') }}</div>
        <div class="btn" @click="onSave">{{ $t('confirm') }}</div>
      </div>
    </div>
  </portal>
</template>

<script>
import { euTF } from '@/utils/global.js'
import { getGpsStr } from '@/config/account.js'

const default_position = {
  lat: 24.807824129248665,
  lng: 121.03832092621745
}

export default {
  name: 'PortalMap',
  props: ['bEdit', 'gps'/*, 'action'*/],
  data() {
    return {
      euTF,
      gpsText: '',
      searchText: '',
      ammap: null,
      // ammarker: null,

      center: default_position,
      position: default_position,

      initGps: {lat:0, lng:0},
      editGps: {latitude:0, longitude:0},
      // 經緯度格式錯誤訊息
      error_msg: null
    }
  },
  mounted() {
    // 取得 GmapMap
    this.$nextTick(() => {
      this.ammap = (this.$refs.ammap) ? this.$refs.ammap : null;
      // this.ammarker = (this.$refs.ammarker) ? this.$refs.ammarker : null;
    })

    if(this.gps) {
      this.setInitGps(this.gps.latitude, this.gps.longitude)

      this.setMapCenter(this.gps.latitude, this.gps.longitude)
      this.setMarkerPosition(this.gps.latitude, this.gps.longitude)

      this.setEditGps(this.gps.latitude, this.gps.longitude)
      this.setSearchText(this.gps.latitude, this.gps.longitude)
    }
  },
  computed: {
    isView() {
      const ret = this.bEdit /*&& ( this.gps && this.gps.lock === euTF.false )*/
      return !ret;
    },
  },
  methods: {
    setInitGps(lat, lng) {
      this.initGps.lat = lat
      this.initGps.lng = lng
    },
    setEditGps(lat, lng) {
      this.editGps.latitude = lat
      this.editGps.longitude = lng

      if(this.editGps.lock === undefined) {
        this.editGps.lock = this.gps.lock
      }
    },
    setMapCenter(lat, lng) {
      this.center.lat = lat
      this.center.lng = lng
    },
    setMarkerPosition(lat, lng) {
      this.position.lat = lat
      this.position.lng = lng
    },
    getMapOptions() {
      return {
        mapTypeId: 'terrain', // terrain: 地形
        // zoomControl: true, // 是否能放大縮小地圖，預設為true。
        mapTypeControl: false, // 是否能切換地圖樣式，預設為true。
        scaleControl: false, // 是否顯示地圖比例尺。
        streetViewControl: false, // 是否讓使用者使用街景功能。
        rotateControl: false,
        fullscreenControl: false, // 是否開啟全螢幕地圖功能。
        disableDefaultUI: false,
        // draggable: true, // 可否拖曳地圖，預設為true。
        styles: [
          // {
          //   featureType: 'administrative.land_parcel',
          //   elementType: 'labels',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'poi',
          //   elementType: 'labels.text',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'poi.business',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'road',
          //   elementType: 'labels.icon',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          // {
          //   featureType: 'transit',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // }
        ]
      }
    },
    setSearchText(lat, lng) {
      const gpsStr = getGpsStr(lat, lng)
      this.searchText = gpsStr
      this.gpsText = gpsStr
    },
    setPosition(lat, lng /*, caller*/) {
      // console.log(`[setPosition.${caller}] lat, lng:`, lat, lng);

      // 定位 底圖 center
      // this.setMapCenter(lat, lng)
      this.$refs.ammap?.$mapPromise.then((map) => {
        map.panTo({lat, lng})
      })

      // 定位 marker
      this.setMarkerPosition(lat, lng)

      // Set editGps
      this.setEditGps(lat, lng)
      this.setSearchText(lat, lng)
    },
    onDragMarker(event) {
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()

      this.setMarkerPosition(lat, lng)
      this.setEditGps(lat, lng)
      this.setSearchText(lat, lng)
      // console.log(`${lat},${lng}`)
    },
    onSelectInput(event) {
      // event.target.select();
      event.target.setSelectionRange(0, event.target.value.length)
    },
    onTrimInput() {
      // Clean error_msg
      this.error_msg = null

      // Trim value
      this.searchText = this.searchText.trim();

      // Parser latitude, longitude
      let [lat, lng] = this.searchText.split(`,`).map((latLng) => (latLng === '') ? undefined : Number(latLng))
      if (isNaN(lat) && isNaN(lng)) {
        this.error_msg = `Error: latitude, longitude`
        return
      } else if( isNaN(lat) || ((lat < 0) || (lat > 90)) ) {
        this.error_msg = `Error: latitude`
        return
      } else if (isNaN(lng) || ((lng < 0) || (lng > 180)) ) {
        this.error_msg = `Error: longitude`
        return
      }
      // console.log(`[onTrimInput] lat, lng:`, lat, lng);

      // 定位
      this.setPosition(lat, lng, 'onTrimInput')
    },
    onCleanInput() {
      this.error_msg = null
    },
    getBrowserPosition(position) {
      const { latitude:lat, longitude:lng } = position.coords

      this.setPosition(lat, lng, 'getBrowserPosition')
    },
    onSearch() {
      // 從瀏覽器取得位址資訊
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getBrowserPosition)
      }
    },
    onReset() {
      // 將 點位&底圖 重置在原點位(拖拉marker之前的位置)
      const { lat, lng } = this.initGps

      this.setPosition(lat, lng, 'onReset')
    },
    onClose() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('confirm', this.editGps)
    }
  }

}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.account-map-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.account-map-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 540px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #282942;
  border: 1px solid #4A5C78;
  color: #ffffff;
  border-radius: 4px;
  padding: 32px;
  font-size: 24px;
  z-index: 3;
}

.coordinate {
  margin-bottom: 10px;
}
.view {
  display: flex;
  align-items: center;
}
.view > span {
  margin-left: 0.5rem;
}
.search {
  display: flex;
  align-items: center;
}
.search > input[type='text'] {
  border-radius: 8px;
  height: 48px;
  width: 408px;
  border: 0;
  outline: 0;
  font-size: 24px;
  padding: 0 12px;
}
.search-btn {
  background: #4A5C7888;
  padding: 13px 36px 11px 36px;
  border-radius: 8px;
  margin-left: 16px;
  cursor: pointer;
  font-size: 0; /* 消除圖片與 div 底部有一小塊留白 */
}
.search-btn:hover {
  background: #6e7d9388;
}
.search-btn > img {
  height: 24px;
  width: 24px;
}
.map {
  height: 520px;
  width: 520px;
}

.reset {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.error-msg {
  color: #F94144;
}
.reset-btn {
  cursor: pointer;
}
.reset > span {
  font-size: 20px;
}
.reset-lock {
  cursor: text;
  margin-top: 8px;
}
.btns {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

.cancel {
  border: 1px solid #FFC600;
  background: #282942;
  color: #FFC600;
  margin-right: 32px;
}

.cancel:hover {
  background: #FFC60033;
}
</style>