<template>
  <div class="wrap-group">
    <GroupTop />
    <div class="container-group">
      <GroupTable :showEdit="showEdit" />
      <GroupEdit :showEdit="showEdit" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GroupTop from './group/Top.vue'
import GroupTable from './group/Table.vue'
import GroupEdit from './group/Edit.vue'
import { PermissionLevel } from '@/config/permissions.js'

export default {
  name: 'Group',
  components: { GroupTop, GroupTable, GroupEdit },
  computed: {
    ...mapState(['permissionV2']),
    showEdit() {
      return this.permissionV2.group >= PermissionLevel.L2
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap-group {
  width: 100%;
  height: 100%;
}

.container-group {
  display: flex;
  width: 100%;
  height: calc(100% - px2rem(87));
}
</style>
