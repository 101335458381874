<template>
  <div class="group-top">
    <div class="input-select">
      <div class="search">
        <input
          id="group-filter"
          type="text"
          v-model="filterInput"
          @keyup.enter="searchGroup"
          :placeholder="$t('am_search_group')"
        />
        <div v-if="filterInput.length > 0" class="clear" @click="clearInput">
          <img src="@/assets/icons/clear.svg" alt="" />
        </div>
        <div class="search-icon" @click="searchGroup">
          <img src="@/assets/icons/feather-search-gray.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// import { euEditMode } from '@/config/account.js'

export default {
  name: 'GroupTop',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('userinfo/group', ['filterText']),
    filterInput: {
      get() {
        return this.filterText
      },
      set(value) {
        this.updateFilterText(String(value).trim())
      }
    }
  },
  methods: {
    ...mapMutations('userinfo/group', ['updateFilterText']),
    searchGroup() {
      this.updateFilterText(this.filterText)
    },
    clearInput() {
      this.updateFilterText('')
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.group-top {
  width: 100%;
  height: px2rem(87);
  background: #282942;
  border-bottom: 1px solid #4a5c78;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: px2rem(8);
}

.input-select {
  width: 80%;
  display: flex;
  /* align-items: center; */
}

.search {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: px2rem(24);
}

.search span {
  font-size: px2rem(24);
  font-weight: 300;
  color: #9d9d9d;
}

.search input {
  /* flex: 1; */
  /* width: 100%; */
  width: px2rem(460);
  border: none;
  outline: none;
  color: #222222;
  font-size: px2rem(24);
  font-weight: 300;
  border-radius: px2rem(8);
  height: px2rem(48);
  padding: 0 px2rem(12);
  background: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clear {
  position: absolute;
  right: px2rem(45);
  top: px2rem(11);
  width: px2rem(12);
  height: px2rem(12);
  cursor: pointer;
}

.clear img {
  width: 100%;
  height: 100%;
}

.clear {
  filter: brightness(0) saturate(100%) invert(73%) sepia(0%) saturate(0%)
    hue-rotate(180deg) brightness(85%) contrast(99%);
}

.search-icon {
  position: absolute;
  right: px2rem(12);
  top: px2rem(13);
  width: px2rem(22);
  height: px2rem(22);
  cursor: pointer;
}

.search-icon:hover {
  filter: brightness(0) saturate(100%) invert(9%) sepia(29%) saturate(1346%)
    hue-rotate(189deg) brightness(90%) contrast(95%);
}

.select-type {
  min-width: px2rem(120);
  margin-right: px2rem(24);
}

.select-abnormal-account {
  width: px2rem(180);
  margin-right: px2rem(8);
}
</style>
