<template>
  <div class="wrap-single-group">
    <div class="header">
      <div v-if="!bEdit" class="group-action">
        <div class="parent-name">
          {{
            editGroup
              ? getGroupName(editGroup.parent)
              : $t('am_parent_group_empty')
          }}
        </div>
        <div v-if="showEdit" class="action">
          <div v-if="!bEdit" class="action-btn" @click="onEdit">
            {{ $t('edit') }}
          </div>
        </div>
      </div>
      <div v-if="!bEdit" class="group-name">
        <div class="group-icon">
          <img alt="" src="@/assets/icons/member-num.svg" />
        </div>
        <div class="text">
          {{ editGroup ? getGroupName(editGroup.id) : '' }}
        </div>
      </div>

      <div v-if="bEdit" v-html="$t('account_required')"></div>
    </div>

    <div class="content">
      <div v-if="bEdit && !isRootGroup(editGroup)" class="item">
        <div class="title">
          <div>{{ $t('am_parent_group') }}<span>*</span></div>
        </div>
        <!-- value -->
        <SelectTree
          class="select-tree"
          v-model="editGroup.parent"
          :options="groupTreeList"
          :dataList="groupList"
          :placeholder="$t('am_parent_group_hint')"
          @input="onSelectParent"
        />
      </div>
      <div v-if="bEdit" class="item">
        <div class="title">
          <div>{{ $t('am_group_name') }}<span>*</span></div>
        </div>
        <div class="value" :class="{ editable: bEdit }">
          <div>
            <input
              type="text"
              class="edit-input"
              v-model="editGroup.name"
              name="name"
              :placeholder="$t('am_group_name_hint')"
              @input="onInput"
            />
          </div>
        </div>
      </div>
      <div v-if="errorName" class="error">{{ $t(errorName) }}</div>

      <div class="item" :class="{ disable: bEdit }">
        <div class="title">
          <div>{{ $t('am_group_id') }}</div>
        </div>
        <div class="value">
          <div>{{ editGroup ? editGroup.id : '' }}</div>
        </div>
      </div>
      <div class="item" :class="{ disable: bEdit }">
        <div class="title">
          <div>{{ $t('am_group_updated_time') }}</div>
        </div>
        <div class="value">
          <div>{{ '' }}</div>
        </div>
      </div>
      <div class="item" :class="{ disable: bEdit }">
        <div class="title">
          <div>{{ $t('am_group_create_time') }}</div>
        </div>
        <div class="value">
          <div>{{ '' }}</div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div v-if="bEdit" class="footer">
      <div class="btn cancel" @click="onCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onSave">{{ $t('confirm') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SelectTree from '@/components/AccountManagement/tools/SelectTree.vue'
import { euEditMode } from '@/config/account.js'
import { apiEditGroup } from '@/api/index.js'

export default {
  name: 'GroupEditSingle',
  components: { SelectTree },
  props: ['showEdit'],
  data() {
    return {
      bEdit: false,
      initGroup: null,
      editGroup: null,
      errorName: null
    }
  },
  computed: {
    ...mapState('userinfo', ['groupList', 'groupTreeList', 'filterText']),
    ...mapState('userinfo/group', ['groupId', 'editQuota']),
  },
  watch: {
    editMode() {
      // console.log(`[group.watch.editMode] editMode:`, Object.keys(euEditMode)[this.editMode])
      this.bEdit = this.editMode === euEditMode.editing ? true : false
    },
    groupId() {
      // console.log(`[group.watch.groupId] groupId:`, this.groupId)
      this.getGroupValue('watch.groupId')
      if (this.bEdit) {
        this.onEdit()
      }
    }
  },
  mounted() {
    this.getGroupValue('mounted')
  },
  methods: {
    ...mapMutations('userinfo/group', [
      'updateEditMode',
      'updateParentGroupId',
      'updateEditQuota'
    ]),
    ...mapActions('userinfo', ['getGroupList', 'getUserTree']),
    getGroupValue(/*src*/) {
      const group = this.groupList.find(
        (group) => String(group.id) === this.groupId
      )
      // console.log(`[getGroupValue] groupId, group:`, this.groupId, JSON.stringify(group))
      if (group) {
        this.initGroup = { ...group }
        this.editGroup = { ...group }
      }
    },
    setGroupInfo(parentId) {
      this.editGroup.parent = parentId
    },
    getGroupName(groupId) {
      const group = this.groupList.find((group) => String(group.id) === groupId)
      return group ? group.name : this.$t('am_parent_group_empty')
    },
    isRootGroup(editGroup) {
      return editGroup && editGroup.layer === 0 ? true : false
    },
    onEdit(/*event*/) {
      this.bEdit = !this.bEdit
      this.updateEditMode(this.bEdit ? euEditMode.editing : euEditMode.editView)
      this.updateEditQuota(false)
    },
    onSelectParent(groupId) {
      // console.log(`[onSelectParent] groupId:`, groupId)
      this.updateParentGroupId(groupId)
      this.setGroupInfo(groupId)
    },
    onInput(event) {
      const { name, value } = event.target
      this.editGroup[name] = value.trim()
    },
    onCancel() {
      // console.log(`[onCancel]`)
      if (this.groupId) {
        this.updateEditMode(euEditMode.editView)
      } else {
        this.updateEditMode(euEditMode.none)
      }
      this.bEdit = false
      this.errorName = null
    },
    async onSave() {
      // console.log(`[onSave]`)
      this.errorName = null
      if (!this.editGroup.name) {
        this.errorName = 'account_required_info'
        return
      }

      // console.log(`[onSave] editGroup:`, JSON.stringify(this.editGroup));
      try {
        const res = await apiEditGroup(this.editGroup)
        if (res.status === 200) {
          await this.getGroupList()
          await this.getUserTree()

          this.getGroupValue('onSave')
          this.$notify({
            title: `${this.$t('am_edit_group_pass')} ${this.editGroup.name}`,
            type: 'success',
          })
        } else {
          throw res
        }
      } catch (err) {
        // console.error(`[onSave] apiEditGroup fail`, err);
        this.$notify({
          title: `${this.$t('am_edit_group_fail')} ${this.editGroup.name}`,
          message: err.response.data.message,
          type: 'error',
        })
      } finally {
        this.onCancel()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-single-group {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  font-size: px2rem(24);
  // font-weight: 300;
  line-height: px2rem(36);
  display: flex;
  flex-direction: column;
}
.edit-group {
  height: calc(100% - px2rem(120));
  padding: px2rem(8) px2rem(20) px2rem(20) px2rem(20);
  overflow: overlay;
}
/* View/Edit */
.header:deep {
  padding: px2rem(20) px2rem(8) 0 px2rem(20);

  .required {
    color: #ffc600;
  }
}
.edit-group-name {
  width: 60%;
  outline: none;
  border: none;
  border-radius: px2rem(8);
  padding: px2rem(4) px2rem(12);
  font-size: px2rem(24);
  line-height: px2rem(36);
}
.group-action {
  display: flex;
  justify-content: space-between;
  margin-bottom: px2rem(6);
}
.action {
  display: flex;
  height: calc(
    px2rem(35) + px2rem(6) * 2
  ); /* action-btn: line-height + padding(top & bottom) */
}
.action-btn {
  padding: px2rem(6) px2rem(24);
  line-height: px2rem(35);
  background: #4a5c7880;
  border-radius: px2rem(8);
  cursor: pointer;
}
.action-btn:hover {
  background: #4a5c78;
}

.parent-name {
  font-size: px2rem(24);
}

.group-name {
  display: flex;
  margin-bottom: px2rem(12);
}
.group-icon {
  height: px2rem(32);
  width: px2rem(32);
  padding: 1px;
}
.group-name img {
  height: 100%;
  width: 100%;
  font-size: 0;
}
.group-name .text {
  font-size: px2rem(32);
  padding-left: 1rem;
}
.content {
  flex: 1;
  padding: px2rem(20) px2rem(8) px2rem(20) px2rem(20);
  overflow: overlay;
  position: relative;
}
.item {
  display: flex;
  margin-bottom: px2rem(12);
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: stretch;
  width: 25%;
  background: #282942;
  border: 1px solid #ffffff80;
  border-right: none;
  border-radius: px2rem(10) 0px 0px px2rem(10);
  color: #ffffff;
  padding: px2rem(6) px2rem(20);
}
.title span {
  color: #ffc600;
}

.value {
  display: flex;
  align-items: center;
  width: 65%;
  min-height: px2rem(50);
  background: #4a5c78;
  border: 1px solid #ffffff80;
  border-radius: 0px px2rem(10) px2rem(10) 0px;
  color: #ffffff;
  padding: px2rem(6) px2rem(40) px2rem(6) px2rem(20);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
/* .value div {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
} */
.editable {
  /* height: 100%; */
  background: #ffffff;
  color: #282942;
  outline: none;
}
.disable {
  opacity: 0.2;
}
.edit-input {
  width: 100%;
  outline: none;
  border: none;
  font-size: px2rem(24);
  // font-weight: 300;
  // text-align: center;
}
.select-tree:deep {
  border-radius: 0px px2rem(10) px2rem(10) 0px !important;
  width: 65%;
  text-align: center;
  background-color: #fff;

  .select-selected { }
}
.error {
  font-size: px2rem(20);
  color: #f94144;
}

/* footer */
.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;
}
.btn {
  border: 1px solid #ffc600;
  border-radius: px2rem(8);
  padding: px2rem(10) px2rem(48);
  margin-left: px2rem(16);
  margin-right: px2rem(16);
  font-size: 1.5rem;
  // font-weight: 300;
  white-space: nowrap;
  cursor: pointer;
}
.cancel {
  color: #ffc600;
}
.cancel:hover {
  background: #ffc60033;
}
.confirm {
  background: #ffc600;
  color: #282942;
}
.confirm:hover {
  background: #ffd133;
}
</style>
