<template>
  <div class="wrap-new-account">
    <div class="new-account" ref="content" @scroll="onScroll">
      <div class="info" v-html="$t('account_required')"></div>

      <div
        class="item"
        v-for="(item, idx) in deviceItems"
        :key="`${item.title}_${idx}`"
      >
        <div class="item-block" :id="item.key">
          <div class="item-desc" v-if="item.key === 'id' || item.key === 'password'">
            <span v-if="item.key === 'id'">
              {{ account_id_tip }}
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="accSpecialChars">
                <img class="acc-psw-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span v-else-if="item.key === 'password'">
              {{ account_pw_tip }}
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
                <img class="acc-psw-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
          </div>
          <div
            class="item-detail"
            :class="{ 'not-editable': item.ui && !item.canEdit }"
          >
            <div class="title" :class="{ 'group-title': !item.ui }">
              <img v-if="item.ui" :src="item.icon" alt="" />
              <div>{{ $t(item.title) }}</div>
              <span v-if="item.required" class="required">*</span>
            </div>
            <div v-if="item.ui" class="content">
              <!-- 群組 -->
              <SelectTree
                v-if="item.key === 'group'"
                class="select-tree"
                v-model="newAccount.groupId"
                :options="groupTreeList"
                :dataList="groupList"
                :placeholder="$t('am_search_group')"
              />
              <!-- 帳號ID -->
              <form v-else-if="item.key === 'id'" class="account">
                <input
                  type="text"
                  autocomplete="off"
                  :class="{ security: !showAccountId }"
                  v-model="newAccount.id"
                  @input="checkIdInput"
                />
                <img v-if="showAccountId" src="@/assets/icons/EyeOpen.svg" @click="onShowAccountId">
                <img v-else src="@/assets/icons/EyeClose.svg" @click="onShowAccountId">
              </form>
              <!-- 密碼 -->
              <form v-else-if="item.key === 'password'" class="password">
                <input
                  autocomplete="off"
                  :type="showPsw ? 'text' : 'password'"
                  v-model="newAccount.password"
                  @input="checkPasswordInput"
                />
                <img v-if="showPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handlePswShow">
                <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handlePswShow">
              </form>
              <!-- 確認密碼 -->
              <form v-else-if="item.key === 'password_confirm'" class="password">
                <input
                  autocomplete="off"
                  :type="showConfirmPsw ? 'text' : 'password'"
                  v-model="confirmPassword"
                  @input="checkConfirmPasswordInput"
                />
                <img v-if="showConfirmPsw" src="@/assets/icons/EyeOpen.svg" alt="" @click="handleConfirmPswShow">
                <img v-else src="@/assets/icons/EyeClose.svg" alt="" @click="handleConfirmPswShow">
              </form>
              <!-- 帳號名稱 -->
              <div v-else-if="item.key === 'info.name'">
                <input
                  type="text"
                  v-model="newAccount.name"
                  name="name"
                  @input="trimInput"
                />
              </div>
              <!-- 影片標題 -->
              <div v-else-if="item.key === 'video.title'">
                <input
                  type="text"
                  v-model="newAccount.videoTitle"
                  name="videoTitle"
                  @input="trimInput"
                />
              </div>
              <!-- 公開狀態 -->
              <Select
                v-else-if="item.key === 'public'"
                class="dropdown"
                v-model="newAccount.public"
                :options="publicOptions"
              />
              <!-- 帳號類型 -->
              <Select
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
                class="dropdown"
                v-model="newAccount.kind"
                :options="deviceKindOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 設備類型 -->
              <div
                v-else-if="item.key === 'deviceModelId'"
                class="device-model"
              >
                <img v-if="deviceModelIcon" class="edit" :src="deviceModelIcon" alt="" />
                <Select
                  class="dropdown"
                  v-model="newAccount.deviceModelId"
                  :classifySelect="true"
                  :options="deviceModelOptions"
                  :placeholder="$t('search_device_placehold') /*請選擇*/"
                />
              </div>
              <!-- 參數來源 -->
              <Select
                v-else-if="item.key === 'defaultSetting'"
                class="dropdown"
                v-model="newAccount.defaultSetting"
                :options="defaultSettingOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 浮水印 -->
              <Select
                v-else-if="item.key === 'watermarkEnabled'"
                class="dropdown"
                v-model="newAccount.watermarkEnabled"
                :options="watermarkOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 勾稽 -->
              <Select
                v-else-if="item.key === 'linkedMode'"
                class="dropdown"
                v-model="newAccount.linkedMode"
                :options="linkedModeOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- GPS -->
              <div v-else-if="item.key === 'gps'" class="gps">
                <div>{{ GPS }}</div>
                <div class="gps-lock" @click="onLockGps">
                  <img
                    v-if="newAccount.lock"
                    src="@/assets/icons/lock-gray.svg"
                    alt=""
                  />
                  <img v-else src="@/assets/icons/unlocked-gray.svg" alt="" />
                </div>
              </div>
              <!-- (車辨)事件保留 -->
              <!-- <Select
                v-else-if="item.key === 'lprKeepDay'"
                class="dropdown"
                v-model="newAccount.lprKeepDay"
                :options="keepOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              /> -->
              <!-- 保留時間 -->
              <Select
                v-else-if="item.key === 'video.keepDay'"
                class="dropdown"
                v-model="newAccount.keepDay"
                :options="keepOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
            </div>
          </div>
          <div
            class="item-error"
            v-if="
              haveError &&
              (item.key === 'id' ||
                item.key === 'password' ||
                item.key === 'password_confirm' ||
                item.key === 'deviceModelId' ||
                item.key === 'video.keepDay')
            "
          >
            <span v-if="item.key === 'id' && errorMsg.id">
              {{ $t(errorMsg.id.key || errorMsg.id, errorMsg.id?.param) }}
              <el-tooltip v-if="errorMsg.id.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="accSpecialChars">
                <img class="acc-psw-err-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span v-else-if="item.key === 'password' && errorMsg.password">
              {{ $t(errorMsg.password.key || errorMsg.password, errorMsg.password?.param) }}
              <el-tooltip v-if="errorMsg.password.info" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="pswSpecialChars">
                <img class="acc-psw-err-i" src="@/assets/icons/info.svg" alt="" />
              </el-tooltip>
            </span>
            <span
              v-else-if="
                item.key === 'password_confirm' && errorMsg.password_confirm
              "
            >
              {{ $t(errorMsg.password_confirm.key || errorMsg.password_confirm) }}
            </span>
            <!-- <span
              v-else-if="
                item.key === 'kind' &&
                item.title === 'account_from' &&
                errorKind
              "
            >
              {{ $t(errorKind) }}
            </span> -->
            <span v-else-if="item.key === 'deviceModelId' && errorMsg.deviceModelId">
              {{ $t(errorMsg.deviceModelId) }}
            </span>
            <span v-else-if="item.key === 'video.keepDay' && errorMsg.keepDay">
              {{ $t(errorMsg.keepDay) }}
            </span>
            <!-- <span v-else>
              {{ $t('account_required_info') }}
            </span> -->
          </div>
        </div>
        <div class="item-helper">
          <div class="gps-helper" v-if="item.helper && item.key === 'gps'">
            <div class="icon" @click="openMap">
              <img src="@/assets/icons/account-map.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div class="footer">
      <div class="btn cancel" @click="handleCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onAddDevice">
        {{ $t('confirm') }}
      </div>
    </div>
    <PortalMap
      v-if="showMap"
      :gps="{
        lock: newAccount.lock,
        latitude: newAccount.latitude,
        longitude: newAccount.longitude
      }"
      :bEdit="true"
      @close="closeMap"
      @confirm="confirmMap"
    />
    <PortalCreateConfirm
      v-if="showPortalCreateConfirm"
      :title="`${$t('am_device_create')}` /*新增設備*/"
      :content="`${$t(
        'am_device_create_confirm'
      )}` /*設備新增後將無法刪除, 您確定要新增嗎？*/"
      @close="handleCancel"
      @confirm="handleAddDevice"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { console } from '@/utils/lib.js'
import SelectTree from '../../tools/SelectTree.vue'
import Select from '../../tools/Select.vue'
import { apiCreateUser } from '@/api/index.js'
import {
  euTF,
  euAccountKind as euKind,
  keepOptions,
  deviceKindOptions,
} from '@/utils/global.js'
import {
  pswLen,
  pswSpecialChars,
  validatePwsLen,
  validatePswChar,
  validatePswSpecialChar,
  accLen,
  accSpecialChars,
  validateAccountLen,
  validateAccountIDChar
} from '@/utils/lib.js'
import {
  euEditMode,
  euPublic,
  publicOptions,
  euLinkMode,
  linkedModeOptions,
  euDefaultSetting,
  defaultSettingOptions,
  euWaterMark,
  watermarkOptions,
  createDeviceItems as defDeviceItems,
  getGpsStr
} from '@/config/account.js'
import PortalMap from '../../portal/ProtalMap.vue'
import PortalCreateConfirm from '@/components/AccountManagement/portal/PortalCreateConfirm.vue'
import { genDeviceModelOptions } from '@/components/AccountManagement/Device.vue'

export default {
  name: 'DeviceEditNew',
  components: { SelectTree, Select, PortalMap, PortalCreateConfirm },
  data() {
    return {
      isDev: console.get(),
      accLen,
      accSpecialChars,
      pswLen,
      pswSpecialChars,

      deviceItems: JSON.parse(JSON.stringify(defDeviceItems)),
      newAccount: {
        groupId: '', // 群組
        id: null, // 帳號
        password: undefined, // 密碼
        name: '', // 帳號名稱
        videoTitle: '', // 影片標題
        public: euPublic.private, // 帳號公開
        kind: euKind.device, // 帳號類型
        linkedMode: euLinkMode.not_link,
        deviceModelId: null, // 設備類型
        defaultSetting: euDefaultSetting.global, // 參數來源
        watermarkEnabled: euWaterMark.off, // 浮水印
        // GPS
        lock: euTF.false,
        latitude: 0,
        longitude: 0,
        // 資料
        // lprKeepDay: 0, // 事件保留
        keepDay: null, // 保留時間
        // ipSrc: '' // IP 來源 TODO
      },
      confirmPassword: null,
      showAccountId: false,
      showPsw: false,
      showConfirmPsw: false,
      showMap: false,
      keepOptions,

      // errorIpSrc: false, // IP來源錯誤
      errorMsg: {
        id: null, // 帳號錯誤
        password: undefined, // 密碼錯誤
        password_confirm: null, // 確認密碼錯誤
        kind: null, // 帳號種類錯誤
        deviceModelId: null, // 設備總類錯誤,
        keepDay: null, // 影片保留時間
        ipSrc: null, // IP來源錯誤
      },

      showPortalCreateConfirm: false
    }
  },
  computed: {
    ...mapState('userinfo', ['groupList', 'groupTreeList', 'deviceModels']),
    ...mapState('userinfo/device', [
      'deviceId',
      'editMode',
      'addDeviceGroupId'
    ]),
    account_id_tip() {
      const { min, max } = accLen
      return this.$t('account_id_tip', { min, max })
    },
    account_pw_tip() {
      const { min, max } = pswLen
      return this.$t('account_pw_tip', { min, max })
    },
    publicOptions() {
      return this.formatOptions(publicOptions)
    },
    deviceKindOptions() {
      return this.formatOptions(
        deviceKindOptions.filter((option) => !option.disabled)
      )
    },
    deviceModelOptions() {
      if (this.deviceModels) {
        return genDeviceModelOptions(this.deviceModels)
      }
      return []
    },
    deviceModelIcon() {
      const currValue = this.newAccount.deviceModelId
      const option = this.deviceModelOptions.find(
        ({ value, classify }) => !classify && value === currValue
      )
      return option ? require(`@/assets/icons/${option.icon}`) : null
    },
    defaultSettingOptions() {
      return this.formatOptions(defaultSettingOptions)
    },
    watermarkOptions() {
      return this.formatOptions(watermarkOptions)
    },
    linkedModeOptions() {
      return this.formatOptions(linkedModeOptions)
    },
    GPS() {
      const { latitude, longitude } = this.newAccount
      const gps = getGpsStr(latitude, longitude)
      return gps
    },
    haveError() {
      const errors = Object.keys(this.errorMsg)
      const eKey = errors.find((key) => this.errorMsg[key])

      return eKey ? true : false
    },
  },
  methods: {
    ...mapActions('userinfo', ['getDeviceInfoList']),
    ...mapMutations('userinfo', ['updateAccountPortal', 'updateDeviceList']),
    ...mapMutations('userinfo/device', ['updateDeviceId', 'updateEditMode']),
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    trimInput(event) {
      const { name, value } = event.target

      if (name) {
        this.newAccount[name] = value.trim()
      }
    },
    // setErrorMsg(key, msg) {
    //   this.errorMsg = { ...this.errorMsg, ...{ [key]: msg } }
    // },
    checkIdInput(event) {
      const key = 'id'
      const { value } = event.target

      this.errorMsg[key] = null
      this.newAccount[key] = value.trim() // 移除字串起始及結尾處的空白字元

      if (this.newAccount[key] == null) {
        return
      }

      if (this.newAccount[key].length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info',
        }
      }
      // else if (this.newAccount[key].length < accLen.min) {}
      else if (!validateAccountIDChar(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_id_char_error',
          info: true
        }
      } else if (!validateAccountLen(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_id_length_error',
          param: {
            min: accLen.min,
            max: accLen.max
          }
        }
      }
    },
    checkPasswordInput(event) {
      const key = 'password'
      const { value } = event.target

      this.errorMsg[key] = null
      this.newAccount[key] = value.trim() // 移除字串起始及結尾處的空白字元

      if (this.newAccount[key] == null) {
        return
      }

      if (this.newAccount[key].length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info'
        }
      } else if (!validatePswChar(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_pw_char_error', // 密碼只允許英數，大小寫，及特殊字元
          info: true
        }
      } else if (!validatePwsLen(this.newAccount[key])) {
        const { min, max } = pswLen
        this.errorMsg[key] = {
          key: 'account_pw_length_error', // 密碼長度需{min}～{max}個字元
          param: {
            min,
            max
          }
        }
      } else if (!validatePswSpecialChar(this.newAccount[key])) {
        this.errorMsg[key] = {
          key: 'account_pw_special_char_error', // 密碼須包含任一特殊字元 {chars}
          info: true
        }
      }

      this.checkConfirmPasswordInput()
    },
    checkConfirmPasswordInput(event) {
      const key = 'password_confirm'
      const value = event ? event.target.value.trim() : this.confirmPassword

      this.errorMsg[key] = null
      this.confirmPassword = value

      if (this.confirmPassword === null) {
        return
      }

      if (this.confirmPassword.length === 0) {
        this.errorMsg[key] = {
          key: 'account_required_info'
        }
      } else if (this.confirmPassword !== this.newAccount.password) {
        this.errorMsg[key] = {
          key: 'account_pw_error'
        }
      }
    },
    onShowAccountId() {
      this.showAccountId = !this.showAccountId
    },
    handlePswShow() {
      this.showPsw = !this.showPsw
    },
    handleConfirmPswShow() {
      this.showConfirmPsw = !this.showConfirmPsw
    },
    // checkDeviceModel() {
    //   const fail = this.newAccount.deviceModelId === null
    //   return fail ? 'account_required_info' : ''
    // },
    validateIP(ip) {
      if (!ip || ip.length === 0) {
        return false
      }

      const ipClasses = ip.split('.')
      if (ipClasses.length !== 4) {
        return false
      }

      return ipClasses.filter(
        (ipClass) => Number(ipClass) < 0 || Number(ipClass) > 255
      ).length <= 0
        ? true
        : false
    },
    checkIpSrc() {
      // 移除字串起始及結尾處的空白字元
      this.newAccount.ipSrc = this.newAccount.ipSrc.trim()
      this.errorIpSrc = this.validateIP(this.newAccount.ipSrc) ? false : true
    },
    onLockGps() {
      this.newAccount.lock =
        this.newAccount.lock === euTF.true ? euTF.false : euTF.true
    },
    openMap() {
      this.updateAccountPortal(`account-map`)
      this.showMap = true
    },
    closeMap() {
      this.showMap = false
    },
    confirmMap(newGps) {
      this.closeMap()
      const { lock, latitude, longitude } = newGps

      this.newAccount.lock = lock
      this.newAccount.latitude = latitude
      this.newAccount.longitude = longitude
    },
    checkAllRequired() {
      let key = `id`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `password`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `password_confirm`
      if (!this.confirmPassword || this.confirmPassword.length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `deviceModelId`
      if (!this.newAccount[key] || this.newAccount[key].length === 0) {
        this.errorMsg[key] = 'account_required_info'
      }

      key = `keepDay`
      if (this.newAccount[key] === null) {
        this.errorMsg[key] = 'account_required_info'
      }

      return !this.haveError
    },
    handleCancel() {
      // 回到檢視帳號模式

      if (this.deviceId) {
        this.updateDeviceId(this.deviceId)
      } else {
        this.updateDeviceId('')
      }
      this.updateEditMode(euEditMode.editView)
      this.showPortalCreateConfirm = false
    },
    onScroll(/*event*/) {
      // console.log(`[onScroll] scrollTop, scrollHeight, offsetHeight:`, event.target.scrollTop, event.target.scrollHeight, event.target.offsetHeight)
    },
    async handleAddDevice() {
      // console.log(`[handleAddDevice] newAccount:`, this.newAccount)
      // let isDev = true
      // if (isDev) return

      try {
        const res = await apiCreateUser(this.newAccount)
        // const res = { status: 200, data: {} } // for debug
        if (res.status !== 200) {
          throw res
        }

        // 重新取得使用者資訊, 並切換到檢視模式
        await this.getDeviceInfoList()
        this.updateEditMode(euEditMode.editView)
        this.updateDeviceId(this.deviceId)

        this.$notify({
          title: this.$t('account_add'),
          message: this.$t('account_add_success'),
          type: 'success',
        })
      } catch (err) {
        this.$notify({
          title: this.$t('account_add'),
          message: `${this.$t('account_add_failed')}: ${
            err.response.data.message
          }`,
          type: 'error',
        })
        this.updateEditMode(euEditMode.editView)
      } finally {
        this.showPortalCreateConfirm = false
      }
    },
    onAddDevice() {
      // console.log(`[onAddDevice] errorMsg:`, this.errorMsg)
      if (!this.checkAllRequired()) {
        this.$nextTick(() => {
          const errElem = document.querySelector(`.item-error:has(span)`)
          if (errElem) {
            errElem.scrollIntoView({block: "end", inline: "nearest", behavior: 'smooth' })
          }
        })

        return
      }

      this.updateAccountPortal('account-create-confirm')
      this.showPortalCreateConfirm = true
    },
  },
  watch: {
    addDeviceGroupId() {
      this.newAccount.groupId = `${this.addDeviceGroupId}`
    },
    'newAccount.groupId'(nVal) {
      this.newAccount.groupId = `${nVal}` // 後台是接收字串的 groupId
    },
    'newAccount.deviceModelId'(nVal) {
      if (nVal) {
        this.errorMsg.deviceModelId = null
      }
    },
    'newAccount.keepDay'(nVal) {
      if (nVal !== null) {
        this.errorMsg.keepDay = null
      }
    }
  },
  mounted() {
    this.newAccount.groupId = `${this.addDeviceGroupId}`
  },
  destroyed() {
    this.showPortalCreateConfirm = false
    this.updateDeviceId('')
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-new-account {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  font-size: px2rem(20);
  // font-size: px2rem(24);
  // font-weight: 300;
  line-height: px2rem(36);
  display: flex;
  flex-direction: column;
}

.new-account {
  height: calc(100% - px2rem(120));
  padding: px2rem(8) 0 px2rem(20) px2rem(20);
  overflow: overlay;

  .info::v-deep {
    margin-bottom: px2rem(12);
    .required {
      @include required;
    }
  }

  .item {
    $HelperW: px2rem(48);
    display: flex;
    margin-top: px2rem(12);

    .item-block {
      display: flex;
      flex-direction: column;
      width: calc(100% - $HelperW);
      // background-color: #f00;
      .item-desc {
        margin-bottom: px2rem(4);
        font-size: 1rem;

        .acc-psw-i {
          @include img_icon_16x16;
          @include filter_FFF_50;
          &:hover {
            @include filter_FFF;
          }
        }
      }
      .item-detail {
        $TitleW: calc(25% * 1.5);
        display: flex;

        &.not-editable {
          opacity: 0.2;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: $TitleW;
          border: 1px solid #ffffff80;
          border-right: none;
          border-radius: px2rem(10) 0px 0px px2rem(10);
          color: #ffffff;
          padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);

          &.group-title {
            border: unset;
            border-radius: unset;
            padding: unset;
            margin-top: px2rem(8);
            // font-size: 1rem;
            font-weight: 700;;
            color: #ffe99f;
          }

          img {
            padding-right: px2rem(8);
          }

          .required {
            @include required;
          }
        }

        .content {
          $LeftGap: 0.5rem;

          display: flex;
          align-items: center;
          width: calc(100% - $TitleW);
          min-height: px2rem(50);
          border-radius: 0px px2rem(10) px2rem(10) 0px;
          // color: #ffffff;
          background-color: #ffffff;
          // background-color: #00f;

          input {
            width: 100%;
            outline: none;
            border: none;
            font-size: 1.5rem;;
            // font-weight: 300;
            padding-left: px2rem(20);
            // padding-right: px2rem(40);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // background: #f00;
          }

          div {
            width: calc(100% - $LeftGap);
          }

          .select-tree:deep {
            width: 100%;
            .select-selected {
              text-align: unset;
            }
          }

          .account,
          .password {
            $EyeW: px2rem(24);
            position: relative;
            display: flex;
            width: 100%;
            // background-color: #f00;
            input {
              width: calc(100% - $LeftGap - $EyeW);
              &.security {
                // IE & Firefox 不支援...
                -webkit-text-security: disc;
                -moz-text-security: disc;
                text-security: disc;
              }
            }
            // eye
            img {
              width: $EyeW;
              height: $EyeW;
            }
          }

          .device-model:deep {
            display: flex;
            align-items: center;
            padding-left: 1rem;
            width: 100%;
            // background-color: #f00;

            img {
              position: unset;
              @include img_icon_24x24;
              vertical-align: bottom;
              // background-color: #00f;
              &.edit {
                @include filter_39425D;
              }
            }
            .dropdown {
              .select-selected {
                padding-left: 0.5rem;
                img {
                  @include img_icon_16x16;
                }
              }
            }
          }

          .gps {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: calc($LeftGap + 1rem);
            color: initial;

            .gps-lock {
              height: px2rem(24);
              width: px2rem(24);
              cursor: pointer;

              img {
                height: px2rem(24);
                width: px2rem(24);
              }
            }
          }

          .switch {
            padding-left: 1.5rem;
          }
          .dropdown:deep {
            height: fit-content;
            .select {
              .select-selected {
                text-align: unset;
              }
            }
          }
          img {
            position: absolute;
            right: px2rem(12);
            cursor: pointer;
          }
        }
      }
      .item-error {
        font-size: 1rem;
        color: $color_F94144;

        .acc-psw-err-i {
          @include img_icon_16x16;
          @include filter_F94144_50;
          &:hover {
            @include filter_F94144;
          }
        }
      }
    }

    .item-helper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $HelperW;
      // background-color: #00f;

      .gps-helper {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          display: flex;
          padding: 0.5rem;
          border-radius: px2rem(8);
          background-color: #ffffff1a; // 白色 10%
          &:hover {
            background-color: #ffffff4d; // 白色 30%
            cursor: pointer;
          }
        }
      }
    }
  }

}

// .content > div {
//   width: 100%;
// }



// .my-select {
//   width: 100%;
// }

// input {
//   width: 100%;
//   outline: none;
//   border: none;
//   font-size: px2rem(24);
//   font-weight: 300;
//   padding-left: px2rem(20);
//   padding-right: px2rem(40);
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

.pw-tip {
  margin-top: px2rem(12);
  padding-top: px2rem(20);
  margin-bottom: px2rem(12);
  border-top: 1px solid #ffffff80;
}

.error {
  font-size: px2rem(20);
  color: #f94144;
}

.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;

  .btn {
    border: 1px solid #ffc600;
    border-radius: px2rem(8);
    padding: px2rem(10) px2rem(48);
    margin-left: px2rem(16);
    margin-right: px2rem(16);
    font-size: 1.5rem;
    // font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &.cancel {
      color: #ffc600;
      &:hover {
        background: #ffc60033;
      }
    }

    &.confirm {
      background: #ffc600;
      color: #282942;

      &:hover {
        background: #ffd133;
      }
    }
  }
}
</style>
