<template>
  <div class="wrap-device-edit">
    <DeviceEditMulti v-if="isMulti" />
    <DeviceEditNew v-else-if="isCreate" />
    <DeviceEditSingle v-else-if="deviceId" :showEdit="showEdit" />
    <!-- <div v-else>Empty</div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DeviceEditMulti from './Edit/Multi.vue'
import DeviceEditSingle from './Edit/Single.vue'
import DeviceEditNew from './Edit/New.vue'
import { console } from '@/utils/lib.js'
import { euEditMode } from '@/config/account.js'

export default {
  name: 'DeviceEdit',
  components: { DeviceEditMulti, DeviceEditSingle, DeviceEditNew },
  props: ['showEdit'],
  data() {
    return {
      isDev: console.get(),
      euEditMode,
    }
  },

  // mounted() {},
  computed: {
    ...mapState('userinfo/device', ['editMode', 'deviceId']),
    isMulti() {
      return this.editMode === euEditMode.multiSelect
    },
    isCreate() {
      return this.editMode === euEditMode.add
    }
  },
  methods: {},
  watch: {
    // editMode(val) {
    //   console.log(`Device.Edit.watch.editMode val:`, Object.keys(euEditMode)[val]);
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-device-edit {
  width: 34%;
  height: 100%;
  padding-bottom: calc(100vh * 0.01);
  background: #282942;
  color: #ffffff;
}

// .select-account {
//   height: 46%;
//   border-bottom: 1px solid #4a5c78;
// }

// .title {
//   font-size: 24px;
//   padding: 20px 20px 12px;
// }

// .account-list {
//   height: calc(100% - 80);
//   overflow: overlay;
// }

// .account {
//   display: flex;
//   align-items: center;
//   font-size: 24px;
//   font-weight: 300;
//   line-height: 35px;
//   padding: 8px 0;
//   margin-right: 14px;
//   cursor: pointer;
//   position: relative;
// }

// .account:hover {
//   background: #6e7d9333;
// }

// .account:active,
// .account.selected {
//   background: #6e7d9399;
// }

// .account.text-gray {
//   color: #ffffff33;
// }

// .account.text-red {
//   color: #f94144;
// }

// .status {
//   position: absolute;
//   left: 20px;
//   top: 7px;
// }

// .loading {
//   animation: rotation 1s infinite linear;
// }

// @keyframes rotation {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// .success {
//   width: 20px;
//   height: 20px;
// }

// .id,
// .name {
//   padding-left: 56px;
//   width: 50%;
// }

// .id img {
//   margin-right: 12px;
// }

// .remove {
//   position: absolute;
//   right: 20px;
//   width: 24px;
//   height: 24px;
//   background: #f94144;
//   border-radius: 2px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .remove img {
//   width: 12px;
//   height: 12px;
// }

// .remove:hover {
//   background: #ffffff;
// }

// .remove:hover img {
//   filter: brightness(0) saturate(100%) invert(32%) sepia(54%) saturate(3448%)
//     hue-rotate(338deg) brightness(102%) contrast(97%);
// }

// .batch-edit {
//   height: 54%;
// }

// .edit-items {
//   height: calc(100% - 120);
//   padding: 20px 20px 12px;
// }

// .header {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 12px;
// }

// .header .title {
//   font-size: 24px;
//   padding: 0;
// }

// .add-item {
//   width: 168px;
// }

// .tag-select {
//   display: flex;
//   align-items: center;
//   position: relative;
//   font-size: 24px;
//   line-height: 26px;
//   font-weight: 300;
//   cursor: pointer;
// }

// .tag-select .tag {
//   width: 25%;
//   height: 48px;
//   background: #282942;
//   border: 1px solid #ffffff80;
//   border-radius: 10px 0px 0px 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .tag-select .select {
//   width: 58%;
//   height: 48px;
//   color: #282942;
//   background: #ffffff;
//   border: 1px solid #ffffff;
//   border-radius: 0px 10px 10px 0px;
//   padding-left: 20px;
//   padding-right: 20px;
//   margin-right: 12px;
//   display: flex;
//   align-items: center;
// }

// .tag-select .select:empty::before {
//   content: attr(data-placeholder);
//   color: #9d9d9d;
// }

// .role-info {
//   cursor: pointer;
// }

// .tag-select .remove {
//   right: 0;
// }

// .tag-select-options {
//   position: absolute;
//   top: 54px;
//   width: 83%;
//   height: 200px;
//   overflow: overlay;
//   text-align: center;
//   background: #151b35;
//   border: 1px solid #4a5c78;
//   border-radius: 8px;
//   padding: 6px 0;
// }

// .tag-select-option {
//   padding: 6px 0;
//   margin-right: 12px;
//   line-height: 36px;
// }

// .tag-select-option:hover {
//   background: #6e7d9333;
// }

// .tag-select-option.selected {
//   background: #6e7d9399;
// }

// .role-permission {
//   width: 80%;
//   height: 400px;
//   position: absolute;
//   right: 0;
//   bottom: 104%;
//   background: #151b35;
//   color: #ffffff;
//   padding: 12px;
//   border: 1px solid #4a5c78;
//   border-radius: 8px;
//   overflow: overlay;
// }

// .role-permission .auth {
//   display: flex;
//   font-size: 24px;
//   line-height: 35px;
//   padding: 8px 0;
//   border-bottom: 1px solid #4a5c78;
// }

// .auth-title {
//   width: 60%;
// }

// .auth-value {
//   width: 40%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .footer {
//   height: 120px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-top: 1px solid #4a5c78;
// }

// .btn {
//   border: 1px solid #ffc600;
//   border-radius: 8px;
//   padding: 10px 48px;
//   margin-left: 16px;
//   margin-right: 16px;
//   font-size: 1.5rem;
//   font-weight: 300;
//   white-space: nowrap;
//   cursor: pointer;
// }

// .cancel {
//   color: #ffc600;
// }

// .cancel:hover {
//   background: #ffc60033;
// }

// .confirm {
//   background: #ffc600;
//   color: #282942;
// }

// .confirm:hover {
//   background: #ffd133;
// }
</style>
