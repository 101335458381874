<template>
  <div class="toggle-switch-wrap">
    <label
      v-if="direction == 'right'"
      :direction="direction"
      :for="id"
      :class="{ disabled: disabled }"
    >
      {{ content }}
    </label>
    <div class="switch-icon">
      <input
        type="checkbox"
        :id="id"
        v-model="switchValue"
        :disabled="disabled"
        @click="onSwitch"
      />
      <label
        for="toggle-switch"
        class="slider"
        :class="{ disabled: disabled }"
        @click="onSwitch"
      ></label>
    </div>
    <label
      v-if="direction == 'left'"
      :direction="direction"
      :for="id"
      :class="{ disabled: disabled }"
    >
      {{ content }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    id: {
      type: String,
      default: 'toggle-switch'
    },
    direction: {
      type: String,
      default: 'left' // 'right'|'left'
    },
    content: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      switchValue: false
    }
  },
  methods: {
    setSwitchValue(value) {
      this.switchValue = value
    },
    onSwitch() {
      if (this.disabled) return

      this.switchValue = !this.switchValue
      this.$emit('switch', this.switchValue)
    }
  },
  mounted() {
    this.setSwitchValue(this.value)
  },
  watch: {
    value() {
      this.setSwitchValue(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.toggle-switch-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #ffffff;

  label {
    font-size: px2rem(24);
    // font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &[direction='right'] {
      margin-right: 1rem;
      margin-left: 0;
    }

    &[direction='left'] {
      margin-right: 0;
      margin-left: 0.5rem;
    }

    &.disabled {
      cursor: unset;
    }
  }
  .switch-icon {
    // background-color: #00f;
    .slider {
      position: relative;
      display: inline-block;
      height: px2rem(12);
      width: px2rem(25);
      margin-right: px2rem(8);
      border: 1px solid #9d9d9d;
      border-radius: px2rem(20);
      background-color: #546267;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.75, 0.05, 0.07, 1.05);

      // dot
      &:after {
        content: '';
        position: absolute;
        left: px2rem(-10);
        top: px2rem(-4);
        display: block;
        width: px2rem(17);
        height: px2rem(17);
        border: 1px solid #9d9d9d;
        border-radius: 50%;
        box-shadow: 0px px2rem(2) px2rem(2) rgba(0, 0, 0, 0.2);
        background-color: #fefefe;
        transition: all 0.2s;
      }
    }

    input {
      display: none;
      &:disabled ~ .slider {
        // opacity: 0.2;
        cursor: unset;
      }
      &:checked ~ .slider {
        background: #ffc600; // #0096c7;
      }
      &:checked ~ .slider:after {
        left: 1rem;
      }
    }
  }
}
</style>
