<template>
  <div class="wrap-single-user-edit">
    <div class="header" v-if="true">
      <div class="group-action">
        <SelectTree
          v-if="bEdit && isDeviceKind"
          class="group-tree"
          v-model="editGroupId"
          :options="groupTreeList"
          :dataList="groupList"
          :placeholder="$t('am_search_group')"
        />
        <div v-else class="group-name">
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span>{{ getGroupName(selectedDeviceInfo?.groupId) }}</span>
        </div>
        <div v-if="showEdit" class="action">
          <div v-if="!bEdit" class="action-btn" @click="startEdit">
            {{ $t('edit') }}
          </div>
          <div class="action-btn more" @click="onOpenMoreAction">
            <i class="fa fa-ellipsis-v"></i>
          </div>
        </div>
      </div>
      <div
        v-if="showMoreAction"
        class="action-list"
        v-click-outside="onCloseMoreAction"
      >
        <div class="action-list-item" @click="openEditPwd">
          {{ $t('account_change_password') }}
        </div>
      </div>
      <div class="account-id">
        <img :src="getDeviceIcon(deviceId)" alt="" />
        <span
          :class="{
            'account-id-gray': RegExp(/disable|pause/).test(
              getDeviceIcon(deviceId)
            ),
            'account-id-red': RegExp(/locked/).test(getDeviceIcon(deviceId))
          }"
          >{{ selectedDeviceInfo.id }}</span
        >
      </div>
      <div
        v-if="!bEdit && selectedDeviceInfo.locked === euTF.true"
        class="status"
      >
        <div class="locked">{{ $t('account_locked') /*帳號已上鎖*/ }}</div>
        <div class="disabled locked">
          {{ $t('account_disabled_until') /*暫時停用至*/ }}
        </div>
        <span>{{ getTimeStr(suspend) }}</span>
      </div>
      <div v-else class="status">
        <div v-if="bEdit && !isSelf" class="active">
          <img
            v-if="editEnabled === 1"
            src="@/assets/icons/checkbox-yellow-checked.svg"
            alt=""
            @click="editDeviceEnabled(euTF.false)"
          />
          <img
            v-else
            src="@/assets/icons/checkbox-white-square.svg"
            alt=""
            @click="editDeviceEnabled(euTF.true)"
          />
          {{ $t('account_enabled') /*啟用*/ }}
        </div>
        <div v-else-if="selectedDeviceInfo.enabled === euTF.true" class="active">
          {{ $t('account_active') /*啟用中*/ }}
        </div>
        <div v-else class="active">
          {{ $t('account_not_activated') /*未啟用*/ }}
        </div>

        <div class="disabled" v-if="selectedDeviceInfo.enabled === euTF.true">
          {{ $t('account_disabled_until') /*暫時停用至*/ }}
        </div>
        <div
          v-if="selectedDeviceInfo.enabled === euTF.true"
          class="set-suspend"
        >
          <template v-if="bEdit && !isSelf">
            <Select
              class="suspend"
              v-model="selectTimeOption"
              :options="timeOptions"
              effect="dark"
              :placeholder="$t('search_device_placehold') /*請選擇*/"
            />
            <Select
              v-if="selectTimeOption === 'custom'"
              class="customize-suspend"
              v-model="suspend"
              :options="lockMinutesOptions"
              effect="dark"
              :placeholder="$t('search_device_placehold') /*請選擇*/"
              :tooltip=true
            />
            <!-- <el-date-picker
              v-if="selectTimeOption"
              popper-class="date-pick"
              class="date-pick"
              v-model="suspendedTime"
              type="datetime"
              :placeholder="$t('search_select_time')"
              unlink-panels
            ></el-date-picker> -->
          </template>
          <template v-else>
            <span>{{ $t('none') }}</span>
          </template>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="content" ref="content">
      <div
        class="item"
        v-for="(item, idx) in deviceItems"
        :key="`${item.title}_${idx}`"
        :class="{ edit: bEdit, disable: bEdit && item.ui && !item.canEdit }"
      >
        <div class="item-block">
          <div class="item-detail">
            <div class="title" :class="{ 'title-group': !item.ui }">
              <img v-if="item.ui" :src="item.icon" alt="" />
              <div>{{ $t(item.title) }}</div>
              <span v-if="bEdit && item.required" class="required">*</span>
            </div>
            <!-- 檢視 -->
            <div class="value" v-if="!bEdit && item.ui">
              <span v-if="item.key === 'public'">
                {{ getPublic(item.value) }}
                <!-- <img
                  v-if="item.value === euPublic.public"
                  src="@/assets/icons/success-check.svg"
                />
                <img v-else src="@/assets/icons/failed.svg" alt="" /> -->
              </span>
              <span
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
              >
                {{ getAccountKind(item) }}
              </span>
              <!-- 設備類型 -->
              <span
                class="device-model"
                v-else-if="item.key === 'deviceModelId'"
              >
                <img :src="getDeviceModelIcon(item)" alt="" />
                {{ getDeviceModel(item) }}
              </span>
              <!-- 參數來源 -->
              <span v-else-if="item.key === 'defaultSetting'">
                {{ getDefaultSetting(item) }}
              </span>
              <!-- 浮水印 -->
              <span v-else-if="item.key === 'watermarkEnabled'">
                {{ getWatermark(item) }}
              </span>
              <!-- 勾稽 -->
              <span v-else-if="item.key === 'linkedMode'">
                {{ getLinkMode(item)?.label }}
              </span>
              <span
                class="link-mode-group"
                v-else-if="
                  item.key === 'linkedUserId' &&
                  item.title?.indexOf('group') >= 0
                "
              >
                <div v-if="selectedDeviceInfo.linkedUserId">
                  <img src="@/assets/icons/group-icon.svg" alt="" />
                  <span>{{ getLinkModeGroup(item) }}</span>
                </div>
              </span>
              <span v-else-if="item.key === 'linkedUserId'">
                {{ getLinkModeUser(item) }}
              </span>
              <!-- <span v-else-if="item.key === 'role'">
                {{ getUserRole(item)?.label }}
              </span> -->
              <span v-else-if="item.key === 'gps'" class="gps">
                <div>{{ GPS }}</div>
                <div class="gps-lock">
                  <img
                    v-if="editGps.lock"
                    src="@/assets/icons/lock.svg"
                    alt=""
                  />
                  <img v-else src="@/assets/icons/unlocked.svg" alt="" />
                </div>
              </span>
              <span v-else-if="item.key === 'lprKeepDay'">
                {{
                  keepOptions.find(({ value }) => value === item.value)?.label
                }}
              </span>
              <span v-else-if="item.key === 'video.keepDay'">
                {{
                  keepOptions.find(({ value }) => value === item.value)?.label
                }}
              </span>
              <span v-else-if="item.key === 'language'">
                {{ item.value ? $t(`lang_${item.value}`) : '' }}
              </span>
              <span
                v-else-if="item.key === 'kind' && item.title === 'account_from'"
              >
                {{
                  item.value === euKind.sso
                    ? $t('account_source_sso')
                    : $t('account_source_self')
                }}
              </span>
              <span
                v-else-if="
                  item.key === 'lastLogin' ||
                  item.key === 'updatedTime' ||
                  item.key === 'registerTime'
                "
              >
                {{ getTimeStr(item.value) }}
              </span>
              <span class="text" v-else>{{ item.value }}</span>
            </div>
            <!-- 編輯 -->
            <div
              class="value"
              v-if="bEdit && item.ui"
              :ui="item.ui"
              :class="{ editable: bEdit && item.canEdit }"
            >
              <!-- 公開狀態 -->
              <Select
                v-if="item.key === 'public'"
                class="dropdown"
                v-model="item.editValue"
                :options="publicOptions"
              />
              <!-- 帳號類型 -->
              <Select
                v-else-if="item.key === 'kind' && item.title === 'account_type'"
                class="dropdown"
                v-model="item.editValue"
                :options="deviceKindOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 設備類型 -->
              <div
                class="device-model"
                v-else-if="item.key === 'deviceModelId'"
              >
                <img class="edit" :src="getDeviceModelIcon(item)" alt="" />
                <Select
                  class="dropdown"
                  v-model="item.editValue"
                  :classifySelect="true"
                  :options="deviceModelOptions"
                  :placeholder="$t('search_device_placehold') /*請選擇*/"
                />
              </div>
              <!-- 參數設定 -->
              <Select
                v-else-if="item.key === 'defaultSetting'"
                class="dropdown"
                v-model="item.editValue"
                :options="defaultSettingOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 浮水印 -->
              <Select
                v-else-if="item.key === 'watermarkEnabled'"
                class="dropdown"
                v-model="item.editValue"
                :options="watermarkOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <!-- 勾稽 -->
              <Select
                v-else-if="item.key === 'linkedMode'"
                v-model="item.editValue"
                :options="linkedModeOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <span
                class="link-mode-group"
                v-else-if="
                  item.key === 'linkedUserId' &&
                  item.title?.indexOf('group') >= 0
                "
              >
                <div v-if="selectedDeviceInfo.linkedUserId">
                  <img src="@/assets/icons/group-icon.svg" alt="" />
                  <span>{{ getLinkModeGroup(item) }}</span>
                </div>
              </span>
              <span v-else-if="item.key === 'linkedUserId'">
                {{ getLinkModeUser(item) }}
              </span>
              <!-- GPS -->
              <span v-else-if="item.key === 'gps'" class="gps">
                <div>{{ GPS }}</div>
                <div class="gps-lock edit" @click="onLockGps">
                  <img
                    v-if="editGps.lock"
                    src="@/assets/icons/lock-gray.svg"
                    alt=""
                  />
                  <img v-else src="@/assets/icons/unlocked-gray.svg" alt="" />
                </div>
              </span>
              <!-- 事件保留/影片保留 -->
              <Select
                v-else-if="item.key === 'video.keepDay'/* || item.key === 'lprKeepDay'*/"
                v-model="item.editValue"
                :options="keepOptions"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
              <input
                v-else-if="item.ui === 'text' && item.canEdit"
                class="edit-input"
                type="text"
                v-model="item.editValue"
              />
              <span v-else>{{ item.editValue }}</span>
            </div>
          </div>
          <div class="item-error">
            <span v-if="item.key === 'deviceModelId' && errorDeviceModel">
              {{ $t(errorDeviceModel) }}
            </span>
          </div>
        </div>
        <div class="item-helper">
          <div class="gps-helper" v-if="item.helper && item.key === 'gps'">
            <div
              class="icon"
              @click="openMap"
              v-if="bEdit ? item.editValue : item.value"
            >
              <img src="@/assets/icons/account-map.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="footer" v-if="bEdit">
      <div class="btn cancel" @click="handleCancel">{{ $t('cancel') }}</div>
      <div class="btn confirm" @click="onEditDevice">
        {{ $t('confirm') }}
      </div>
    </div>
    <PortalEditPsw
      v-if="showEditPwdPortal"
      @close="closePortal"
      @confirm="changePassword"
    />
    <PortalMap
      v-if="showMap"
      :gps="editGps"
      :bEdit="bEdit"
      @close="closeMap"
      @confirm="confirmMap"
    />
    <PortalCreateConfirm
      v-if="msgPortal.show"
      :title="$t('account_edit_title')"
      :content="$t('account_edit_device_model_hint')"
      :action="false"
      @close="() => (msgPortal.show = false)"
      @confirm="onConfirmMsgPotral"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// import moment from 'moment-timezone'
import vClickOutside from 'v-click-outside'
import Select from '../../tools/Select.vue'
import SelectTree from '../../tools/SelectTree.vue'
// import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import PortalEditPsw from '../../portal/PortalEditPsw.vue'
import PortalMap from '../../portal/ProtalMap.vue'
import PortalCreateConfirm from '../../portal/PortalCreateConfirm.vue'
import { apiEditUser, apiLockUser, apiUnlockUser } from '@/api/index.js'
import {
  euAccountKind as euKind,
  deviceKindOptions,
  keepOptions,
  lockMinutesOptions,
  euTF
} from '@/utils/global.js'
import { formatTimeStr } from '@/utils/lib'
import {
  euEditMode,
  euPublic,
  publicOptions,
  euLinkMode,
  linkedModeOptions,
  defaultSettingOptions,
  watermarkOptions,
  getGpsStr,
  deviceItems as defDeviceItems
} from '@/config/account.js'
import { genDeviceModelOptions } from '@/components/AccountManagement/Device.vue'

export default {
  name: 'DeviceEditSingle',
  components: {
    Select,
    SelectTree,
    // ToggleSwitch,
    PortalEditPsw,
    PortalMap,
    PortalCreateConfirm
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      euTF,
      euKind,
      euPublic,
      euLinkMode,
      bEdit: false,
      showEditPwdPortal: false,
      deviceItems: JSON.parse(JSON.stringify(defDeviceItems)),
      editGroupId: '',
      editEnabled: false,
      selectTimeOption: null,
      // showRolePermission: false,
      showDeviceType: false,
      showMap: false,
      keepOptions,
      timeOptions: [
        { value: null, label: this.$t('none') },
        { value: '15', label: this.$t('account_15min_later') },
        { value: '30', label: this.$t('account_30min_later') },
        { value: '60', label: this.$t('account_1hr_later') },
        { value: 'custom', label: this.$t('custom') }
      ],
      lockMinutesOptions,
      editGps: {},
      suspend: '',
      showMoreAction: false,

      // errorMessage
      errorDeviceModel: null, // 設備類型錯誤

      // PortalMessage
      msgPortal: {
        show: false,
        param: null
      }
    }
  },
  computed: {
    ...mapGetters(['timezone', 'getUserInfo']),
    ...mapState('userinfo', [
      'deviceModels',
      'deviceInfoList',
      'groupList',
      'roleList',
      'groupTreeList',
      'deviceList'
    ]),
    ...mapState('userinfo/device', ['editMode', 'deviceId']),
    ...mapGetters('userinfo', ['getDeviceIcon']),
    ...mapGetters('userinfo/device', ['selectedDeviceInfo']),
    isSelf() {
      return this.getUserInfo.id === this.selectedDeviceInfo.id
    },
    isDeviceKind() {
      const { kind } = this.selectedDeviceInfo
      return kind === euKind.default || kind === euKind.device
    },
    deviceKindOptions() {
      return this.formatOptions(deviceKindOptions)
    },
    deviceModelOptions() {
      if (this.deviceModels) {
        return genDeviceModelOptions(this.deviceModels)
      }
      return []
    },
    publicOptions() {
      return this.formatOptions(publicOptions)
    },
    defaultSettingOptions() {
      return this.formatOptions(defaultSettingOptions)
    },
    watermarkOptions() {
      return this.formatOptions(watermarkOptions)
    },
    linkedModeOptions() {
      return this.formatOptions(linkedModeOptions)
    },
    GPS() {
      const { latitude, longitude } = this.editGps

      return getGpsStr(latitude, longitude)
    },
  },
  methods: {
    ...mapMutations('userinfo', ['updateAccountPortal']),
    ...mapMutations('userinfo/device', ['updateDeviceId', 'updateEditMode']),
    ...mapActions('userinfo', ['getDeviceInfoList']),
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    getTimeStr(time) {
      return formatTimeStr(time)
    },
    getDeviceItemIdxByKey(key) {
      const idx = this.deviceItems.findIndex((item) => item.key === key)
      return idx
    },
    getPublic(publicSt) {
      const option = this.publicOptions.find(({ value }) => value === publicSt)
      return option?.label
    },
    getAccountKind(item) {
      const currKind = isNaN(item.value) ? 0 : Number(item.value)
      const option = this.deviceKindOptions.find(
        (kind) => kind.value === currKind
      )
      return option.label
    },
    getDeviceModel(item) {
      const option = this.deviceModelOptions.find(
        ({ value, classify }) => !classify && value === item.value
      )
      // return option ? option.label : this.$t(`account_device_model_0`)
      return option?.label
    },
    getDeviceModelIcon(item) {
      const currValue = this.bEdit ? item.editValue : item.value
      const option = this.deviceModelOptions.find(
        ({ value, classify }) => !classify && value === currValue
      )
      return option ? require(`@/assets/icons/${option.icon}`) : null
    },
    getDefaultSetting(item) {
      const option = this.defaultSettingOptions.find(
        ({ value }) => value === item.value
      )
      return option?.label
    },
    getWatermark(item) {
      const option = this.watermarkOptions.find(
        ({ value }) => value === item.value
      )
      return option?.label
    },
    getLinkMode(item) {
      return this.linkedModeOptions.find(({ value }) => value === item.value)
    },
    getLinkModeGroup(item) {
      if (this.selectedDeviceInfo.linkedUserId) return ''

      const accId = `${item.value}`
      const device = this.deviceInfoList.find(({ id }) => id === accId)

      if (device) {
        const { groupId } = device
        const group = this.groupList.find(({ id }) => id === groupId)

        if (group) {
          return group.name
        }
      }

      return ''
    },
    getLinkModeUser(item) {
      // if (item.value) return '' 等同下行
      if (this.selectedDeviceInfo.linkedUserId) return ''

      const accId = `${item.value}`
      const device = this.deviceInfoList.find(({ id }) => id === accId)

      if (device) {
        return `${device.info.name}(${device.id})`
      }

      return ''
    },
    getDeviceItemsValue() {
      if (!this.selectedDeviceInfo) return

      try {
        for (let i = 0; i < this.deviceItems.length; i++) {
          const { key } = this.deviceItems[i]

          if (key) {
            let keys = []
            if (typeof key === 'string') {
              keys = key.split('.')
            } else if (typeof key === 'object') {
              keys = Object.keys(key)
            }

            let value = null
            switch (key) {
              case 'info.name':
              case 'video.title':
              case 'video.keepDay':
                value = this.selectedDeviceInfo[keys[0]][keys[1]]
                break
              default:
                value = this.selectedDeviceInfo[keys[0]]
                break
            }

            this.deviceItems[i].value = value
            // console.log(`[getDeviceItemsValue] key, value:`, key, value)
          }
        }

        this.editGps = { ...this.selectedDeviceInfo.gps }
        this.editEnabled = this.selectedDeviceInfo.enabled
        if (!this.selectedDeviceInfo.suspendedTime) {
          this.selectTimeOption = null
          this.suspend = ''
        } else {
          this.selectTimeOption = 'custom'
          this.suspend = this.selectedDeviceInfo.suspendedTime
        }
        this.selectTimeOption = this.selectedDeviceInfo.suspendedTime
      } catch (err) {
        console.err(`[getDeviceItemsValue] err:`, err)
      }
    },
    // Enable Account
    async editDeviceEnabled(enabled) {
      const data = {
        id: this.selectedDeviceInfo.id,
        enabled: enabled
      }
      try {
        const res = await apiEditUser(data)
        if (res.status === 200) {
          this.editEnabled = enabled
          await this.getDeviceInfoList()
          this.bEdit = false
        }
      } catch (err) {
        console.error(`[editDeviceEnabled] err:`, err)
      } finally {
        this.bEdit = false
        this.updateEditMode(euEditMode.editView)
      }
    },
    // Edit btn
    startEdit() {
      // if(!this.selectedDeviceInfo) { return }
      try {
        this.editGroupId = this.selectedDeviceInfo.groupId

        for (let i = 0; i < this.deviceItems.length; i++) {
          const { key } = this.deviceItems[i]

          if (key) {
            let keys = []
            if (typeof key === 'string') {
              keys = key.split('.')
            } else if (typeof key === 'object') {
              keys = Object.keys(key)
            }

            let editValue = null
            switch (key) {
              case 'info.name':
              case 'video.title':
              case 'video.keepDay':
                editValue = this.selectedDeviceInfo[keys[0]][keys[1]]
                break
              case 'gps': {
                const { latitude, longitude } = this.selectedDeviceInfo.gps
                editValue = getGpsStr(latitude, longitude)
                break
              }
              default:
                editValue = this.selectedDeviceInfo[keys[0]]
                break
            }

            this.deviceItems[i].editValue = editValue

            // console.log(`[startEdit] key, editValue:`, key, editValue)
          }
        }

        this.bEdit = true
        this.updateEditMode(euEditMode.editing)
      } catch (err) {
        console.error(`[startEdit] err:`, err)
      }
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)

      return group ? group.name : ''
    },
    isGpsLock(/*index*/) {
      return (
        // index === items.gps &&
        this.selectedDeviceInfo &&
        this.selectedDeviceInfo.gps.lock === euTF.true
      )
    },
    // handleRoleInfo() {
    //   this.showRolePermission = !this.showRolePermission
    // },
    handleDeviceInfo() {
      this.showDeviceType = !this.showDeviceType
    },
    onLockGps() {
      this.editGps.lock =
        this.editGps.lock === euTF.true ? euTF.false : euTF.true
    },
    openMap() {
      this.updateAccountPortal(`account-map`)
      this.showMap = true
    },
    closeMap() {
      this.showMap = false
    },
    confirmMap(editGps) {
      this.closeMap()
      this.editGps = { ...editGps }

      const gpsIdx = this.deviceItems.findIndex(({ key }) => key === 'gps')
      this.deviceItems[gpsIdx].editValue = getGpsStr(
        editGps.latitude,
        editGps.longitude
      )
    },
    onOpenMoreAction() {
      this.showMoreAction = !this.showMoreAction
    },
    onCloseMoreAction() {
      this.showMoreAction = false
    },
    // Cancel btn
    handleCancel() {
      this.bEdit = false
      this.getDeviceItemsValue()

      this.updateEditMode(euEditMode.editView)
      this.cleanError()
    },
    async handleLock(param) {
      // console.log(`[handleLock] param:`, param)
      try {
        const res = await apiLockUser(param)
        if (res.status === 200) {
          await this.getDeviceInfoList()
          this.getDeviceItemsValue()

          // console.log(`selectedDeviceInfo:`, this.selectedDeviceInfo)
          this.$notify({
            title: `${this.$t('account_lock_success')} ${
              this.selectedDeviceInfo.info.name
            }`,
            type: 'success',
          })
        }
      } catch (err) {
        this.$notify({
          title: `${this.$t('account_lock_failed')} ${
            this.selectedDeviceInfo.info.name
          }`,
          message: err.response.data.message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode(euEditMode.editView)
      }
    },
    async handleUnlock(param) {
      try {
        const res = await apiUnlockUser(param.id)
        if (res.status === 200) {
          await this.getDeviceInfoList()
          this.getDeviceItemsValue()

          // console.log(`selectedDeviceInfo:`, this.selectedDeviceInfo)
          this.$notify({
            title: `${this.$t('account_unlock_success')} ${
              this.selectedDeviceInfo.info.name
            }`,
            type: 'success',
          })
        }
      } catch (err) {
        this.$notify({
          title: `${this.$t('account_unlock_failed')} ${
            this.selectedDeviceInfo.info.name
          }`,
          message: err.response.data.message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode(euEditMode.editView)
      }
    },
    async handleEditDevice(param) {
      try {
        const res = await apiEditUser(param)
        if (res.status !== 200) throw res
      
        await this.getDeviceInfoList()
        this.getDeviceItemsValue()

        this.$notify({
          title: `${this.$t('account_edit_success')} ${
            this.selectedDeviceInfo.info.name
          }`,
          type: 'success',
        })
      } catch (err) {
        const { message } = err.data
        this.$notify({
          title: `${this.$t('account_edit_failed')} ${
            this.selectedDeviceInfo.info.name
          }`,
          message: message,
          type: 'error',
        })
      } finally {
        this.bEdit = false
        this.updateEditMode(euEditMode.editView)
      }
    },
    checkDeviceModel() {
      const deviceModelIdIdx = this.getDeviceItemIdxByKey('deviceModelId')
      const deviceModelId = this.deviceItems[deviceModelIdIdx].editValue

      return !deviceModelId ? 'account_required_info' : ''
    },
    checkDeviceModelDiff() {
      const deviceModelIdIdx = this.getDeviceItemIdxByKey('deviceModelId')
      const deviceModelId = this.deviceItems[deviceModelIdIdx].editValue
      const oldDeviceModelId = this.deviceItems[deviceModelIdIdx].value

      // console.log('[checkDeviceModelDiff]\ndeviceModelId:', deviceModelId, '\noldDeviceModelId:', oldDeviceModelId)
      return deviceModelId !== oldDeviceModelId
    },
    async apiEditDevice(param) {
      if (param.suspend) {
        await this.handleLock(param)

        if (Object.keys(param).length >= 3) {
          await this.handleEditDevice(param)
        }
      } else {
        if (this.selectedDeviceInfo.suspendedTime) {
          await this.handleUnlock(param)
        }

        if (Object.keys(param).length >= 2) {
          await this.handleEditDevice(param)
        }
      }
    },
    async onEditDevice() {
      let param = {}

      // 檢查 設備類型 是否有填
      this.errorDeviceModel = this.checkDeviceModel()
      if (this.errorDeviceModel) {
        // 處理 scrollbar 捲動位置(只抓了大概的位置)
        this.$refs.content.scrollTop = this.$refs.content.offsetHeight / 2
        return
      }

      param.id = this.selectedDeviceInfo.id

      // 群組
      if (this.editGroupId !== this.selectedDeviceInfo.groupId)
        param.groupId = `${this.editGroupId}`

      // 帳號名稱
      const nameIdx = this.getDeviceItemIdxByKey('info.name')
      if (
        this.deviceItems[nameIdx].editValue !==
        this.selectedDeviceInfo.info.name
      )
        param.name = this.deviceItems[nameIdx].editValue

      // 影片標題
      const videoTitleIdx = this.getDeviceItemIdxByKey('video.title')
      if (
        this.deviceItems[videoTitleIdx].editValue !==
        this.selectedDeviceInfo?.video?.title
      )
        param.videoTitle = this.deviceItems[videoTitleIdx].editValue

      // 公開私有
      const publicIdx = this.getDeviceItemIdxByKey('public')
      if (
        this.deviceItems[publicIdx].editValue !== this.selectedDeviceInfo.public
      )
        param.public = this.deviceItems[publicIdx].editValue

      // 帳號類型
      const kindIdx = this.getDeviceItemIdxByKey('kind')
      if (this.deviceItems[kindIdx].editValue !== this.selectedDeviceInfo?.kind)
        param.kind = this.deviceItems[kindIdx].editValue

      // 設備類型
      const deviceModelIdIdx = this.getDeviceItemIdxByKey('deviceModelId')
      if (
        this.deviceItems[deviceModelIdIdx].editValue !==
        this.selectedDeviceInfo?.deviceModelIdx
      )
        param.deviceModelId = this.deviceItems[deviceModelIdIdx].editValue

      // 參數來源
      const defaultSettingIdx = this.getDeviceItemIdxByKey('defaultSetting')
      if (
        this.deviceItems[defaultSettingIdx].editValue !==
        this.selectedDeviceInfo?.defaultSetting
      )
        param.defaultSetting = this.deviceItems[defaultSettingIdx].editValue

      // 浮水印
      const watermarkIdx = this.getDeviceItemIdxByKey('watermarkEnabled')
      if (
        this.deviceItems[watermarkIdx].editValue !==
        this.selectedDeviceInfo?.watermarkEnabled
      )
        param.watermarkEnabled = this.deviceItems[watermarkIdx].editValue

      // 勾稽模式
      const linkedModeIdx = this.getDeviceItemIdxByKey('linkedMode')
      if (
        this.deviceItems[linkedModeIdx].editValue !==
        this.selectedDeviceInfo?.linkedModeIdx
      )
        param.linkedMode = this.deviceItems[linkedModeIdx].editValue

      // GPS
      if (
        JSON.stringify(this.editGps) !==
        JSON.stringify(this.selectedDeviceInfo.gps)
      ) {
        param.lock = this.editGps.lock
        param.latitude = this.editGps.latitude
        param.longitude = this.editGps.longitude
      }

      // // 事件保留
      // const lprKeepDayIdx = this.getDeviceItemIdxByKey('lprKeepDay')
      // if (
      //   this.deviceItems[lprKeepDayIdx].editValue !==
      //   this.selectedDeviceInfo?.lprKeepDay
      // )
      //   param.lprKeepDay = this.deviceItems[lprKeepDayIdx].editValue

      // 保留時間
      const keepDayIdx = this.getDeviceItemIdxByKey('video.keepDay')
      if (
        this.deviceItems[keepDayIdx].editValue !==
        this.selectedDeviceInfo.video.keepDay
      )
        param.keepDay = this.deviceItems[keepDayIdx].editValue

      // 暫停
      if (this.suspend) {
        param.suspend = parseInt(this.suspend, 10)
      }

      if (this.checkDeviceModelDiff()) {
        this.updateAccountPortal('account-create-confirm')
        const msgPortal = this.msgPortal
        this.msgPortal = { ...msgPortal, ...{ param, show: true } }
        return
      }

      // Call API ---
      await this.apiEditDevice(param)
    },
    openEditPwd() {
      this.updateAccountPortal('portal-edit-psw')
      this.showEditPwdPortal = true
    },
    closePortal() {
      this.showEditPwdPortal = false
    },
    async changePassword(newPassword) {
      const data = {
        id: this.selectedDeviceInfo.id,
        password: newPassword
      }

      try {
        const res = await apiEditUser(data)

        if (res.status !== 200) {
          throw res
        }
        this.$notify({
          title: this.$t('account_edit_password'),
          message: this.$t('account_pw_success'),
          type: 'success',
        })
      } catch (err) {
        const { message } = err.data
        this.$notify({
          title: this.$t('account_edit_password'),
          message: message, // this.$t('account_pw_failed'),
          type: 'error',
        })
      } finally {
        this.showEditPwdPortal = false
      }
    },
    // onInput(event) {},
    onSwitchPublic(value) {
      const publicIdx = this.getDeviceItemIdxByKey('public')
      this.deviceItems[publicIdx].editValue = value
    },
    cleanError() {
      this.errorDeviceModel = null
    },
    async onConfirmMsgPotral() {
      await this.apiEditDevice(this.msgPortal.param)
      this.msgPortal.show = false
    }
  },
  watch: {
    editMode() {
      // console.log(`[device.watch.editMode] editMode:`, Object.keys(euEditMode)[this.editMode]);
      this.bEdit = this.editMode === euEditMode.editing ? true : false
      this.cleanError()
    },
    deviceId() {
      this.getDeviceItemsValue()
      if (this.bEdit) {
        this.startEdit()
      }
    },
    selectTimeOption(nVal) {
      if (this.selectTimeOption === null) {
        this.suspend = ''
      } else if (this.selectTimeOption === 'custom') {
        this.suspend = '1'
      } else {
        this.suspend = nVal
      }
    },
    // deviceItems: {
    //   deep: true,
    //   handler(nVal /*, oVal*/) {
    //     console.log(`Watch.deviceItems:`, JSON.stringify(nVal))
    //   }
    // },
  },
  mounted() {
    this.getDeviceItemsValue()
  },
  destroyed() {
    this.updateDeviceId('')
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-single-user-edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // line-height: px2rem(36); // 2.25rem;
  // font-size: px2rem(24); // 1.5rem;
  font-size: px2rem(20);
  font-weight: 300;
  color: #ffffff;
  .header {
    padding: px2rem(20) px2rem(8) 0 px2rem(20);
    .group-action:deep {
      display: flex;
      justify-content: space-between;
      margin-bottom: px2rem(6);

      .group-tree {
        width: 60%;

        .select-selected {
          text-align: unset;
        }
      }

      .group-name {
        font-weight: 500;
        color: #ffc600;

        img {
          width: px2rem(24);
          height: px2rem(24);
          vertical-align: text-bottom;
        }
        span {
          margin-left: 0.5rem;
        }
      }

      .action {
        // position: relative;
        display: flex;
        justify-content: flex-end;

        .action-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: px2rem(8); // 0.5rem;
          padding: px2rem(6) px2rem(24);
          border-radius: px2rem(8);
          line-height: px2rem(35);
          background: #4a5c7880; // 50%
          cursor: pointer;

          &.more {
            width: px2rem(47); // 2.9375rem;
            height: px2rem(47); // 2.9375rem;
          }

          &:first-child {
            margin-left: 0; // px2rem(8);
          }
          &:hover {
            background: #4a5c78;
          }

          &.disable-edit {
            color: rgba(255, 255, 255, 0.2);
            &:hover {
              background: #4a5c7880;
              cursor: unset;
            }
          }
        }
      }
    }

    .action-list {
      position: absolute;
      right: 0;
      margin-right: px2rem(8); // 0.5rem;
      padding: px2rem(4) 0; // 0.25rem 0;
      border-radius: px2rem(8); // 0.5rem;
      border: 1px solid #4a5c78;
      background-color: #151b35;
      z-index: 1;

      .action-list-item {
        display: flex;
        align-items: center;
        padding: 0 px2rem(30); // 0 1.875rem;
        height: px2rem(47); // 2.9375rem;
        &:hover {
          background-color: #4a5c78;
          cursor: pointer;
        }

        &.disabled {
          opacity: 0.2;
          &:hover {
            background-color: unset;
            cursor: unset; // not-allowed;
          }
        }
      }
    }

    .account-id {
      font-size: px2rem(32);
      // font-weight: 300;
      // line-height: px2rem(32);
      margin-bottom: px2rem(6);

      img {
        @include img_icon_24x24;
      }

      &.account-id-gray {
        opacity: 0.2;
      }
      &.account-id-red {
        color: #f94144;
      }

      img {
        margin-right: px2rem(12);
      }
    }

    .status {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      img {
        vertical-align: baseline;
        margin-right: px2rem(8);
        position: relative;
        top: 2px;
        cursor: pointer;
      }

      .active {
        margin-right: px2rem(32);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: px2rem(30);
          background: #ffffff;
          top: px2rem(5);
          right: px2rem(-16);
        }
        .locked {
          // color: #f94144;
        }

        .disabled {
          display: flex;
          align-items: center;
          position: relative;
          &.locked {
            color: #fff;
            padding-left: 2rem;

            &::before {
              left: 1rem;
            }
          }
          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: px2rem(30);
            background: #ffffff;
            top: px2rem(5);
            left: px2rem(-16);
          }
        }

        .set-suspend {
          display: flex;
          align-items: center;

          .suspend {
            width: px2rem(124);
            margin-left: px2rem(12);
            height: px2rem(36);
            line-height: px2rem(36);
            font-size: px2rem(15);
            margin-right: px2rem(8);
          }

          .customize-suspend:deep {
            width: px2rem(124);
            // margin-left: px2rem(-12);
            height: px2rem(36);
            line-height: px2rem(36);
            font-size: px2rem(15);
            // margin-right: px2rem(8);

            .select-options {
              right: 0;
              left: unset;
            }
          }
        }

        span {
          margin-left: px2rem(12);
        }
      }
    }
  }
}
// ::v-deep.customize-suspend .select-options {
//   right: 0;
//   left: unset;
// }

// ----------
.content {
  flex: 1;
  padding: px2rem(20) px2rem(2) px2rem(20) px2rem(20);
  overflow: overlay;
  position: relative;

  .item {
    $HelperW: px2rem(48);

    display: flex;
    margin-bottom: px2rem(12);
    color: #fff;

    &.edit,
    &.disable {
      // color: #000;
    }

    .item-block {
      display: flex;
      flex-direction: column;
      width: calc(100% - $HelperW);
      // background-color: #f00;
      .item-detail {
        $TitleW: calc(25% * 1.5);

        display: flex;

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: $TitleW;
          border: 1px solid #ffffff80;
          border-right: none;
          border-radius: px2rem(10) 0 0 px2rem(10);
          color: #ffffff;
          padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);
          // background-color: #282942;

          .required {
            @include required;
          }

          &.title-group {
            border: unset;
            padding: unset;
            width: 100%;
            font-size: px2rem(20);
            color: #ffe99f;
          }

          img {
            padding-right: px2rem(8);
          }
        }

        .value {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - $TitleW);
          min-height: px2rem(50);
          padding: px2rem(6) px2rem(20) px2rem(6) px2rem(20);
          border: 1px solid #ffffff80;
          border-radius: 0 px2rem(10) px2rem(10) 0;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          color: currentColor;
          background-color: #4a5c7880; // 50%

          .text {
            overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }

          .device-model {
            display: flex;
            align-items: center;
            width: 100%;

            img {
              margin-top: 0.5rem;
              margin-right: 0.5rem;
              @include img_icon_24x24;
              vertical-align: bottom;
              &.edit {
                @include filter_39425D;
              }
            }
          }

          &[ui='dropdown']:deep {
            overflow: visible;
            padding-right: px2rem(10);

            .select-selected {
              padding-left: unset;
              text-align: unset;
            }
          }
          // &[bEdit=true] {
          //   background: #fff;
          // }
          .switch {
            display: flex;
            align-items: center;
            height: px2rem(20);
            width: px2rem(20);
            // img {
            //   height: 100%;
            //   width: 100%;
            // }
          }

          .link-mode-group {
            div {
              display: flex;
              align-items: center;
              font-weight: 500;
              color: #ffc600;

              img {
                padding-right: px2rem(8);
              }
            }
          }

          &.editable {
            background: #ffffff;
            color: #282942;
            outline: none;
          }

          .gps {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // background-color: #f00;

            .gps-lock {
              &.edit {
                cursor: pointer;
              }
            }
            img {
              height: px2rem(24);
              width: px2rem(24);
            }
          }
        }
      }

      .item-error {
        font-size: px2rem(20);
        color: #f94144;
      }
    }

    .item-helper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $HelperW;
      // background-color: #00f;

      .gps-helper {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          display: flex;
          padding: 0.5rem;
          border-radius: px2rem(8);
          background-color: #ffffff1a; // 白色 10%
          &:hover {
            background-color: #ffffff4d; // 白色 30%
            cursor: pointer;
          }
        }
      }
    }
  }
}

.gps-edit {
  display: flex;
}

.lock-img,
.unlock-img {
  cursor: pointer;
}

.lock-img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(2076%)
    hue-rotate(147deg) brightness(83%) contrast(80%);
}

.unlock-img {
  filter: brightness(0) saturate(100%) invert(76%) sepia(9%) saturate(14%)
    hue-rotate(4deg) brightness(82%) contrast(88%);
}

// .keep-select:deep {
//   position: relative;
//   width: 100%;
//   height: px2rem(36);
//   top: px2rem(-6);

//   .select-selected {
//     text-align: unset;
//   }
// }

.edit-input {
  width: 100%;
  outline: none;
  border: none;
  font-size: px2rem(24);
  font-weight: 300;
  // text-align: center;
}
.edit-input[name='gps']:disabled {
  background-color: #ffffff;
}

.lock {
  color: #ffffff80;
}

.map {
  margin-left: px2rem(16);
  cursor: pointer;
}

.auth-title {
  width: 60%;
}

.auth-value {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable {
  opacity: 0.3;
}

// ---
.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;

  .btn {
    border: 1px solid #ffc600;
    border-radius: px2rem(8);
    padding: px2rem(10) px2rem(48);
    margin-left: px2rem(16);
    margin-right: px2rem(16);
    font-size: px2rem(24);
    font-weight: 300;
    white-space: nowrap;
    cursor: pointer;

    &.cancel {
      color: #ffc600;
      &:hover {
        background: #ffc60033;
      }
    }

    &.confirm {
      background: #ffc600;
      color: #282942;
      &:hover {
        background: #ffd133;
      }
    }
  }
}

// @media screen and (min-width: 1280px) {
//   .wrap-single-user-edit,
//   .btn {
//     font-size: 24px;
//   }
//   .account-id {
//     font-size: 32px;
//   }
//   .suspend,
//   .customize-suspend {
//     font-size: 20px;
//   }

//   .edit-input {
//     font-size: 24px;
//   }
// }
</style>
