<template>
  <div class="wrap-user-table">
    <div class="user-table">
      <div class="header">
        <div class="row">
          <div
            class="col"
            :class="{
              eraser: isMultiMode,
              disabled: this.selectedUserList.length <= 0
            }"
            @click="clearSelectedUserList()"
          >
            <img src="@/assets/icons/erase-white.svg" alt="" />
          </div>
          <div class="col">{{ $t('account_title') }}</div>
          <div class="col">{{ $t('account_name') }}</div>
          <div class="col">{{ $t('account_role') }}</div>
          <div class="col">&nbsp;</div>
        </div>
      </div>
      <div class="user-content">
        <!-- <template v-for="group in subGroupList"> -->
        <UserTableList
          v-for="group in subGroupList"
          :showEdit="showEdit"
          :key="group.id"
          :userList="getFilterGroupUserList(group.id)"
          :groupId="group.id"
        />
        <!-- </template> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getNodeFamily } from '@/utils/lib.js'
import { all } from '@/config/account.js'
import UserTableList from './UserTable/UserList.vue'

export default {
  name: 'UserTable',
  components: { UserTableList },
  props: ['showEdit'],
  data() {
    return {
      // filterUserList: []
    }
  },
  computed: {
    ...mapState('userinfo', [
      'editMode',
      'groupId',
      'groupTreeList',
      // 'groupList',
      'groupInfoList',
      'filterText',
      'filterRoles',
      'filterUseStates',
      'filterPublic',
      'userInfoList',
      'selectedUserList',
      'selectedGroupList',
      'multiSelectOnly'
    ]),
    isMultiMode() {
      return this.editMode === 'multiSelect' /*&& this.multiSelectOnly*/
    },
    filterUserList() {
      // console.time('filterUserList time')
      let needResult = true
      this.updateFilterResult(-1)

      // 過濾 使用者種類(非設備帳號)
      let userList = this.userInfoList.filter((user) => user.kind !== 2)

      // 過濾勾選群組
      if (this.selectedGroupList?.length > 0) {
        // needResult = true
        userList = userList.filter((user) => {
          return this.selectedGroupList.find((grpId) => grpId === user.groupId)
        })
      } // 過濾單選群組
      else if (this.groupId) {
        userList = userList.filter((user) => {
          return this.groupId === user.groupId
        })
      }
      // console.log(`[filterUserList] #1 userList:`, userList.length)

      // 過濾帳號或帳號名稱
      if (this.filterText) {
        needResult = true
        const text = this.filterText.toLowerCase()
        userList = userList.filter(
          (user) =>
            user.id.toLowerCase().includes(text) ||
            user.info.name.toLowerCase().includes(text)
        )
      }
      // console.log(`[filterUserList] #2 userList:`, userList.length)

      // 過濾角色
      if (this.filterRoles.length > 0) {
        needResult = true
        userList = userList.filter((user) =>
          this.filterRoles.includes(user.roleId)
        )
      }
      // console.log(`[filterUserList] #3 userList:`, userList.length)

      // 過濾帳號狀態
      if (this.filterUseStates.length > 0) {
        needResult = true
        const stateFilter = {
          enabled: this.filterUseStates.includes('enabled') ? 1 : null,
          disable: this.filterUseStates.includes('disable') ? 0 : null,
          locked: this.filterUseStates.includes('locked') ? 1 : null
        }

        userList = userList.filter((user) => {
          const { enabled, disable, locked } = stateFilter
          return (
            (enabled != null && user.enabled === enabled) ||
            (disable != null && user.enabled === disable) ||
            (locked != null && user.locked === locked)
          )
        })
      }
      // console.log(`[filterUserList] #4 userList:`, userList.length)

      // 過濾 公開私有
      if (this.filterPublic !== all) {
        needResult = true
        userList = userList.filter((user) => {
          return user.public === this.filterPublic
        })
      }
      // console.log(`[filterUserList] #5 userList:`, userList.length)

      // 更新篩選結果 (不需要將 多選帳號, 所選的帳號一起列入)
      // console.log(`[filterUserList] needResult:`, needResult)
      if (needResult) {
        this.updateFilterResult(userList.length)
      }

      // 過濾是為勾選帳號
      let multiUserList = []
      const needFilterMulti = this.isMultiMode && this.multiSelectOnly
      if (needFilterMulti) {
        multiUserList = userList.filter((user) => {
          return (
            this.selectedUserList.filter(({ id }) => id === user.id).length > 0
          )
        })
      }
      // console.log(`[filterUserList] #6 userList:`, userList.length)
      // console.log(`[filterUserList] #6 multiUserList:`, multiUserList.length)

      // console.timeEnd('filterUserList time')
      return needFilterMulti ? multiUserList : userList
    },
    subGroupList() {
      // console.time('subGroupList time')
      let subTreeList = this.groupInfoList

      if (this.selectedGroupList?.length > 0) {
        subTreeList = subTreeList.filter((groupInfo) => {
          const checked = this.selectedGroupList.find(
            (groupId) => groupId === groupInfo.id
          )
          return checked ? true : false
        })
      } else {
        const groupId = this.groupId
          ? this.groupId
          : this.groupInfoList.length > 0
          ? this.groupInfoList[0].id
          : ''

        if (groupId) {
          subTreeList = getNodeFamily(this.groupTreeList, groupId)
        }
      }

      // 過濾掉空的群組
      subTreeList = this.getSubGroupList(subTreeList)

      // console.timeEnd('subGroupList time')
      return subTreeList
    }
  },
  methods: {
    ...mapMutations('userinfo', [
      'clearSelectedUserList',
      'updateFilterResult'
    ]),
    getSubGroupList(list) {
      let result = []
      const userGrpIdList = this.filterUserList.map((user) => user.groupId)

      result = list.filter((tlGrp) => {
        const hasUser = userGrpIdList.includes(tlGrp.id)
        return hasUser
      })

      return result
    },
    // getSubGroupListById(list, groupId) {
    //   let result = []

    //   if (groupId) {
    //     result = list.filter((tlGrp) => {
    //       const hasUser =
    //         this.filterUserList.filter((user) => user.groupId === tlGrp.id)
    //           .length > 0

    //       return hasUser
    //     })
    //   }

    //   return result
    // },
    getFilterGroupUserList(groupId) {
      // if (this.groupId && groupId !== this.groupId) return []
      if (this.filterUserList.length <= 0) return []
      // console.time('getFilterGroupUserList time')
      // 過濾 使用者群組
      const result = this.filterUserList.filter(
        (user) => user.groupId === groupId
      )
      // console.timeEnd('getFilterGroupUserList time')
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
$ActionIconW: 3%; // 1rem;
$ShortInfoW: 10%; // 5rem;
$LongInfoW: calc($ShortInfoW * 2); // 10rem;
$InfoPadding: 1rem;
$fontSize: px2rem(24);
.wrap-user-table {
  height: 100%;
  padding-bottom: calc(100vh * 0.01);
  background-color: #282942;

  .user-table {
    width: 100%;
    height: 100%;
    font-size: px2rem(24);
    // line-height: px2rem(30);
    color: #ffffff;

    .header {
      // font-size: px2rem(20);

      .row {
        display: flex;
        align-items: center;
        margin-left: px2rem(4);
        margin-right: px2rem(13);
        min-height: px2rem(50);

        // &:hover {
        //   background: #6e7d9333;
        // }

        // &:active {
        //   background: #6e7d9399;
        // }

        // > .col {
        //   padding-left: px2rem(52);
        //   display: flex;
        //   align-items: center;

        //   img {
        //     margin-right: px2rem(12);
        //   }
        // }

        .col {
          display: flex;
          font-weight: 500;
          margin-right: 0.4rem;
          padding-left: 0.5rem;
          width: px2rem(24); // 基準
          font-size: px2rem(20);
          // background-color: #00f;

          // 多選帳號 eraser
          &:first-child {
            margin-left: 0.75rem;
            padding-left: unset;
            visibility: hidden;
            img {
              height: $fontSize;
              width: $fontSize;
            }
          }
          &.eraser {
            cursor: pointer;
            visibility: visible;

            &.disabled {
              cursor: unset;
              opacity: 0.2;
            }
          }

          // 帳號(包含 帳號,公開 ICON)
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: calc($LongInfoW + $ShortInfoW);
          }
          // Lock, 多選帳號儲存狀態
          &:last-child {
            margin-right: unset;
          }
        }
      }
    }

    .user-content {
      height: calc(100% - px2rem(50));
      overflow: overlay;
    }
  }
}
</style>
